import React, {useEffect, useRef, useState} from 'react'
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import 'react-toastify/dist/ReactToastify.css'
import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup'; // To use confirmPopup method
import "primereact/resources/themes/lara-light-blue/theme.css";
import {FaFloppyDisk, FaPlus, FaPencil, FaTrash, FaCheck, FaSpinner, FaTrashCan} from "react-icons/fa6";
import productDataService from "../services/products";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import { Toast } from 'primereact/toast';
import paymentDataService from "../services/payments";
import moment from "moment/moment";
import { Tag } from 'primereact/tag';
import utils from "../services/utils";

const PaymentListTemplate = ({token, payments, getCurrentPageInfo, role, fromOrder, fromShipment, fromUSShipment, currentOrder,
                                 currentShipment, currentUSShipment,paid, remaining, fromFair, currentFair}) =>{
    const toast = useRef(null);

    const handleDeletePayment = (event, paymentId) =>{
        confirmPopup({
            target: event.currentTarget,
            message: 'Bạn có chắc là bạn muốn xóa biên lai thanh toán này chứ?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deletePayment(paymentId),
            acceptLabel: "Đồng Ý",
            rejectLabel: "Không"

        })
    }

    const deletePayment = (paymentId) =>{
        paymentDataService.delete(paymentId, token)
        .then(response => {
            toast.current.show({ severity: 'info', detail: "Xóa biên lai thanh toán thành công!" });
            getCurrentPageInfo();
        })
        .catch(e =>{
            console.log(e);
        });
    }

    return(
        <Container>
            {
                token==null||token===""?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <Toast ref={toast} />
                        <ConfirmPopup />
                        {
                            !fromFair?<div>
                            <label>Đã Thanh Toán:&nbsp;</label>
                            <Tag severity="success" value={utils.formatCurrency(paid)} className="mb-3"></Tag>
                            &nbsp;
                            <label>Còn lại:&nbsp;</label>
                            {
                               remaining < 0? <Tag severity="danger" value={utils.formatCurrency(remaining)} className="mb-3"></Tag> :(
                                   remaining === 0? <Tag severity="success" value={utils.formatCurrency(remaining)} className="mb-3"></Tag>:
                                       <Tag severity="warning" value={utils.formatCurrency(remaining)} className="mb-3"></Tag>
                               )
                            }

                        </div>:""
                        }

                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>Ngày Thanh Toán</th>
                                    <th>Số Tiền</th>
                                    <th>Trạng Thái</th>
                                    {
                                        role==='admin'?<th></th>:""
                                    }
                                </tr>

                            </thead>
                                {
                                    payments.map((payment) =>{
                                        return(
                                            <tbody>
                                                <tr key={payment.payment_id}>

                                                    <td>{
                                                        role==='admin'?(
                                                            <Link to ={{
                                                                pathname:"/payments/"+ payment.payment_id,
                                                                state:{
                                                                    currentPayment:payment,
                                                                    fromOrder: fromOrder,
                                                                    fromShipment: fromShipment,
                                                                    currentOrder: currentOrder,
                                                                    currentShipment: currentShipment,
                                                                    fromUSShipment: fromUSShipment,
                                                                    currentUSShipment: currentUSShipment,
                                                                    currentFair:currentFair,
                                                                    fromFair:fromFair
                                                                        }
                                                                    }}>
                                                                    {payment.payment_date?moment(payment.payment_date).format('yyyy-MM-DD'):"Không Có Dữ Liệu"}
                                                            </Link>
                                                        ): payment.payment_date?moment(payment.payment_date).format('yyyy-MM-DD'):"Không Có Dữ Liệu"
                                                    }
                                                        </td>
                                                    <td>{fromFair?utils.formatCurrency(payment.payment_amount, 'us'):utils.formatCurrency(payment.payment_amount)}</td>
                                                    <td>{payment.is_paid?<Tag severity="success" value="Đã Thanh Toán" icon="pi pi-check"></Tag>:<Tag severity="warning" value="Chưa Thanh Toán" icon="pi pi-exclamation-triangle"></Tag>}</td>
                                                    {
                                                        role==='admin'?(
                                                            <td>
                                                                <Link to ={{
                                                                    pathname:"/payments/" + payment.payment_id,
                                                                    state:{
                                                                        currentPayment:payment,
                                                                        fromOrder: fromOrder,
                                                                        fromShipment: fromShipment,
                                                                        currentOrder: currentOrder,
                                                                        currentShipment: currentShipment,
                                                                        fromUSShipment: fromUSShipment,
                                                                        currentUSShipment: currentUSShipment,
                                                                        currentFair:currentFair,
                                                                        fromFair:fromFair
                                                                    }
                                                                }}>
                                                                <Button variant="outline-info" className="me-2"><FaPencil title="Chỉnh Sửa"/></Button>
                                                                </Link>
                                                                <Button variant="outline-danger" onClick={(e) => handleDeletePayment(e, payment.payment_id)}><FaTrashCan title="Xóa"/></Button>
                                                            </td>
                                                        ):""
                                                    }
                                                </tr>

                                            </tbody>

                                        )
                                    }

                                    )
                                }
                        </Table>
                    </div>
                )
            }

        </Container>
    )

}

export default PaymentListTemplate