import axios from "axios";
import CommonDataService from "./commondataservice";

const  baseURL = "https://api.telegram.org/bot";

class TelegramServices{
    async sendMessage(token, chatId, message){
        return await axios.post(baseURL+`${token}/sendMessage?chat_id=${chatId}&text=${message}`);
    }
}

const telegramServices = new TelegramServices();
export default telegramServices;