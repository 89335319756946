import React, {useEffect, useState} from "react";
import vendorDataService from '../services/vendors';
import userDataService from '../services/users';
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import {InputGroup} from "react-bootstrap";
import {AutoComplete} from "primereact/autocomplete";
import {Checkbox} from "primereact/checkbox";
import utils from "../services/utils";

const AddVendor = props => {
    let editing = false;
    let initialEmail = "";
    let initialNote= "";
    let intitialFB="";
    let initialPhoneNo = "";
    let initialVendor = "";
    let initialUser = "";
    let initialVendorName = "";
    let initialTelegramChatId = "";
    let initialIsActive = true;
    let initialSearchedUsername = "";

    if(props.location.state && props.location.state.currentVendor){
        editing = true;
        initialEmail = props.location.state.currentVendor.email;
        initialNote = props.location.state.currentVendor.notes;
        intitialFB = props.location.state.currentVendor.facebook_handler;
        initialPhoneNo = props.location.state.currentVendor.phone_no;
        initialVendorName = props.location.state.currentVendor.name;
        initialVendor =props.location.state.currentVendor;
        initialUser = props.location.state.currentVendor.vendor_user
        initialTelegramChatId = props.location.state.currentVendor.telegram_chat_id;
        initialIsActive = props.location.state.currentVendor.is_active;
        initialSearchedUsername = props.location.state.currentVendor.username;
    }

    const [user, setUser] = useState(initialUser);
    const [users, setUsers] = useState([]);
    // const [vendors, setVendors] = useState([]);
    const [email, setEmail] = useState(initialEmail);
    const [submitted, setSubmitted] = useState(false);
    const [phoneNo, setPhoneNo] = useState(initialPhoneNo);
    const [note, setNote] = useState(initialNote);
    const [fbHandler, setFBHandler] = useState(intitialFB);
    const [name, setName] = useState(initialVendorName);
    const [isActive, setIsActive] = useState(initialIsActive);
    const [selectedUsername, setSelectedUsername] = useState(initialSearchedUsername);
    const [filteredUsers, setFilteredUsers] = useState(null);
    const [telegramChatId, setTelegramChatId] = useState(initialTelegramChatId);

    useEffect(() =>{
        if(initialUser)
            retrieveUser(initialUser);
        retrieveUnassignedUsers();
    },[props.token]);

    const retrieveUser = (userId) =>{
        userDataService.getOne(userId,props.token).then(response=>{
            setUser(response.data);
            setSelectedUsername(response.data.username);
            // console.log('response.data',response.data);
        }).catch(e =>{
            console.log(e);
        });
    }

    const retrieveUnassignedUsers = () => {
        vendorDataService.getAll(props.token)
            .then(r=>{
                const vendors = r.data;
                const userIds = vendors.map(vendor => vendor.vendor_user);
                // console.log('userIds', userIds);
                userDataService.getAll(props.token)
                    .then(r=>{
                        let filteredUsers = r.data;
                        // console.log('userIds', userIds);
                        // console.log('allUsers', allUsers);
                        if(userIds.length>0){
                            userIds.forEach(userId => {
                                filteredUsers = filteredUsers.filter(user => user.id !== userId && user.is_active === true)
                            })
                        }
                        // console.log('filteredUsers', filteredUsers);
                        setUsers(filteredUsers);

                    })
                    .catch(e=>console.log(e));
            })
            .catch(e=>console.log(e));
    }

    const search = (event) => {
        setTimeout(()=>{
            let _filteredUsernames;
            const usernames = users.map(user => user.username);
            if(!event.query.trim().length){

                _filteredUsernames = [...usernames];
            }
            else{
                // console.log(event.query);
                _filteredUsernames = usernames.filter((username) =>{
                    return username.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            setFilteredUsers(_filteredUsernames);
        }, 250)
    }

    const onChangeEmail = e => {
        const email = e.target.value;
        setEmail(email);
    }

    const onChangeFBHandler = e => {
        const fbHandler = e.target.value;
        setFBHandler(fbHandler);
    }

    const onChangeTelegramChatId = e => {
        const chatId = e.target.value;
        setTelegramChatId(chatId);
    }

    const onChangeUsername = (e) =>{
        const username = e.target.value
        // console.log('username', username);
        setSelectedUsername(username);
        const user = users.filter(user => user.username === username);
        // console.log("user:", user);
        if(username.length > 0)
            setUser(user[0]);
        else{
            setUser(null);
        }
    }

    const onChangeVendorName = e => {
        const vendorName = e.target.value;
        setName(vendorName);
    }

    const onChangeNote = e =>{
        const note = e.target.value;
        setNote(note);
    }

    const onChangePhoneNumber = e => {
        const phoneNo = e.target.value;
        setPhoneNo(phoneNo);
    }
    const saveVendor = (e) => {
        e.preventDefault();
        var data;
        if(editing){
            data = {
                vendor_user: user?user.id:null,
                email: email,
                is_active: isActive,
                phone_no: phoneNo,
                notes: note,
                name: name,
                facebook_handler: fbHandler,
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
                telegram_chat_id: telegramChatId
            }
            vendorDataService.update(props.location.state.currentVendor.vendor_id, data, props.token).then(response =>{
                setSubmitted(true);
            }).catch(e =>{
                console.log(e);
            })
            
        }
        else{
            data = {
                vendor_user: user?user.id:null,
                email: email,
                is_active: isActive,
                phone_no: phoneNo,
                notes: note,
                name: name,
                facebook_handler: fbHandler,
                created_by: localStorage.getItem('username'),
                last_modified_by: localStorage.getItem('username'),
                telegram_chat_id: telegramChatId
            }
            console.log(data);
            vendorDataService.create(data, props.token).then(response =>{
                setSubmitted(true);
            }).catch(e =>{
                console.log(e);
            });
        }
        
    }

    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(submitted?(
                    editing?(
                        <div>
                        <h4>Thông tin cập nhật thành công!</h4>
                        <Link to ={{
                            pathname:"/vendor/"+ initialVendor.vendor_id + "/view",
                            state:{
                                currentVendor:initialVendor
                            }
                        }}>
                        Quay lại {name}
                        </Link>
                        <br/>
                        <Link to={"/vendors/"}>Hoặc đi tới danh sách nhà cung cấp</Link>
                    </div>
                    ):(
                        <div>
                        <h4>Nhà cung cấp và user được tạo thành công</h4>
                        <Link to={"/vendors/"}>Quay lại danh sách nhà cung cấp</Link>
                    </div>
                    )

                ):(
                    <div>
                        {editing?(initialVendor.is_active?'':(<Alert variant="warning">Nhà cung cấp không còn hoạt động!</Alert>)):''}
                        <Form onSubmit={saveVendor}>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Tên Nhà Cung Cấp</InputGroup.Text>
                                <Form.Control
                                    type='text'
                                    placeholder="Nhập tên nhà cung cấp"
                                    value={name}
                                    onChange={onChangeVendorName}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                            <InputGroup.Text>Tên Tài Khoản</InputGroup.Text>
                                <AutoComplete value={selectedUsername} suggestions={filteredUsers} completeMethod={search} onChange={(e) => onChangeUsername(e)} dropdown />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Email</InputGroup.Text>
                                <Form.Control
                                    type='email'
                                    placeholder="Nhập email"
                                    value={email}
                                    onChange={onChangeEmail}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Số Điện Thoại</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    placeholder="Nhập số điện thoại"
                                    value={phoneNo}
                                    onChange={onChangePhoneNumber}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Facebook</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập facebook"
                                    value={fbHandler}
                                    onChange={onChangeFBHandler}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Telegram Chat Id</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Nhập telegram chat id"
                                    value={telegramChatId}
                                    onChange={onChangeTelegramChatId}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                    <InputGroup.Text>Is Active?</InputGroup.Text>
                                    <Checkbox className="m-2" onChange={e => setIsActive(e.checked)} checked={isActive}></Checkbox>
                             </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Ghi Chú</InputGroup.Text>
                                <Form.Control
                                    as='textarea'
                                    rows={3}
                                    placeholder="Nhập ghi chú"
                                    value={note}
                                    onChange={onChangeNote}
                                />
                            </InputGroup>
                            <Link to ={{
                                        pathname:"/vendors/",
                                    }}>
                                <Button variant="outline-primary" className="me-2"><b>&#8592;</b></Button>
                                </Link>
                            <Button variant="outline-warning" type="submit"
                                    // onClick={saveVendor}
                            >{editing?"Cập Nhật":"Tạo Mới"}</Button>
                        </Form>

                    </div>
                    
                ))

                
            }
        </Container>
    )

}

export default AddVendor;