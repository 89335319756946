import React, {useRef} from 'react'
import {Link} from "react-router-dom";
import moment from "moment/moment";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import 'react-toastify/dist/ReactToastify.css'
import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup'; // To use confirmPopup method
import "primereact/resources/themes/lara-light-blue/theme.css";
import {FaFloppyDisk, FaPlus, FaPencil, FaTrash, FaCheck, FaSpinner, FaTrashCan} from "react-icons/fa6";
import { Toast } from 'primereact/toast';
import orderDataService from "../services/orders";
import Container from "react-bootstrap/Container";
import orderedProductDataService from "../services/orderedproducts";
import paymentDataService from "../services/payments";

const OrderListTemplate = ({orders, role, completeTab, token, getCurrentPageInfo}) => {
    const toast = useRef(null);

    const completeOrder = (orderId, is_complete) => {
        orderDataService.complete(orderId, token)
        .then(response => {
            if(is_complete)
                // toastIncompleteNotification();
                toast.current.show({ severity: 'info', detail: "Chuyển đơn hàng sang \"Chưa Hoàn Thành\" thành công!" });
            else
                toast.current.show({ severity: 'info', detail: "Chuyển đơn hàng sang \"Đã Hoàn Thành\" thành công!" });
            getCurrentPageInfo();
        })
        .catch(e => {
            console.log(e);
        });
    }

    const deleteOrder = (orderId) =>{
        orderDataService.delete(orderId, token)
        .then(response => {
            orderedProductDataService.getAll(token, orderId)
                .then (r => {
                    const ops = r.data;
                    ops.forEach(op => {
                        orderedProductDataService.delete(op.op_id, token)
                            .then(r => {

                            })
                            .catch(e => console.log(e))
                    })
                })
                .catch(e => console.log(e));
            paymentDataService.getAllPaymentsForOrder(token, orderId)
                .then(
                    r => {
                        const payments = r.data;
                        payments.forEach(payment => {
                            paymentDataService.delete(payment.payment_id, token)
                                .then( r=> {

                                })
                                .catch(e => console.log(e));
                        })
                    }
                )
                .catch(e => console.log(e));
            toast.current.show({ severity: 'info', detail: "Xóa đơn hàng thành công!" });
            getCurrentPageInfo();
        })
        .catch(e =>{
            console.log(e);
        });
    }

    const deleteShippedOrderedProduct = (event, order_id) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Bạn thật sự muốn xóa đơn hàng?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteOrder(order_id),
        })
    }
    return(
        <Container>
            <Toast ref={toast} />
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Tên Đơn Hàng</th>
                        <th>Nhà Cung Cấp</th>
                        <th>Ngày Xác Nhận</th>
                        {
                            completeTab?(
                               <th>Ngày Hoàn Thành</th>
                            ):<th>Ngày Dự Kiến Hoàn Thành</th>
                        }
                        {
                            role==='admin'?<th></th>:""
                        }

                    </tr>

                </thead>
                    {
                        orders.map((order) =>{
                            return(
                                <tbody>
                                    <tr key={order.order_id} >
                                        <td><Link to ={{
                                                pathname:"/order/"+ order.order_id + "/view",
                                                state:{
                                                    currentOrder:order
                                                }
                                            }}>
                                            {order.name}
                                            </Link></td>
                                        <td>{order.vendor_name}</td>
                                        <td>{order.confirmed_date?moment(order.confirmed_date).format("LLL"):"Chưa Xác Nhận"}</td>
                                        <td >{
                                            completeTab?order.completed_date?moment(order.completed_date).format("LL"):"Chưa Xác Định":order.estimated_date?moment(order.estimated_date).format("LL"):"Chưa Xác Định"
                                        }
                                            </td>
                                        {
                                            role==='admin'?(
                                                <td>
                                                        <Link to ={{
                                                            pathname:"/orders/" + order.order_id,
                                                            state:{
                                                                currentOrder:order
                                                            }
                                                        }}>
                                                            <Button variant="outline-primary" className="me-2"><FaPencil title="Chỉnh Sửa" size="1em"/></Button>
                                                        </Link>
                                                        <ConfirmPopup/>
                                                        <Button variant="outline-success" onClick={() => completeOrder(order.order_id, order.is_complete)}>{order.is_complete?(<FaSpinner title="Mark As Incomplete" size="1em"/>):<FaCheck title="Mark As Completed" size="1em"/>}</Button>
                                                        {   order.is_complete?
                                                            "":
                                                        <Button variant="outline-danger" onClick={(e) => deleteShippedOrderedProduct(e, order.order_id)}><FaTrashCan title="Xóa" size="1em"/></Button>}
                                                 </td>
                                                ):""

                                        }

                                    </tr>
                                </tbody>
                            )
                        }

                        )
                    }
            </Table>
        </Container>

    )
}

export default OrderListTemplate;