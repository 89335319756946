import React, {useEffect, useRef, useState} from "react";
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import orderDataService from "../services/orders";
import orderedProductDataService from "../services/orderedproducts";
import productDataService from "../services/products";
import DataPagination from "./pagination";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Accordion from 'react-bootstrap/Accordion';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import utils from "../services/utils";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { classNames } from 'primereact/utils';
import { DataView } from 'primereact/dataview';
import {
    FaFloppyDisk,
    FaPlus,
    FaPencil,
    FaArrowLeft,
    FaTruckFast,
    FaTrashCan, FaDollarSign
} from "react-icons/fa6";
import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup'; // To use confirmPopup method
// import "primereact/resources/themes/lara-light-blue/theme.css";
import {InputGroup} from "react-bootstrap";
// import 'react-toastify/dist/ReactToastify.css'
import { Toast } from 'primereact/toast';
import {v4 as uuidv4} from "uuid";
import vendorDataService from "../services/vendors";
import shipmentDataService from "../services/shipments";
import shippedProductDataService from "../services/shippedproducts";
import ShipmentListTemplate from "./shipment-list-template";
import PaymentListTemplate from "./payment-list-template";
import paymentDataService from "../services/payments";
import CommonImagePreviewHandler from "./common-image-preview-handler";
import CommonGalleryDisplayHandler from "./common-gallery-preview-handler";
import { AutoComplete } from "primereact/autocomplete";
import VNCurrencyInput from "./vn-currency-input-mask";
import telegramServices from "../services/telegram";


const ViewOrder = props => {
    let initialOrderId = "";
    let initialVendorId = "";
    let initialQuantity = '';
    let initialShipped = 0;
    let initialSelectedProduct = "";
    let initialPrice = '';
    let initialCost = 0;
    let initialDateEstimated = "";
    let initialActiveTab = 0;


    if(props.location.state && props.location.state.currentOrder){
        initialOrderId = props.location.state.currentOrder.order_id;
        initialVendorId = props.location.state.currentOrder.vendor_id;
    }
    else{
        initialOrderId = props.location.pathname.replace('/order/', '').replace('/view', '');
    }

    if(props.location.state && props.location.state.currentVendorId){
        initialVendorId = props.location.state.currentVendorId;
    }

    if(props.location.state && props.location.state.eventKey){
        initialActiveTab = props.location.state.eventKey;
    }

    if(props.location.state && props.location.state.newOrderId){
        initialOrderId = props.location.state.newOrderId;
    }
    else{
        initialOrderId = props.location.pathname.replace('/order/', '').replace('/view', '');
    }

    const [order, setOrder] = useState('');
    const [products, setProducts] = useState([]);
    const [vendorID, setVendorID] = useState(initialVendorId);
    const [orderedProducts, setOrderedProducts] = useState([]);
    const [vendorOrderedProducts, setVendorOrderedProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordCount, setRecordCount] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState(initialSelectedProduct);
    const [quantity, setQuantity] = useState(initialQuantity);
    // const [shippedQuantity, setShippedQuantity] = useState(initialShipped);
    const [productCost, setProductCost] = useState(initialPrice);
    const [selectedProductPrice, setSelectedProductPrice] = useState(0);
    // const [orderCost, setOrderCost] = useState(0);
    const pageSize = 10;
    const [validated, setValidated] = useState(false);
    const [dateEstimated, setDateEstimated] = useState(initialDateEstimated);
    const [allShipments, setAllShipment] = useState([]);
    const [allPayments, setAllPayments] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState("");
    const [selectedProductName, setSelectedProductName] = useState("");
    const [filteredProducts, setFilteredProducts] = useState(null);
    const toast = useRef(null);
    const toastCreateShipmentNotification = () => toast.current.show({ severity: 'success', detail: "Tạo đơn giao hàng thành công!" });
    const toastOrderConfirmationNotification = () => toast.current.show({ severity: 'success', detail: "Xác nhận đơn hàng thành công!" });
    const toastOrderEstimatedNotification = () => toast.current.show({ severity: 'success', detail: "Đã cập ngày dự kiến hoàn thành!" });
    // const telegramToken = process.env.REACT_APP_TELEGRAM_TOKEN_FIRST+ ":" + process.env.REACT_APP_TELEGRAM_TOKEN_SECOND;
    const telegramToken = process.env.REACT_APP_TEL_TOKEN_FIRST + ":" + process.env.REACT_APP_TEL_TOKEN_SECOND;
    const currentEnv = process.env.REACT_APP_ENV;


    useEffect(() =>{
        retrieveOrder(initialOrderId);
        if(props.role!=='vendor'){
            retrieveOrderedProductPagination(currentPage, initialOrderId);
        }
        else {
            retrieveVendorOrderedProducts(initialOrderId);
        }

        retrieveProductByVendor(vendorID);
        retrieveShipments();
        retrievePaymentsForOrder(initialOrderId);

    }, [props.token, currentPage]);


    const retrieveOrderedProductPagination = (currentPage, orderId) =>{
        orderedProductDataService.getDataPagination(currentPage,props.token, orderId)
        .then(response => {
            setOrderedProducts(response.data.results);
            setRecordCount(response.data.count);
        })
        .catch(e=>{
            console.log(e);
        });
    }

    const retrieveVendorOrderedProducts = (orderId) =>{
        orderedProductDataService.getAll(props.token, orderId)
        .then(response => {
            setVendorOrderedProducts(response.data);
        })
        .catch(e=>{
            console.log(e);
        });
    }
    
    const search = (event) => {
        setTimeout(()=>{
            let _filteredProducts;
            const productsName = products.map(product => product.name)
            if(!event.query.trim().length){

                _filteredProducts = [...productsName];
            }
            else{
                console.log(event.query);
                _filteredProducts = productsName.filter((product) =>{
                    return product.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            setFilteredProducts(_filteredProducts);
        }, 250)
    }

    const retrievePaymentsForOrder = (orderId) => {
        paymentDataService.getAllPaymentsForOrder(props.token, orderId)
            .then(r =>{
                setAllPayments(r.data);
            })
            .catch(e => console.log(e));
    }

    const retrieveProductByVendor = (vendorId) => {
        productDataService.getAll(props.token, vendorId)
            .then(response=>{
                // setProducts(response.data);
                let products = response.data;
                orderedProductDataService.getAll(props.token,initialOrderId)
                    .then( r=> {
                        const selectedProducts = r.data;
                        selectedProducts.forEach(selectedProduct => {
                            products = filterAddedProducts(products, selectedProduct.product_id);
                        })
                        setProducts(products);
                    })
            })
            .catch(e=>{
                console.log(e);
            });
    }

    const filterAddedProducts = (products, filterProduct) =>{
        return products.filter(product => product.product_id !==filterProduct)
    }

    const onChangeDateEstimated = e => {
        const dateEstimated = e.target.value;
        setDateEstimated(dateEstimated);
    }

    const saveEstimatedDate = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Bạn sẽ không thể thay đổi Ngày Dự Kiến Hoàn Thành sau khi lưu. Bạn chắc chứ?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                let data = {
                    vendor_id: order.vendor_id,
                    estimated_date: moment(dateEstimated).add(12, 'hours'),
                }
                orderDataService.update(order.order_id, data, props.token)
                    .then(r=>{
                        toastOrderEstimatedNotification()
                        retrieveOrder(initialOrderId);
                        retrieveOrderedProductPagination(currentPage, initialOrderId);
                    })
                    .catch(e =>{
                        console.log(e);
                    })
            },
            acceptLabel: "Đồng Ý",
            rejectLabel: "Không"

        })
    }

    const saveConfirmedDate = (event) => {
        let data = {
            vendor_id: order.vendor_id,
            confirmed_date: moment()
        }
        orderDataService.update(order.order_id, data, props.token)
            .then(r=>{
                toastOrderConfirmationNotification()
                retrieveOrder(initialOrderId);
                retrieveOrderedProductPagination(currentPage, initialOrderId);
            })
            .catch(e =>{
                console.log(e);
            })
    }

    const retrieveSelectedProductPrice = (product_id) =>{
        productDataService.getOne(product_id, props.token)
            .then(response=>{
                const price = response.data.price;
                setSelectedProductPrice(utils.formatCurrency(price));
            })
            .catch(e => {
                console.log(e);
            });
    }

    const onChangeProduct = e =>{
        const selectedProductName = e.target.value;
        setSelectedProductName(selectedProductName);
        // console.log('products', products);
        const selectedProduct = products.filter(product => product.name===selectedProductName);
        // console.log('selectedProduct', selectedProduct);
        if(selectedProduct.length > 0){
           setSelectedProduct(selectedProduct[0].product_id);
            retrieveSelectedProductPrice(selectedProduct[0].product_id);
            productDataService.getOne(selectedProduct[0].product_id, props.token)
                .then(response=>{
                    const price = response.data.price;
                    const cost = price * quantity;
                    setProductCost(cost);
                })
                .catch(e => {
                    console.log(e);
                });
        }

    }

    const onChangePrice = e =>{
        const price = e.target.value;
        setProductCost(price);
    }

    const onChangeSelectedProductPrice = e =>{
        const selectedProductPrice = e.target.value;
        console.log("selectedProductPrice", selectedProductPrice);
        setSelectedProductPrice(selectedProductPrice);
        if(quantity>0)
            setProductCost(quantity*selectedProductPrice);
    }

    const onChangeQuantity = e =>{
        const quantity = e.target.value;
        setQuantity(quantity);
        setProductCost(quantity * utils.parseVNCurrencyInput(selectedProductPrice));
    }

    // const onChangeShipped = e => {
    //     const shipped = e.target.value;
    //     setShippedQuantity(shipped);
    // }


    const retrieveOrder = (order_id) =>{
        orderDataService.getOne(order_id,props.token)
        .then(response => {
            const order = response.data;
            setOrder(order);
            setVendorID(order.vendor_id);
            if(order.payment_status === 'overpaid')
                setPaymentStatus("Thanh Toán Lố")
            else if(order.payment_status === 'partiallypaid')
                setPaymentStatus("Chưa Thanh Toán Đủ")
            else{
                setPaymentStatus("Đã Thanh Toán")
            }

        })
        .catch(e=>{
            console.log(e);
        });
    }

    const handleDeleteOrderProduct = (event, orderedProductId) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Bạn có chắc là bạn muốn xóa sản phẩm này khỏi đơn hàng này chứ?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteOrderProduct(orderedProductId),
            acceptLabel: "Đồng Ý",
            rejectLabel: "Không"

        })
    }

    const deleteOrderProduct = (orderedProductId) =>{
        orderedProductDataService.delete(orderedProductId, props.token)
        .then(response => {
            retrieveOrder(initialOrderId);
            retrieveOrderedProductPagination(currentPage, initialOrderId);
            retrieveProductByVendor(initialVendorId);
        })
        .catch(e =>{
            console.log(e);
        });

    }

    const handlePagination = (currentPage) => {
        setCurrentPage(currentPage);
    }

    const saveOrderedProduct = () =>{
        // console.log('productCost:', productCost);
        // console.log('selectedProductPrice:', selectedProductPrice);
        var data = {
            product_id: selectedProduct,
            order_id: initialOrderId,
            quantity: quantity,
            price: utils.parseVNCurrencyInput(productCost),
            ordered_product_price: utils.parseVNCurrencyInput(selectedProductPrice),
            last_modified_by: localStorage.getItem('username'),
            created_by: localStorage.getItem('username'),
        }
        orderedProductDataService.create(data, props.token)
            .then(response =>{
                setSelectedProductName("");
                setSelectedProduct("");
                setSelectedProductPrice(0);
                setQuantity("");
                setProductCost("");
                retrieveOrder(initialOrderId);
                retrieveOrderedProductPagination(currentPage, initialOrderId);
                retrieveProductByVendor(initialVendorId);
                // GetTotalCostForOrder(initialOrderId);
                // updateOrderCost(GetTotalCostForOrder(initialOrderId));
            }).catch(e => {
                console.log(e);
        });

    }
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        setValidated(true);
        saveOrderedProduct();
        // setSelectedProduct("");
        // setSelectedProductPrice(0);
        // setQuantity("");
        // setProductCost("");
  };

    //shipment
    const createShippingLabel = () => {
        const shipment_id = uuidv4();
        let vendor_id = order.vendor_id;
        var data = {
            shipment_id: shipment_id,
            label: "Đơn giao hàng từ  " + order.vendor_name,
            vendor_id: vendor_id,
            created_by: localStorage.getItem('username'),
            last_modified_by: localStorage.getItem('username'),
            last_modified_date: moment()
        }
        shipmentDataService.create(data, props.token)
            .then(response =>{
                orderedProductDataService.getAll(props.token, initialOrderId)
                    .then(r=>{
                        const orderProducts = r.data;
                        orderProducts.forEach(orderProduct =>{
                            if(!orderProduct.is_fulfilled){
                                const shippedProductData = {
                                shipment_id: shipment_id,
                                op_id: orderProduct.op_id,
                                total_quantity: orderProduct.quantity,
                                last_modified_by: localStorage.getItem('username'),
                                created_by: localStorage.getItem('username'),
                                }
                                shippedProductDataService.create(shippedProductData, props.token)
                                    .then( r => {

                                    })
                                    .catch(e => {
                                        console.log(e);
                                });
                            }
                        })
                    })
                    .catch(e=>console.log(e));
                toastCreateShipmentNotification();
                // retrieveShipments();
                retrieveOrder(initialOrderId);
                if(props.role!=='vendor'){
                    retrieveOrderedProductPagination(currentPage, initialOrderId);
                }
                else {
                    retrieveVendorOrderedProducts(initialOrderId);
                }
                retrieveProductByVendor(vendorID);
                retrievePaymentsForOrder(initialOrderId);
                // retrieveShipmentsPagination(currentPage);
            })
            .catch(e => {
                console.log(e);
            });
        // retrieveShipments();
        setTimeout(function (){
            retrieveShipments();
        }, 1000);
    }

    const retrieveShipments = () => {
        console.log('get shipment');
        shippedProductDataService.getAllShippedProdForOrder(props.token, initialOrderId)
            .then(r => {
                const allShippedOrdersForOrder = r.data;
                const allShipmentsFordOrder = new Set(allShippedOrdersForOrder.map(shippedOrder => shippedOrder.shipment_id));
                let filter_string = "";
                console.log('allShipmentsFordOrder:', allShipmentsFordOrder);
                allShipmentsFordOrder.forEach(shipmentId =>{
                    filter_string = filter_string + shipmentId + "|"
                })
                if(filter_string.length > 0)
                    shipmentDataService.getShipmentsById(props.token, filter_string)
                        .then(r=>{
                            setAllShipment(r.data);
                        })
                        .catch(e=>console.log(e));
                }
            )
            .catch(e => console.log(e));
    }

    const sendConfirmation = (order) => {
        const vendorTelegramChatId = order.vendor_telegram_chat_id;
        // console.log('telegramToken: ', telegramToken);
        let message = `Bạn có đơn hàng mới! Hãy bấm vào ${utils.getBaseUrl(currentEnv)}order/${order.order_id}/view để đến đơn hàng và xác nhận đơn hàng!`
        telegramServices.sendMessage(telegramToken, vendorTelegramChatId, message)
            .then(r=>{
                console.log(r)
            }).catch(e => console.log(e));
    }

    const getCurrentPageInfo = () =>{
        retrieveOrder(initialOrderId);
        if(props.role!=='vendor'){
            retrieveOrderedProductPagination(currentPage, initialOrderId);
        }
        else {
            retrieveVendorOrderedProducts(initialOrderId);
        }
        retrieveProductByVendor(initialVendorId);
        retrieveShipments();
        retrievePaymentsForOrder(initialOrderId);
    }

    // data table's columns handler method
    // const formatCurrency = (value) => {
    //     return Intl.NumberFormat('vi-VN', {style : 'currency', currency : 'VND'}).format(value);
    // }

    //vendor view
    const itemTemplate = (orderedProduct, index) => {
        return (
            <div className="col-12" key={orderedProduct.op_id}>
                <div className={classNames('flex flex-column xl:flex-row xl:align-items-start p-4 gap-4', { 'border-top-1 surface-border': index !== 0 })}>
                    {/*<img className="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" src={`https://primefaces.org/cdn/primereact/images/product/${orderedProduct.image}`} alt={orderedProduct.name} />*/}
                    <div className="w-9 sm:w-16rem xl:w-10rem block xl:block mx-auto">
                        <CommonGalleryDisplayHandler
                                token={props.token}
                                keyId={"product_id"}
                                value={orderedProduct.product_id}
                            />
                    </div>
                    {/*<CommonGalleryDisplayHandler*/}
                    {/*            token={props.token}*/}
                    {/*            keyId={"product_id"}*/}
                    {/*            value={orderedProduct.product_id}*/}
                    {/*            imageDisplayName={"\"w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round\""}*/}
                    {/*        />*/}
                    <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                        <div className="flex flex-column align-items-center sm:align-items-start gap-3">

                            <div className="text-2xl font-bold text-900">{orderedProduct.product_name}</div>
                            <div className="text-xl font-bold text-900">Số lượng đặt: {orderedProduct.quantity}</div>
                            <div className="text-xl font-bold text-900">Số lượng cần gửi: {orderedProduct.quantity - orderedProduct.vendor_true_shipped_quantity}</div>
                            <div className="flex align-items-center gap-3">
                                <span className="flex align-items-center gap-2">
                                    {/*<i className="pi pi-tag"></i>*/}
                                    <span className="font-semibold">Đơn giá: {utils.formatCurrency(orderedProduct.ordered_product_price)}</span>
                                    <span className="font-semibold">Thành Tiền: {utils.formatCurrency(orderedProduct.price)}</span>
                                </span>
                            </div>
                        </div>
                        {/*<div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">*/}
                        {/*    <span className="text-2xl font-semibold">${orderedProduct.price}</span>*/}
                        {/*    <Button icon="pi pi-shopping-cart" className="p-button-rounded" disabled={orderedProduct.inventoryStatus === 'OUTOFSTOCK'}></Button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        );
    };

    const listTemplate = (items) => {
        if (!items || items.length === 0) return null;

        let list = items.map((product, index) => {
            return itemTemplate(product, index);
        });

        return <div className="grid grid-nogutter">{list}</div>;
    };

    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <Toast ref={toast} />
                        <h2>Chi Tiết Đơn Hàng</h2>
                        <Card>
                            <Card.Body className="mb-3">
                                <Container fluid>
                                    <Row>
                                        <Col><Card.Title>{order.name}</Card.Title></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card.Text className="text-lg"><b>Tổng Đơn Hàng: </b>{utils.formatCurrency(order.cost)} &nbsp;
                                            {
                                                paymentStatus === "Thanh Toán Lố"?<Tag severity="danger" value={paymentStatus} icon="pi pi-times"></Tag>:
                                                    paymentStatus === "Chưa Thanh Toán Đủ"?<Tag severity="warning" value={paymentStatus} icon="pi pi-exclamation-triangle"></Tag>:
                                                        <Tag severity="success" value={paymentStatus}></Tag>
                                            }
                                        </Card.Text>
                                        </Col>

                                        <Col>
                                        <Card.Text><b>Giảm Giá: </b>{utils.formatCurrency(order.discount)}</Card.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <Card.Text><b>Nhà Cung Cấp: </b>{order.vendor_name}</Card.Text>
                                        </Col>
                                        <Col>
                                        <Card.Text><b>Ngày Xác Nhận: </b>{order.confirmed_date?utils.formatDate(order.confirmed_date):(
                                            <>
                                                <Button variant="danger" onClick={saveConfirmedDate} className="me-2">Nhấn vào đây để xác nhận</Button>
                                                {
                                                    props.role==='admin'?<Button variant="info" onClick={(e) => sendConfirmation(order)} className="me-2">Gửi Tin Nhắn Xác Nhận</Button>:""
                                                }

                                            </>


                                            )}


                                        </Card.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {
                                                order.estimated_date===null?(
                                                    <>
                                                        <InputGroup>
                                                            <InputGroup.Text>Ngày Dự Kiến Hoàn Thành</InputGroup.Text>
                                                            <Form.Control
                                                                    className='dateformgroup'
                                                                    type='date'
                                                                    placeholder="Nhập ngày dự kiến hoàn thành"
                                                                    value={dateEstimated}
                                                                    onChange={(e) =>onChangeDateEstimated (e)}
                                                                />
                                                                <ConfirmPopup/>
                                                                <Button variant="outline-warning" onClick={saveEstimatedDate}><FaFloppyDisk title="Lưu"/></Button>
                                                        </InputGroup>
                                                    </>

                                                ):(
                                                    <Card.Text><b>Ngày Dự Kiến Hoàn Thành: </b>{utils.formatDateUndefined(order.estimated_date, "Chưa Nhập Thông Tin")}</Card.Text>
                                                )
                                            }

                                        </Col>
                                        <Col>
                                        <Card.Text><b>Số Tiền Đặt Cọc: </b>{utils.formatCurrency(order.deposit)}</Card.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card.Text><b>Ghi Chú: </b><br/>{order.notes}</Card.Text>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                         <div>
                                    <Link to ={{
                                            pathname:"/orders/",
                                        }}>
                                    <Button variant="primary" className="me-2"><FaArrowLeft/></Button>
                                    </Link>
                                    {
                                        props.role==='admin'?<Link to ={{
                                        pathname:"/orders/" + order.order_id,
                                        state:{
                                            currentOrder:order,
                                            fromOrder: true
                                        }
                                    }}>
                                    <Button variant="outline-info" className="me-2"><FaPencil /> &nbsp; Chỉnh Sửa</Button>
                                    </Link>:""
                                    }

                                </div>
                        <br/>
                        <Tabs defaultActiveKey={initialActiveTab}>
                            <Tab eventKey="0" title="Sản Phẩm">
                                <Tab.Content className='d-flex flex-column flex-grow-1'>
                                    <DataPagination
                                    pageSize={pageSize}
                                    recordCount={recordCount}
                                    handlePagination={handlePagination}
                                    currentPage={currentPage}
                                    />
                                    {
                                        props.role!=='vendor'?
                                            <Container>
                                                <Table bordered hover>
                                                    <thead>
                                                    <tr>
                                                        <th>Sản Phẩm</th>
                                                        <th>Hình Mẫu</th>
                                                        <th>Số Lượng</th>
                                                        <th>Đơn Giá</th>
                                                        {
                                                            props.role === 'admin'?(
                                                                <th>Số Lượng Đã Gửi</th>
                                                            ):""
                                                        }

                                                        <th>Số Lượng Cần Gửi</th>
                                                        <th>Thành Tiền</th>
                                                        {
                                                            props.role==='admin'?<th></th>:""
                                                        }

                                                    </tr>
                                                    </thead>
                                                    {
                                                        orderedProducts.length?
                                                        orderedProducts.map((orderedProduct) =>{
                                                            return(
                                                                <tbody>
                                                                    <tr key={orderedProduct.op_id}>
                                                                        <td>{orderedProduct.product_name}</td>
                                                                        <td>
                                                                            {/*<CommonImagePreviewHandler*/}
                                                                            {/*    token={props.token}*/}
                                                                            {/*    keyId={"product_id"}*/}
                                                                            {/*    value={orderedProduct.product_id}*/}
                                                                            {/*/>*/}
                                                                            <CommonGalleryDisplayHandler
                                                                                token={props.token}
                                                                                keyId={"product_id"}
                                                                                value={orderedProduct.product_id}
                                                                            />
                                                                        </td>
                                                                        <td>{orderedProduct.quantity}</td>
                                                                        <td>{utils.formatCurrency(orderedProduct.ordered_product_price)}</td>
                                                                        {
                                                                            props.role==="admin"?<td>{orderedProduct.vendor_true_shipped_quantity}</td>:""
                                                                        }
                                                                        <td>{orderedProduct.quantity - orderedProduct.vendor_true_shipped_quantity}</td>
                                                                        <td>{utils.formatCurrency(orderedProduct.price)}</td>
                                                                        {
                                                                            props.role==='admin'?(
                                                                                <>
                                                                                    <td>
                                                                                        <Link to ={{
                                                                                            pathname:"/orderedProducts/" + orderedProduct.op_id,
                                                                                            state:{
                                                                                                currentOrderedProduct:orderedProduct,
                                                                                                currentOrder: order,
                                                                                                currentVendorId: initialVendorId
                                                                                            }
                                                                                        }}>
                                                                                        <Button variant="outline-info" className="me-2"><FaPencil/></Button>
                                                                                        </Link>
                                                                                        <Button variant="outline-danger" onClick={(e) => handleDeleteOrderProduct(e, orderedProduct.op_id)}><FaTrashCan/></Button>
                                                                                    </td>
                                                                                </>
                                                                            ):""
                                                                        }

                                                                    </tr>
                                                                </tbody>
                                                                )

                                                        }):(
                                                            <div className='d-flex justify-content-center'>
                                                                <br/>
                                                                <Alert variant="info">Chưa có sản phẩm. Hãy thêm sản phẩm vào đơn hàng</Alert>
                                                            </div>)
                                                    }

                                                </Table>
                                                {
                                                    props.role==='admin'?(
                                                        <Accordion>
                                                            <Accordion.Item eventKey="0">
                                                                <Accordion.Header><b>Nhấn Vào Đây Để Thêm Sản Phẩm Vào Đơn Hàng</b></Accordion.Header>
                                                                <Accordion.Body>
                                                                    <Form noValidate validated={validated}>
                                                                        <Row>
                                                                            <Form.Group as={Col} controlId="productValidation">
                                                                                <Form.Label>Sản Phẩm</Form.Label>
                                                                                {/*<Form.Select*/}
                                                                                {/*    required*/}
                                                                                {/*    value={selectedProduct}*/}
                                                                                {/*    isInvalid={!selectedProduct}*/}
                                                                                {/*    onChange={onChangeProduct}*/}
                                                                                {/*    >*/}
                                                                                {/*        <option></option>*/}
                                                                                {/*        {*/}
                                                                                {/*            products.map((product) =>{*/}
                                                                                {/*                return(*/}
                                                                                {/*                    <option value={product.product_id} key={product.product_id}*/}
                                                                                {/*                            data-key={product.product_id}>{product.name}</option>*/}
                                                                                {/*                )*/}
                                                                                {/*            }*/}
                                                                                {/*            )*/}
                                                                                {/*        }*/}

                                                                                {/*</Form.Select>*/}
                                                                                {/*<Form.Control.Feedback type="invalid">*/}
                                                                                {/*    Chọn sản phẩm*/}
                                                                                {/*  </Form.Control.Feedback>*/}
                                                                                <AutoComplete value={selectedProductName} suggestions={filteredProducts} completeMethod={search} onChange={(e) => onChangeProduct(e)} dropdown />
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} controlId="priceValidation">
                                                                                <Form.Label>Đơn Giá</Form.Label>
                                                                                <Form.Control
                                                                                    type="numeric"
                                                                                    required
                                                                                    disabled
                                                                                    value={selectedProductPrice}
                                                                                    onChange={onChangeSelectedProductPrice}
                                                                                />
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    Nhập giá cho sản phẩm
                                                                                  </Form.Control.Feedback>
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} controlId="quantityValidation">
                                                                                <Form.Label>Số Lượng</Form.Label>
                                                                                 <Form.Control
                                                                                    type="number"
                                                                                    required
                                                                                    isInvalid={!parseInt(quantity) > 0}
                                                                                    value={quantity}
                                                                                    onChange={onChangeQuantity}
                                                                                />
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    Nhập số lượng sản phẩm
                                                                                  </Form.Control.Feedback>
                                                                            </Form.Group>
                                                                            <Form.Group as={Col} controlId="productCostValidation">
                                                                                <Form.Label>Thành Tiền</Form.Label>
                                                                                {/* <Form.Control*/}
                                                                                {/*    type="number"*/}
                                                                                {/*    required*/}
                                                                                {/*    isInvalid={!parseInt(productCost) > 0}*/}
                                                                                {/*    value={productCost}*/}
                                                                                {/*    onChange={onChangePrice}*/}
                                                                                {/*/>*/}
                                                                                <VNCurrencyInput value={productCost} onChange={onChangePrice} type="text" className="border-solid border-1 p-2"/>
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    Nhập giá tiền cuối cùng
                                                                                  </Form.Control.Feedback>
                                                                            </Form.Group>
                                                                            <Form.Group as={Col}>
                                                                                <br/>
                                                                                <Button variant="outline-success" onClick={handleSubmit}><FaPlus size="1.5em" title="Thêm sản phẩm"/></Button>
                                                                            </Form.Group>
                                                                        </Row>

                                                                    </Form>

                                                                </Accordion.Body>

                                                            </Accordion.Item>
                                                        </Accordion>
                                                    ):""
                                                }
                                            </Container>
                                        :(
                                            <div className="card">
                                                <DataView value={vendorOrderedProducts} listTemplate={listTemplate} paginator rows={25} />
                                            </div>
                                            )
                                    }


                                </Tab.Content>
                            </Tab>
                            <Tab eventKey="1" title="Giao Hàng">
                                <Tab.Content>
                                    <Button variant="success" className="mb-3" onClick={createShippingLabel}>
                                        <FaTruckFast />&nbsp;Tạo Đơn Giao Hàng
                                    </Button>
                                    <ShipmentListTemplate
                                        role={props.role}
                                        token={props.token}
                                        shipments={allShipments}
                                        fromOrder={true}
                                        tabEventKey={1}
                                        orderId={initialOrderId}
                                        getCurrentPageInfo={getCurrentPageInfo}
                                    />

                                </Tab.Content>
                            </Tab>

                            <Tab eventKey="2" title="Thanh Toán">
                                <Tab.Content>
                                    {
                                        props.role === 'admin'?(
                                            <Link to={{
                                                pathname: "/payments/create",
                                                state:{
                                                    currentOrder: order,
                                                    isNew: true
                                                }}}>
                                                <Button variant="success" className="mb-3">
                                                    <FaDollarSign />&nbsp;Thanh Toán
                                                </Button>
                                            </Link>
                                        ):""
                                    }
                                    <PaymentListTemplate
                                        payments={allPayments}
                                        token={props.token}
                                        role={props.role}
                                        fromOrder={true}
                                        paid={order.paid_amount}
                                        remaining={order.remaining_payment_amount}
                                        fromShipment={false}
                                        currentOrder={order}
                                        currentShipment={null}
                                        getCurrentPageInfo={getCurrentPageInfo}
                                    />


                                </Tab.Content>
                            </Tab>

                        </Tabs>


                        {/*<Formik validationSchema={schema} onSubmit={}>*/}

                        {/*</Formik>*/}

                    </div>
                    
                )

                
            }
        </Container>
    )

}

export default ViewOrder;