import CommonDataService from './commondataservice';
import axios from "axios";

class ShippedProductPhotoDataService extends CommonDataService{
    async getAllShippedProductPhotos(token, shippedProductId){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view?sp_id=${shippedProductId}`, {timeout:5000})
    }

    async getAllShipmentPhotos(token, shipmentId){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view?shipment_id=${shipmentId}`, {timeout:5000})
    }
}

const shippedProductPhotoDataService = new ShippedProductPhotoDataService('shippedproductphotos', 'shippedproductphoto');
export default shippedProductPhotoDataService;