
import React, { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primeicons/primeicons.css";
import {
  S3Client,
  PutObjectCommand,
} from "@aws-sdk/client-s3";
import moment from "moment";
import shippedProductPhotoDataService from "../services/shippedproductphotos";


export default function ShippedPhotoImageHandler({shippedProductId, shipmentId, token, refreshPage}) {
    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const accessKey = process.env.REACT_APP_ACCESS_KEY;
    const vultrBucketName = process.env.REACT_APP_BUCKET_NAME;
    const vultrEndpoint = process.env.REACT_APP_HOST_NAME;
    // const { S3Client } = require('@aws-sdk/client-s3');

    const s3Client = new S3Client({
        region: 'us-east-1',
        endpoint: vultrEndpoint,
        forcePathStyle: false,
        credentials: {
            accessKeyId: accessKey,
            secretAccessKey: secretKey,
          },
    });

    const onTemplateSelect = (e) => {
        let _totalSize = 0;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    async function uploadFileToServer(file, key){
        await s3Client.send(
            new PutObjectCommand({
            Bucket: vultrBucketName,
            Key: key,
            Body: file
        })
        )
    }


    const imgUploadHandler = (e) => {
        e.files.forEach((file) =>{
            const key = moment().unix()+ "_sp_" + shippedProductId + "_" + file.name;
            uploadFileToServer(file, key)
                .then(r=>{
                    const photoData = {
                        sp_id: shippedProductId,
                        shipment_id: shipmentId,
                        photo_vn: key,
                        created_by: localStorage.getItem('username'),
                        last_modified_by: localStorage.getItem('username'),
                    }
                    shippedProductPhotoDataService.create(photoData, token)
                        .then(
                            r=>{

                            }
                        )
                        .catch(e => console.log(e));
                    fileUploadRef.current.clear();
                    toast.current.show({ severity: 'info', summary: 'Thành Công', detail: 'Thêm ' + file.name + ' thành công! Tải lại trang để xem hình!' });
                    window.location.reload();

                })
                .catch(e => {
                    toast.current.show({ severity: 'error', summary: 'Lỗi', detail: 'Có Lỗi. Xin Hãy Thử Lại!' })
                    console.log(e);
                });
        })
        refreshPage();

    }

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });


        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 200000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 20 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Kéo Thả Hình Vào Đây
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images text-lg font-medium', iconOnly: true, className: 'custom-choose-btn p-button-rounded  w-3rem h-3rem' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload text-lg font-medium', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded w-3rem h-3rem' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    return (
        <div>
            <Toast ref={toast}></Toast>

            <Tooltip target=".custom-choose-btn" content="Chọn" position="bottom" />
            <Tooltip target=".custom-upload-btn" content="Tải" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content="Xóa" position="bottom" />

            <FileUpload ref={fileUploadRef} uploadHandler={imgUploadHandler}  customUpload={true} multiple accept="image/*" maxFileSize={20000000}
                        onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
        </div>
    )
}
