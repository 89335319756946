import CommonDataService from './commondataservice';
import axios from 'axios';

class VendorDataService extends CommonDataService{
    async activate_deactivate(id,token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.put(this.baseURL+`${this.objectNames}/${id}/active`);
    }

    async getSearchVendorDataPagination(pageNumber,token, name){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view/all?p=${pageNumber}&name=${name}`, {timeout:5000});
    }
}

const vendorDataService = new VendorDataService('vendors', 'vendor');

export default vendorDataService

// export default new VendorDataService('vendors', 'vendor');