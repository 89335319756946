import React, {useEffect, useState} from "react";
import vendorDataService from '../services/vendors';
import userDataService from '../services/users';
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import {InputGroup} from "react-bootstrap";
import {Checkbox} from "primereact/checkbox";
import utils from "../services/utils";

const AddUser = props => {
    let editing = false;
    let initialUsername = "";
    let initialFirstName= "";
    let intitialLastName="";
    let initialIsSuperuser = false;
    let initialIsStaff = false;
    let initialPassword = "";
    let initialIsActive = true;
    let initialId = "";

    if(props.location.state && props.location.state.currentUser){
        editing = true;
        initialUsername = props.location.state.currentUser.username;
        initialFirstName = props.location.state.currentUser.first_name;
        intitialLastName = props.location.state.currentUser.last_name;
        initialIsSuperuser = props.location.state.currentUser.is_superuser;
        initialIsActive = props.location.state.currentUser.is_active;
        initialIsStaff =props.location.state.currentUser.is_staff;
        initialPassword = props.location.state.currentUser.password
        initialId = props.location.state.currentUser.id;

    }

    const[user, setUser] = useState("");
    const[groups, setGroups] = useState([]);
    const[username, setUsername] = useState(initialUsername);
    const[password, setPassword] = useState(initialPassword);
    // const[selectedUser, setSelectedUser] = useState(initialPassword);
    const[isStaff, setIsStaff] = useState(initialIsStaff);
    const[submitted, setSubmitted] = useState(false);
    const [isSuperUser, setIsSuperUser] = useState(initialIsSuperuser);
    const[firstName, setFirstName] = useState(initialFirstName);
    const[lastName, setLastName] = useState(intitialLastName);
    const[isActive, setIsActive] = useState(initialIsActive);

    useEffect(() =>{
        if(initialId){
            retrieveUser(initialId);
        }

        retrieveGroups();
    },[props.token]);

    const retrieveUser = (userId) =>{
        userDataService.getOne(userId,props.token).then(response=>{
            setUser(response.data);
        }).catch(e =>{
            console.log(e);
        });
    }

    const retrieveGroups = () => {
        userDataService.getGroups(props.token)
            .then(r=>{
                // console.log(r.data);
                setGroups(r.data);
            })
            .catch(e=> console.log(e));
    }

    const onChangeUsername = e => {
        const username = e.target.value;
        setUsername(username);
    }

    const onChangePassword = e => {
        const password = e.target.value;
        setPassword(password);
    }

    const onChangeIsStaff = e => {
        setIsSuperUser(false);
        setIsStaff(e.checked);
    }

    const onChangeLastName = e => {
        const lastName = e.target.value;
        setLastName(lastName);
    }


    // const onChangeIsActive = e => {
    //     const isActive = e.target.value;
    //     setIsActive(isActive);
    // }

    const onChangeFirstName = e =>{
        const firstName = e.target.value;
        setFirstName(firstName);
    }

    const onChangeIsSuperuser = e => {
        setIsSuperUser(e.checked);
        setIsStaff(false);
    }
    const saveUser = (e) => {
        e.preventDefault();
        var data;
        let group;
            if(isSuperUser){
                const filterGroup = groups.filter(group => group.name.toLowerCase() === 'admin')
                group = filterGroup[0].id;
            }
            else if(isStaff){
                const filterGroup = groups.filter(group => group.name.toLowerCase() === 'staff');
                group = filterGroup[0].id;
            }
            else{
                const filterGroup = groups.filter(group => group.name.toLowerCase() === 'vendor');
                group = filterGroup[0].id;
            }
        if(editing){
            data = {
                password: password,
                groups: [group],
                is_staff: isStaff,
                is_active: isActive,
                is_superuser: isSuperUser,
                first_name: firstName,
                username: username,
                last_name: lastName,
            }
            // console.log(data);
            userDataService.update(initialId, data, props.token).then(response =>{
                setSubmitted(true);
            }).catch(e =>{
                console.log(e);
            })

        }
        else{
            let group;
            if(isSuperUser){
                const filterGroup = groups.filter(group => group.name.toLowerCase() === 'admin')
                group = filterGroup[0].id;
            }
            else if(isStaff){
                const filterGroup = groups.filter(group => group.name.toLowerCase() === 'staff');
                group = filterGroup[0].id;
            }
            else{
                const filterGroup = groups.filter(group => group.name.toLowerCase() === 'vendor');
                group = filterGroup[0].id;
            }
            data = {
                groups: [group],
                password: password,
                is_staff: isStaff,
                is_active: isActive,
                is_superuser: isSuperUser,
                first_name: firstName,
                username: username,
                last_name: lastName,
            }
            // console.log(data);
            userDataService.create(data, props.token).then(response =>{
                setSubmitted(true);
            }).catch(e =>{
                console.log(e);
            });
        }

    }

    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(submitted?(
                    editing?(
                        <div>
                        <h4>Thông tin cập nhật thành công!</h4>
                        {/*<Link to ={{*/}
                        {/*    pathname:"/users/"+ initialId,*/}
                        {/*    state:{*/}
                        {/*        currentUserId:initialId*/}
                        {/*    }*/}
                        {/*}}>*/}
                        {/*Quay lại {username}*/}
                        {/*</Link>*/}
                        {/*<br/>*/}
                        <Link to={"/users/"}>Đi tới danh sách người dùng</Link>
                    </div>
                    ):(
                        <div>
                        <h4>Người dùng được tạo thành công</h4>
                        <Link to={"/users/"}>Quay lại danh sách người dùng</Link>
                    </div>
                    )

                ):(
                    <div>
                        {/*{editing?(initialIsStaff.is_active?'':(<Alert variant="warning">Nhà cung cấp không còn hoạt động!</Alert>)):''}*/}
                        <Form onSubmit={saveUser}>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Tên Tài Khoản</InputGroup.Text>
                                <Form.Control
                                    type='text'
                                    placeholder="Nhập tên tài khoản"
                                    value={username}
                                    onChange={onChangeUsername}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Mật Khẩu</InputGroup.Text>
                                <Form.Control
                                    type='password'
                                    placeholder="Nhập mật khẩu"
                                    disabled={editing}
                                    value={password}
                                    onChange={onChangePassword}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Họ</InputGroup.Text>
                                <Form.Control
                                    type='text'
                                    placeholder="Nhập họ"
                                    value={lastName}
                                    onChange={onChangeLastName}
                                />
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Tên</InputGroup.Text>
                                <Form.Control
                                    type='text'
                                    placeholder="Nhập tên"
                                    value={firstName}
                                    onChange={onChangeFirstName}
                                />
                            </InputGroup>
                             <InputGroup className="mb-3">
                                    <InputGroup.Text>Is Superuser?</InputGroup.Text>
                                    <Checkbox className="m-2" onChange={e => onChangeIsSuperuser(e)} checked={isSuperUser}></Checkbox>
                             </InputGroup>
                            <InputGroup className="mb-3">
                                    <InputGroup.Text>Is Staff?</InputGroup.Text>
                                    <Checkbox className="m-2" onChange={e => onChangeIsStaff(e)} checked={isStaff}></Checkbox>
                             </InputGroup>
                            <InputGroup className="mb-3">
                                    <InputGroup.Text>Is Active?</InputGroup.Text>
                                    <Checkbox className="m-2" onChange={e => setIsActive(e.checked)} checked={isActive}></Checkbox>
                             </InputGroup>
                            <Link to ={{
                                        pathname:"/users/",
                                    }}>
                                <Button variant="outline-primary" className="me-2"><b>&#8592;</b></Button>
                                </Link>
                            <Button variant="outline-warning" type="submit"
                                    // onClick={saveUser}
                            >{editing?"Cập Nhật":"Tạo Mới"}</Button>
                        </Form>

                    </div>

                ))


            }
        </Container>
    )

}

export default AddUser;