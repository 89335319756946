import "primereact/resources/themes/lara-light-blue/theme.css";
import 'primereact/resources/primereact.css'
import "primeicons/primeicons.css";
import { Galleria } from 'primereact/galleria';
import {
  S3Client,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import {
  getSignedUrl,
} from "@aws-sdk/s3-request-presigner";
import React, {useEffect, useState} from "react";
import shippedProductPhotoDataService from "../services/shippedproductphotos";
import Container from "react-bootstrap/Container";
import paymentPhotoDataService from "../services/paymentphotos";
import productPhotoDataService from "../services/productphotos";

export default function CommonImageDisplayHandler({keyId, value, token}) {
     useEffect( () => {
       retrievePhotos(keyId, value);
     }, [token]);

    const [photos, setPhotos] = useState(null);
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const accessKey = process.env.REACT_APP_ACCESS_KEY;
    const vultrBucketName = process.env.REACT_APP_BUCKET_NAME;
    const vultrEndpoint = process.env.REACT_APP_HOST_NAME;
    const [activeIndex, setActiveIndex] = useState(0);
    const responsiveOptions = [
        {
            breakpoint: '991px',
            numVisible: 4
        },
        {
            breakpoint: '767px',
            numVisible: 3
        },
        {
            breakpoint: '575px',
            numVisible: 1
        }
    ];

    const s3Client = new S3Client({
        region: 'us-east-1',
        endpoint: vultrEndpoint,
        forcePathStyle: false,
        credentials: {
            accessKeyId: accessKey,
            secretAccessKey: secretKey,
          },
    });

    const createPresignedUrl = (key) => {
        const command = new GetObjectCommand({Bucket:vultrBucketName, Key:key});
        return getSignedUrl(s3Client, command, {expiresIn:3600});
    }

    const next = () => {
        setActiveIndex(prevState => (prevState === photos.length - 1) ? 0 : prevState + 1)
    }

    const prev = () => {
        setActiveIndex((prevState) => (prevState === 0 ? 0 : prevState - 1));
    };

    const indicatorTemplate = (index) => {
        return <span style={{ color: '#ffffff', cursor: 'pointer'}}>{index + 1}</span>;
    };

    const itemTemplate = (item) => {
        return <img src={item}  style={{ width: '100%', display: 'block' }} />;
    }

    const thumbnailTemplate = (item) => {
        return <img src={item} style={{ width: '25%', display: 'block' }} />;
    }

    async function getURL(key){
        return await createPresignedUrl(key);
    }

    const retrievePhotos = (keyId, value) => {
      if(keyId === "payment_id"){
        paymentPhotoDataService.getAllPaymentProductPhotos(token, value)
            .then(
                r => {
                    let photoList = [];
                    const photoIds = r.data;
                    photoIds.forEach((photoId) =>{
                        const url = getURL(photoId.photo_vn);
                        photoList.push(url);
                    })
                    Promise.all(photoList).then(values =>{
                        setPhotos(values);
                    }).catch(e=> console.log(e));
                }
            )
            .catch(e => console.log(e));
      }
      else if(keyId === "product_id"){
        productPhotoDataService.getAllProductPhotos(token, value)
            .then(
                r => {
                    let photoList = [];
                    const photoIds = r.data;
                    photoIds.forEach((photoId) =>{
                        const url = getURL(photoId.photo_vn);
                        photoList.push(url);
                    })
                    Promise.all(photoList).then(values =>{
                        setPhotos(values);
                    }).catch(e=> console.log(e));
                }
            )
            .catch(e => console.log(e));
      }


    }


    return(
        <Container>
          <div className="card">
           <Galleria
                value={photos}
                style={{ maxWidth: '75vw'}}
                className="custom-indicator-galleria"
                circular
                showItemNavigators
                showThumbnails={false}
                showIndicators
                showIndicatorsOnItem
                indicatorsPosition="top"
                item={itemTemplate}
                indicator={indicatorTemplate}
            />
        </div>
        </Container>
    )

}