import React, {useEffect, useRef, useState} from "react";
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
// import { Button } from 'primereact/button';
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import orderDataService from "../services/orders";
import orderedProductDataService from "../services/orderedproducts";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Accordion from 'react-bootstrap/Accordion';
import shippedProductDataService from "../services/shippedproducts";
import shipmentDataService from "../services/shipments";
import { Toast } from 'primereact/toast';
import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup'; // To use confirmPopup method
import "primereact/resources/themes/lara-light-blue/theme.css";
import {
    FaFloppyDisk,
    FaPlus,
    FaImages,
    FaXmark,
    FaArrowLeft,
    FaTrash,
    FaPencil,
    FaTruckFast,
    FaPlane,
    FaDollarSign, FaTrashCan
} from "react-icons/fa6";
import {InputGroup, Tab, Tabs} from "react-bootstrap";
import {FaShippingFast} from "react-icons/fa";
import PaymentListTemplate from "./payment-list-template";
import paymentDataService from "../services/payments";
import CommonImagePreviewHandler from "./common-image-preview-handler";
import { Tag } from 'primereact/tag';
import utils from "../services/utils";
import shippedProductPhotoDataService from "../services/shippedproductphotos";
import VNCurrencyInput from "./vn-currency-input-mask";
import CommonGalleryDisplayHandler from "./common-gallery-preview-handler";
import telegramServices from "../services/telegram";


const ViewShipment = props => {
    let newShipment = false;
    let initialShipmentId = "";
    let initialNote = "";
    let initialOrderId = "";
    let initialShippingCost = 0;
    let isDelivered = false;
    let isEditLabel = false;
    let initialIsSentNotification = false;
    let initialLabel = "";
    let initialVendorId = "";
    let initialVendorName = "";
    let initialActiveTab = 0;
    let fromOrder = false;
    let initialFromOtherPageActiveTab = 0;


    if(props.location.state && props.location.state.currentOrder){
        initialOrderId = props.location.state.currentOrder.order_id;
    }
    else{
        initialShipmentId = props.location.pathname.replace('/shipment/', '').replace('/view', '');
    }
    if(props.location.state && props.location.state.currentShipmentId){
        initialShipmentId = props.location.state.currentShipmentId;
    }
    else{
        initialShipmentId = props.location.pathname.replace('/shipment/', '').replace('/view', '');
    }

    if(props.location.state && props.location.state.currentShipment){
        initialShipmentId = props.location.state.currentShipment.shipment_id;
        initialShippingCost = utils.formatCurrency(props.location.state.currentShipment.shipping_cost);
        initialLabel = props.location.state.currentShipment.label;
        initialNote = props.location.state.currentShipment.notes;
        initialVendorId = props.location.state.currentShipment.vendor_id;
        initialVendorName = props.location.state.currentShipment.vendor_name;
        initialIsSentNotification = props.location.state.currentShipment.is_notification_sent;
    }
    else{
        initialShipmentId = props.location.pathname.replace('/shipment/', '').replace('/view', '');
    }

    if(props.location.state && props.location.state.eventKey){
        initialActiveTab = props.location.state.eventKey;
    }

    if(props.location.state && props.location.state.shipmentPageEventKey){
        initialFromOtherPageActiveTab = props.location.state.shipmentPageEventKey;
    }

    if(props.location.state && props.location.state.fromOrder){
        fromOrder = props.location.state.fromOrder;
    }

    if(props.location.state && props.location.state.orderId){
        initialOrderId = props.location.state.orderId;
    }

    const [currentShipment, setCurrentShipment] = useState("");
    const [currentShipmentOrderProducts, setCurrentShipmentOrderProducts] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState("");
    const [allIncompleteOrder, setAllIncompleteOrder] = useState([]);
    const [note, setNote] = useState(initialNote);
    const [validated, setValidated] = useState(false);
    const [shippingCost, setShippingCost] = useState(initialShippingCost);
    const [selectedOrderQuantity, setSelectedOrderQuantity] = useState(0);
    const [selectedOrderQuantityShipped, setSelectedOrderQuantityShipped] = useState(0);
    const [ordersData, setOrdersData] = useState([]);
    const toastUpdateNotification = () => toast.current.show({ severity: 'success', detail: "Cập nhật thông tin hợp lệ thành công!" });
    const toastDeleteNotification = () => toast.current.show({ severity: 'success', detail: "Xóa thành công!" });

    const [editLabel, setEditLabel] = useState(isEditLabel);
    const [label, setLabel] = useState(initialLabel);
    const [shipmentStatus, setShipmentStatus] = useState("Mới");
    const [shipmentStatusUS, setShipmentStatusUS] = useState("");
    const [shipmentPayments, setShipmentPayments] = useState([]);
    const [currentVendor, setCurrentVendor] = useState(initialVendorId);
    const [currentVendorName, setCurrentVendorName] = useState(initialVendorName);
    const [paymentStatus, setPaymentStatus] = useState("");
    const [isNotificationSent, setIsNotificationSent] = useState(initialIsSentNotification);
    const toast = useRef(null);
    const telegramToken = process.env.REACT_APP_TEL_TOKEN_FIRST + ":" + process.env.REACT_APP_TEL_TOKEN_SECOND;
    const adminChatId = process.env.REACT_APP_TELEGRAM_CHAT_ID_ADMIN;
    const currentEnv = process.env.REACT_APP_ENV;



    useEffect(() =>{
        // console.log(props.location.pathname.replace('/shipment/', '').replace('/view', ''));
        retrieveShipment(initialShipmentId);
        retrieveAllShipmentOrders(initialShipmentId);
        retrieveShipmentPayments(initialShipmentId);
        // getShipmentPageInfo();

    }, [props.token]);


    const handleFilteredBlankOrders = (orders) => {
        return orders.filter(order => (order.order_quantity > 0) && (!order.is_complete));
    }

    const handleFilterCurrentVendor = (orders, vendorId) => {
        return orders.filter( order => order.vendor_id === vendorId);
    }

    const getShipmentPageInfo = () =>{
        retrieveShipment(initialShipmentId);
        retrieveAllShipmentOrders(initialShipmentId);
        retrieveShipmentPayments(initialShipmentId);
    }

    const retrieveAllShipmentOrders = (shipmentId) =>{
        orderDataService.getAllIncompleteOrCompleteOrder(props.token)
            .then(r => {
                const allIncompleteOrders = r.data;
                const filteredBlankOrders = handleFilteredBlankOrders(allIncompleteOrders);
                const filteredVendorOrders = handleFilterCurrentVendor(filteredBlankOrders, currentVendor);
                shippedProductDataService.getAll(props.token, shipmentId)
                    .then(r => {
                        const shippedProducts = r.data;
                        // console.log('shippedProducts before: ',shippedProducts);
                        shippedProducts.forEach((shippedProduct) =>{
                            // console.log("shippedProduct:", shippedProduct);
                            if(shippedProduct.quantity_received === null){
                                shippedProduct.quantity_received = shippedProduct.quantity_sent;
                                // console.log("shippedProduct.quantity_receive", shippedProduct.quantity_receive);
                            }
                        })
                        // console.log('shippedProducts after: ',shippedProducts);
                        setCurrentShipmentOrderProducts(shippedProducts);
                        var currentShipmentOrders = shippedProducts.map((shippedProduct)=> shippedProduct.order_id);
                        var currentShipmentOrdersSet = new Set(currentShipmentOrders);
                        let filter_orders = "";
                        // filter out current orders in the shipment
                        currentShipmentOrdersSet.forEach(shipmentId =>{
                            filter_orders = filter_orders + shipmentId + "|"
                        })
                        if (filter_orders.length > 0){
                            orderDataService.getOrdersById(props.token, filter_orders)
                            .then(r=>{
                                const orders = r.data;
                                setOrdersData(orders);
                                // getShippedOrdersData(orders, filteredBlankOrders);
                                getShippedOrdersData(orders, filteredVendorOrders);
                            })
                                .catch(e => console.log(e));

                        }
                        else{
                            setOrdersData([]);
                            setAllIncompleteOrder(filteredVendorOrders);
                            // setAllIncompleteOrder(filteredBlankOrders);
                        }

                    })
                    .catch(e=>console.log(e));
            })
            .catch(e => console.log(e));
    }

    const getShippedOrdersData = (orders, filtered_order) => {
        orders.forEach((order)=>{
            filtered_order = filterOutSelectedOrder(order.order_id, filtered_order);
        })
        setAllIncompleteOrder(filtered_order);
    }

    const retrieveShipmentPayments = (shipmentId) =>{
        paymentDataService.getAllPaymentsForShipment(props.token, shipmentId)
            .then( r=>{
                const payments = r.data;
                setShipmentPayments(payments);
            })
            .catch(e => console.log(e))
    }



    const handleFilterOrderProduct = (orderId, shippedOrderedProducts) =>{
        return shippedOrderedProducts.filter(shippedOrderedProduct => shippedOrderedProduct.order_id === orderId)
    }

    //to remove the selected order from add order list or the order in the shipment
    const filterOutSelectedOrder = (orderId, orderOptions) =>{
        return orderOptions.filter(orderOption => orderOption.order_id !== orderId)
    }


    const retrieveSelectedOrder = (orderId) =>{
        orderDataService.getOne(orderId, props.token)
            .then(response => {
                const order = response.data;
                setSelectedOrderQuantity(order.order_quantity);
                setSelectedOrderQuantityShipped(order.order_true_shipped_quantity);
            })
    }

    const retrieveShipment = (shipmentId) =>{
        shipmentDataService.getOne(shipmentId, props.token)
            .then(response => {
                const shipmentData = response.data;
                setCurrentShipment(shipmentData);
                if(shipmentData.shipping_cost > 0){
                    setShippingCost(utils.formatCurrency(shipmentData.shipping_cost));
                }
                setNote(shipmentData.notes);
                setLabel(shipmentData.label);
                setCurrentVendor(shipmentData.vendor_id);
                setCurrentVendorName(shipmentData.vendor_name);
                setIsNotificationSent(shipmentData.is_notification_sent);
                if(shipmentData.is_delivered_vn || shipmentData.is_delivered_us){
                    setShipmentStatus("Đã Nhận")
                }
                if(shipmentData.is_delivered_us){
                    setShipmentStatusUS("Đã Nhận ở Mỹ")
                }
                if(shipmentData.payment_status === 'overpaid')
                    setPaymentStatus("Thanh Toán Lố")
                else if(shipmentData.payment_status === 'partiallypaid')
                    setPaymentStatus("Chưa Thanh Toán Đủ")
                else{
                    setPaymentStatus("Đã Thanh Toán");
                }
            })
            .catch(e =>{
                console.log(e);
            });
    }

    const onChangeShippingCost = e =>{
        const cost = e.target.value;
        setShippingCost(cost);
    }

    const onChangeSelectedOrder = e => {
        const selectedOrder = e.target.value;
        console.log(selectedOrder);
        setSelectedOrder(selectedOrder);
        if (selectedOrder!== '')
            retrieveSelectedOrder(selectedOrder);
    }

    const onChangeRemainingQuantity = (e, sp_id) => {
        const {name, value} = e.target;
        const editData = currentShipmentOrderProducts.map((item) =>
            item.sp_id === sp_id && name?{...item, [name]: value}:item
        )
        // console.log('editData: ',editData);
        setCurrentShipmentOrderProducts(editData);
    }

    const onChangeNote = e => {
        const note = e.target.value;
        setNote(note);
    }

    const onChangeLabel = e => {
        const label = e.target.value;
        setLabel(label);
    }

    const updateEditLabel = () => {
        setEditLabel(true);
    }

    const handleShippingVN = (event) =>{
        confirmPopup({
            target: event.currentTarget,
            message: 'Bạn sẽ không thể thay đổi tình trạng vận chuyển sau khi nhấn "Xác Nhận". Bạn chắc chứ?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Xác Nhận",
            rejectLabel: "Quay Lại",
            accept: () => onClickShippingVN()
        })
    }

    const onClickShippingVN = () => {
        const shippingCostInt = utils.parseVNCurrencyInput(shippingCost);
        const shippedProductQuantityArray = currentShipmentOrderProducts.map((currentShipmentOrderProduct) => utils.getInt(currentShipmentOrderProduct.quantity_received));
        const shippedProductQuantitySum = shippedProductQuantityArray.reduce((partialSum, a) => partialSum+ a, 0);
        // console.log("shippedProductQuantitySum", shippedProductQuantitySum);
        // console.log("shippingCost:", shippingCost);
        const shipping_cost_per_item = shippedProductQuantitySum>0?utils.getInt(shippingCostInt/shippedProductQuantitySum):0;
        currentShipmentOrderProducts.forEach(currentShipmentOrderProduct => {
            const shipProductData = {
                quantity_sent: currentShipmentOrderProduct.quantity_sent,
                quantity_received: currentShipmentOrderProduct.quantity_received,
                status: 'in_vn',
                shipping_cost_per_item_vn: utils.getInt(shipping_cost_per_item),
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
                shipment_id: currentShipmentOrderProduct.shipment_id,
                op_id: currentShipmentOrderProduct.op_id
            }
            shippedProductDataService.update(currentShipmentOrderProduct.sp_id, shipProductData, props.token)
                .then(r=>{

                })
                .catch(e => console.log(e));
        })
        shipmentDataService.deliver(currentShipment.shipment_id, 'vn', props.token)
            .then(r=>{
                toastUpdateNotification();
                retrieveShipment(initialShipmentId);
            })
            .catch(e => console.log(e));
    }

    // const handleShippingUS = (event) =>{
    //     confirmPopup({
    //         target: event.currentTarget,
    //         message: 'Bạn sẽ không thể thay đổi tình trạng vận chuyển sau khi nhấn "Xác Nhận". Bạn chắc chứ?',
    //         icon: 'pi pi-exclamation-triangle',
    //         acceptLabel: "Xác Nhận",
    //         rejectLabel: "Quay Lại",
    //         accept: () => onClickShippingUS()
    //     })
    // }
    //
    // const onClickShippingUS = () => {
    //     currentShipmentOrderProducts.forEach(currentShipmentOrderProduct => {
    //         const shipProductData = {
    //             quantity_sent: currentShipmentOrderProduct.quantity_sent,
    //             quantity_received: currentShipmentOrderProduct.quantity_received,
    //             status: 'in_us',
    //             last_modified_by: localStorage.getItem('username'),
    //             last_modified_date: moment(),
    //             shipment_id: currentShipmentOrderProduct.shipment_id,
    //             op_id: currentShipmentOrderProduct.op_id
    //         }
    //         shippedProductDataService.update(currentShipmentOrderProduct.sp_id, shipProductData, props.token)
    //             .then(r=>{
    //
    //             })
    //             .catch(e => console.log(e));
    //     })
    //     shipmentDataService.deliver(currentShipment.shipment_id, 'us', props.token)
    //         .then(r=>{
    //             toastUpdateNotification();
    //             retrieveShipment(initialShipmentId);
    //         })
    //         .catch(e => console.log(e));
    // }

    const saveShipment = () => {
        const shippingCostInt = utils.parseVNCurrencyInput(shippingCost);
        if (currentShipment.shipping_cost !== shippingCostInt || currentShipment.notes !== note || currentShipment.label !==label){
            var data = {
                label: label,
                shipping_cost: shippingCostInt,
                notes:note,
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
            }
            shipmentDataService.update(initialShipmentId, data, props.token)
                .then(r => {
                    setEditLabel(false);
                    retrieveShipment(initialShipmentId);
                    retrieveAllShipmentOrders(initialShipmentId);
                    retrieveShipmentPayments(initialShipmentId);
                })
                .catch(e =>{
                    console.log(e);
                })
        }
        const shippedProductQuantityArray = currentShipmentOrderProducts.map((currentShipmentOrderProduct) => utils.getInt(currentShipmentOrderProduct.quantity_received));
        const shippedProductQuantitySum = shippedProductQuantityArray.reduce((partialSum, a) => partialSum+ a, 0);
        // console.log("shippedProductQuantitySum", shippedProductQuantitySum);
        // console.log("shippingCost:", shippingCost);
        const shipping_cost_per_item = shippedProductQuantitySum>0?utils.getInt(shippingCostInt/shippedProductQuantitySum):0;
        // console.log('shipping_cost_per_item: ', shipping_cost_per_item);
        currentShipmentOrderProducts.forEach((currentShipmentOrderProduct) => {
            if(props.role === 'vendor'){
                let shippedProductUpdateData = {
                    shipment_id: currentShipmentOrderProduct.shipment_id,
                    op_id: currentShipmentOrderProduct.op_id,
                    shipping_cost_per_item_vn: shipping_cost_per_item,
                    quantity_sent: utils.getInt(currentShipmentOrderProduct.quantity_sent),
                    total_quantity: utils.getInt(currentShipmentOrderProduct.total_quantity),
                    last_modified_by: localStorage.getItem('username'),
                    last_modified_date: moment(),
                }
                // console.log(shippedProductUpdateData);
                shippedProductDataService.update(currentShipmentOrderProduct.sp_id, shippedProductUpdateData, props.token)
                    .then(r=>{

                    })
                    .catch(e=>{
                        console.log(e);
                    });
                if(currentShipmentOrderProduct.quantity_sent!==0 && currentShipmentOrderProduct.quantity_sent <= currentShipmentOrderProduct.total_quantity){
                    // toastUpdateNotification();
                    retrieveShipment(initialShipmentId);
                    retrieveAllShipmentOrders(initialShipmentId);
                    retrieveShipmentPayments(initialShipmentId);
                }
            }
            else {
                let shippedProductUpdateData = {
                        shipment_id: currentShipmentOrderProduct.shipment_id,
                        op_id: currentShipmentOrderProduct.op_id,
                        quantity_sent: utils.getInt(currentShipmentOrderProduct.quantity_sent),
                        shipping_cost_per_item_vn: shipping_cost_per_item,
                        quantity_received: utils.getInt(currentShipmentOrderProduct.quantity_received),
                        total_quantity: utils.getInt(currentShipmentOrderProduct.total_quantity),
                        last_modified_by: localStorage.getItem('username'),
                        last_modified_date: moment(),
                }
                // console.log('shippedProductUpdateData: ', (shippedProductUpdateData));
                shippedProductDataService.update(currentShipmentOrderProduct.sp_id, shippedProductUpdateData, props.token)
                    .then(r => {

                    })
                    .catch(e => {
                        console.log(e);
                    });
                if (currentShipmentOrderProduct.quantity_sent !== 0 &&
                    currentShipmentOrderProduct.quantity_sent <= utils.getInt(currentShipmentOrderProduct.total_quantity) &&
                    utils.getInt(currentShipmentOrderProduct.quantity_received) <= utils.getInt(currentShipmentOrderProduct.quantity_sent)) {
                    // toastUpdateNotification();
                    retrieveShipment(initialShipmentId);
                    retrieveAllShipmentOrders(initialShipmentId);
                    retrieveShipmentPayments(initialShipmentId);
                }
            }
        })
        toastUpdateNotification();

    }

    const saveQuantityFields = (e, sp_id) =>{
        const editData = currentShipmentOrderProducts.filter((item) => item.sp_id === sp_id);
        const quantity_sent = editData[0].quantity_sent;
        const quantity_received = editData[0].quantity_received;
        const total_quantity = editData[0].total_quantity;
        console.log(editData);
        if(props.role === 'vendor'){
            let shippedProductUpdateData = {
                shipment_id: editData[0].shipment_id,
                op_id: editData[0].op_id,
                quantity_sent: utils.getInt(quantity_sent),
                total_quantity: total_quantity,
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
            }
            console.log(shippedProductUpdateData);
            shippedProductDataService.update(sp_id, shippedProductUpdateData, props.token)
                .then(r=>{

                })
                .catch(e=>{
                    console.log(e);
                });
            if(quantity_sent!==0 && quantity_sent <= total_quantity){
                toastUpdateNotification();
                retrieveShipment(initialShipmentId);
                retrieveAllShipmentOrders(initialShipmentId);
            }
        }
        else {
            let shippedProductUpdateData = {
                shipment_id: editData[0].shipment_id,
                op_id: editData[0].op_id,
                quantity_sent: utils.getInt(quantity_sent),
                quantity_received: utils.getInt(quantity_received),
                total_quantity: total_quantity,
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
            }
            console.log(shippedProductUpdateData);
            shippedProductDataService.update(sp_id, shippedProductUpdateData, props.token)
                .then(r => {

                })
                .catch(e => {
                    console.log(e);
                });
            if (quantity_sent !== 0 && quantity_sent <= total_quantity && quantity_received <= quantity_sent) {
                toastUpdateNotification();
                retrieveShipment(initialShipmentId);
                retrieveAllShipmentOrders(initialShipmentId);
            }
        }
    }

    const saveShippedOrdersForSelectedOrder = (orderId) => {
        let note = "Đơn giao hàng cho cho đơn hàng: ";
        let label = "Đơn giao hàng từ "
        const names = ordersData.map((order) => order.name);
        const vendors = new Set(ordersData.map((order) => order.vendor_name));
        const vendorId = new Set(ordersData.map((order) => order.vendor_id));
        names.forEach((name) => note = note + name +" ");
        setNote(note);
        vendors.forEach((vendor) => label = label + vendor + "-");
        orderedProductDataService.getAll(props.token, orderId)
            .then(r => {
                const selectedOrderProducts = r.data;
                selectedOrderProducts.forEach((selectedOrderProduct) =>{
                    if(!selectedOrderProduct.is_fulfilled){
                        const shippedProductData = {
                        shipment_id: initialShipmentId,
                        op_id: selectedOrderProduct.op_id,
                        total_quantity: selectedOrderProduct.quantity,
                        last_modified_by: localStorage.getItem('username'),
                        created_by: localStorage.getItem('username'),
                        }
                        shippedProductDataService.create(shippedProductData, props.token)
                            .then( r => {

                            })
                            .catch(e => {
                                console.log(e);
                        });
                    }

                })

            })
            .catch(e => {
                console.log(e);
            });
        if(currentShipment.label !== label || currentShipment.vendor_id !== vendorId){
            var shipmentUpdateData = {
                label: label,
                notes: note,
                vendor_id: vendorId
            }
            shipmentDataService.update(initialShipmentId, shipmentUpdateData, props.token)
                .then(r => {
                    retrieveShipment(initialShipmentId)
                    // getShipmentPageInfo();
                    retrieveAllShipmentOrders(initialShipmentId);
                })
                .catch(e =>{
                    console.log(e);
                })
        }
        setSelectedOrder("");
        retrieveShipment(initialShipmentId);
        retrieveAllShipmentOrders(initialShipmentId);
        retrieveShipmentPayments(initialShipmentId);
    }

    const deleteShippedOrder = (event, order_id) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Bạn muốn bỏ đơn hàng này khỏi đơn vận chuyển?',
            acceptLabel:"Xác Nhận",
            rejectLabel: "Không",
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDeleteShipmentOrder(event, order_id),
        })
    }

    const handleDeleteShipmentOrder = (e, order_id) => {
        shippedProductDataService.getAllShippedProdForOrder(props.token, order_id)
            .then(r => {
                const shippedProducts = r.data;
                shippedProducts.forEach((shippedProduct) => {
                    shippedProductDataService.delete(shippedProduct.sp_id, props.token)
                        .then( r=>{
                            shippedProductPhotoDataService.getAllShippedProductPhotos(props.token, shippedProduct.sp_id)
                                .then( r=>{
                                    const photos = r.data;
                                    photos.forEach(photo =>{
                                        shippedProductPhotoDataService.delete(photo.sp_photo_id, props.token)
                                            .then(r=>{

                                            }).catch(e => console.log(e));
                                    })
                                }).catch(e => console.log(e));
                         }
                        )
                        .catch(e => console.log(e));
                });
                retrieveShipment(initialShipmentId);
                retrieveAllShipmentOrders(initialShipmentId);
                retrieveShipmentPayments(initialShipmentId);
                toastDeleteNotification();
            })
    }

    const deleteShippedOrderedProduct = (event, sp_id) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Bạn muốn bỏ sản phẩm khỏi đơn vận chuyển?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel:"Xác Nhận",
            rejectLabel: "Không",
            accept: () => handleDeleteShippedOrderedProduct(event, sp_id),
        })
    }
    const handleDeleteShippedOrderedProduct = (e, sp_id) => {
        shippedProductDataService.delete(sp_id, props.token)
            .then( r => {
                shippedProductPhotoDataService.getAllShippedProductPhotos(props.token, sp_id)
                    .then( r=>{
                        const photos = r.data;
                        photos.forEach(photo =>{
                            shippedProductPhotoDataService.delete(photo.sp_photo_id, props.token)
                                .then(r=>{

                                }).catch(e => console.log(e));
                        })
                    }).catch(e => console.log(e));
                toastDeleteNotification();
                // retrieveShipment(initialShipmentId);
                retrieveShipment(initialShipmentId);
                retrieveAllShipmentOrders(initialShipmentId);
                retrieveShipmentPayments(initialShipmentId);
            }
            )
            .catch(e => console.log(e));

    }

    const sendTelegramMessage = () =>{
        let message = `Có shipment từ ${currentVendorName} ! Hãy bấm vào ${utils.getBaseUrl(currentEnv)}shipment/${initialShipmentId}/view để kiểm tra đơn giao hàng!`;
        telegramServices.sendMessage(telegramToken, adminChatId, message)
            .then(r=>{
                shipmentDataService.notify(initialShipmentId, props.token)
                    .then( r=>{
                        setIsNotificationSent(true);
                        toast.current.show({ severity: 'success', detail: "Cám ơn bạn vì đã thông báo đơn hàng đã được gửi!" });
                    })
                    .catch(e=>console.log(e));
                // console.log(r);
            }).catch(e => console.log(e));

    }


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        setValidated(true);
        if(selectedOrder !== ''){
           saveShippedOrdersForSelectedOrder(selectedOrder);
        }
        retrieveShipment(initialShipmentId);
        retrieveAllShipmentOrders(initialShipmentId);
    // getShipmentPageInfo();
  };


    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <Toast ref={toast} />
                        <h2>Chi Tiết Đơn Giao Hàng</h2>
                        <Card>
                            {
                                props.role!=="vendor"?<Card.Header>Nhà Cung Cấp: {currentShipment.vendor_name}</Card.Header>:""
                            }
                            <Card.Body>
                                <Container fluid className="">
                                    <Row>
                                        {!editLabel?<Col><Card.Title>
                                            {
                                            props.role === 'vendor'?<Tag severity="success" value={shipmentStatus}></Tag>:(
                                                    currentShipment.is_delivered_us?<Tag severity="success" value={shipmentStatus}></Tag>:<Tag severity="success" value={shipmentStatus}></Tag>
                                            )
                                            }
                                            &nbsp;&nbsp;{label}&nbsp;<Button onClick={updateEditLabel}><FaPencil size="0.9em"/></Button></Card.Title></Col>:(
                                            <>
                                                <InputGroup>
                                                    <InputGroup.Text>Tên Đơn Hàng</InputGroup.Text>
                                                    <Form.Control
                                                            type='text'
                                                            placeholder="Nhập tên đơn hàng"
                                                            value={label}
                                                            onChange={(e) =>onChangeLabel (e)}
                                                        />
                                                        <ConfirmPopup/>
                                                </InputGroup>
                                            </>
                                        )}
                                    </Row>
                                    <Row>
                                        <InputGroup className="">
                                            <InputGroup.Text className="mb-3">Phí Giao Hàng</InputGroup.Text>
                                            <VNCurrencyInput value={shippingCost} onChange={onChangeShippingCost} type="text" className="border-solid border-1 p-2 mb-3 xl:w-9"/>
                                          {/*  <Form.Control*/}
                                          {/*      className="mb-3"*/}
                                          {/*      type="numeric"*/}
                                          {/*      value={shippingCost}*/}
                                          {/*      onChange={onChangeShippingCost}*/}
                                          {/*  // onMouseLeave={saveShippingCost}*/}
                                          {/*/>*/}
                                            {
                                            paymentStatus === "Thanh Toán Lố"?<Tag severity="danger" value={paymentStatus} icon="pi pi-times" className="mb-3"></Tag>:
                                                paymentStatus === "Chưa Thanh Toán Đủ"?<Tag severity="warning" value={paymentStatus} icon="pi pi-exclamation-triangle" className="mb-3"></Tag>:
                                                    <Tag severity="success" value={paymentStatus} className="mb-3"></Tag>
                                            }
                                        </InputGroup>
                                    </Row>
                                    <Row>
                                        <Col>
                                             <Form.Group as={Col}>
                                                <Form.Label>Ghi Chú</Form.Label>
                                                 <Form.Control
                                                    as="textarea"
                                                    row={3}
                                                    value={note}
                                                    onChange={onChangeNote}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                        {
                            !fromOrder?
                                props.role==="vendor"?
                                    <Link to ={{
                                    pathname:"/orders/",
                                    state:{
                                        eventKey: initialFromOtherPageActiveTab
                                    }
                                    }}>
                                <Button variant="outline-primary" className="me-2"><FaArrowLeft title="Quay Lại" size="1.2em"/></Button></Link>:
                                <Link to ={{
                                    pathname:"/shipments/",
                                    state:{
                                        eventKey: initialFromOtherPageActiveTab
                                    }
                                }}>
                                <Button variant="outline-primary" className="me-2"><FaArrowLeft title="Quay Lại" size="1.2em"/></Button>
                                </Link>:
                                <Link to ={{
                                    pathname:"/order/" + initialOrderId + "/view",
                                    state:{
                                        eventKey: initialFromOtherPageActiveTab
                                    }

                                }}>
                                <Button variant="outline-primary" className="me-2"><FaArrowLeft title="Quay Lại" size="1.2em"/></Button>
                                </Link>

                        }

                        {
                            currentShipment.is_delivered_vn?"":(
                                <>
                                    <Button variant="warning" onClick={saveShipment} className="me-2"><FaFloppyDisk size="1.2em"/>&nbsp;Cập Nhật</Button>
                                    {
                                        (props.role === 'vendor' && !isNotificationSent)?<Button variant="warning" onClick={sendTelegramMessage} className="me-2"><FaTruckFast size="1.2em"/>&nbsp;Đã Giao Hàng</Button>:""

                                    }
                                </>
                            )
                        }
                        {
                            // props.role!=='vendor'?(currentShipment.is_delivered_us?"":(
                            //
                            //     currentShipment.is_delivered_vn?(props.role==='admin'?(<Button variant="outline-success" onClick={(e) => handleShippingUS(e)} className="me-2"><FaPlane size="1.2em"/>&nbsp;Đã Nhận (US)</Button>):""):
                            //         <Button variant="outline-success" onClick={(e) => handleShippingVN(e)}><FaShippingFast size="1.2em" className="me-2"/>&nbsp;Đã Nhận (VN)</Button>
                            // )):""
                            props.role!=='vendor'? (!currentShipment.is_delivered_vn?<Button variant="outline-success" onClick={(e) => handleShippingVN(e)}><FaShippingFast size="1.2em" className="me-2"/>&nbsp;Đã Nhận (VN)</Button>:""):""
                        }
                        <Tabs defaultActiveKey={initialActiveTab} className="mb-3">
                            <Tab eventKey="0" title="Thông Tin Giao Hàng" className="mb-3">
                                <Tab.Content>
                                    {
                                        ordersData.map((currentShipmentOrder) =>{
                                            return(
                                                <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0" >
                                                    <Accordion.Header>{currentShipmentOrder.name} &nbsp; &nbsp; {currentShipment.is_delivered?"":<Button id={currentShipmentOrder.order_id} variant="outline-danger" onClick={(e) =>deleteShippedOrder(e, currentShipmentOrder.order_id)}><FaTrashCan title="Xóa" size="1.2em"/></Button>}</Accordion.Header>
                                                </Accordion.Item>
                                                    <Table bordered hover>
                                                    <thead>
                                                    <tr>
                                                        {
                                                            props.role!== 'staff'?
                                                                <>
                                                                <th>Sản Phẩm</th>
                                                                <th>Số Lượng Đặt</th>
                                                                <th>Đã Gửi (Nếu Có) </th>
                                                                </>

                                                            :(<th>Hình Lúc Gửi</th>)
                                                        }

                                                        <th>Số Lượng Gửi Lần Này</th>
                                                        {
                                                            props.role !== 'vendor'?<th>Nhận Lần Này</th>: ""
                                                        }
                                                        <th></th>
                                                    </tr>
                                                    </thead>
                                                    {
                                                        currentShipmentOrderProducts.length > 0?
                                                        handleFilterOrderProduct(currentShipmentOrder.order_id, currentShipmentOrderProducts).map(({sp_id,
                                                                                                                                                   product_name,
                                                                                                                                                   product_quantity,
                                                                                                                                                   vendor_true_shipped_quantity,
                                                                                                                                                   quantity_sent,
                                                                                                                                                   quantity_received}) =>{
                                                            return(
                                                                <tbody>
                                                                    <tr key={sp_id}>
                                                                        {
                                                                            props.role!== 'staff'?(
                                                                                <>
                                                                                    <td>{product_name}</td>
                                                                                    <td>{product_quantity}</td>
                                                                                    <td>{vendor_true_shipped_quantity}</td>
                                                                                </>
                                                                            ): (<td>
                                                                                {/*<CommonImagePreviewHandler*/}
                                                                                {/*    token={props.token}*/}
                                                                                {/*    value={sp_id}*/}
                                                                                {/*    keyId={"sp_id"}*/}
                                                                                {/*/>*/}
                                                                                <CommonGalleryDisplayHandler
                                                                                    token={props.token}
                                                                                    value={sp_id}
                                                                                    keyId={"sp_id"}
                                                                                />
                                                                            </td>)
                                                                        }

                                                                        <td><Form.Group as={Col} controlId="remainingValidation">
                                                                            {
                                                                                props.role!=='staff'?
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    name="quantity_sent"
                                                                                    placeholder={product_quantity}
                                                                                    required
                                                                                    isInvalid={!parseInt(quantity_sent) > 0 || (utils.getInt(quantity_sent) + vendor_true_shipped_quantity - quantity_received)  > product_quantity}
                                                                                    value={quantity_sent}
                                                                                    onChange={(e) => onChangeRemainingQuantity(e, sp_id)}
                                                                                />:
                                                                                    <Form.Text>{quantity_sent}</Form.Text>
                                                                            }
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    Kiểm tra lại số lượng!
                                                                                </Form.Control.Feedback>

                                                                        </Form.Group>
                                                                        </td>
                                                                        {
                                                                            props.role !=="vendor"?(
                                                                                <td><Form.Group as={Col} controlId="receivedValidation">
                                                                                 <Form.Control
                                                                                    type="number"
                                                                                    name="quantity_received"
                                                                                    required
                                                                                    isInvalid={!parseInt(quantity_received) > 0 || quantity_received > quantity_sent}
                                                                                    value={quantity_received}
                                                                                    onChange={(e) => onChangeRemainingQuantity(e, sp_id)}
                                                                                />
                                                                                <Form.Control.Feedback type="invalid">
                                                                                    Kiểm tra lại số lượng!
                                                                                  </Form.Control.Feedback>
                                                                                </Form.Group>
                                                                                </td>
                                                                            ): ""
                                                                        }

                                                                            {
                                                                                currentShipment.is_delivered_vn?(
                                                                                    <td>
                                                                                        <Link to ={{
                                                                                            pathname:"/shippedproduct/" + sp_id + '/view',
                                                                                            state:{
                                                                                                currentShippedProductId:sp_id,
                                                                                                currentShipmentId: initialShipmentId,
                                                                                                isDeliveredVN: currentShipment.is_delivered_vn
                                                                                            }
                                                                                        }}>
                                                                                            <Button variant="outline-success" className="me-2"><FaImages title="Thêm Hình"/></Button>
                                                                                        </Link>
                                                                                    </td>
                                                                                ):(

                                                                                    <td><Button variant="outline-warning" className="me-2" onClick={(e) => saveQuantityFields(e, sp_id)}><FaFloppyDisk title="Cập Nhật"/></Button>
                                                                                        <Link to ={{
                                                                                            pathname:"/shippedproduct/" + sp_id + '/view',
                                                                                            state:{
                                                                                                currentShippedProductId:sp_id,
                                                                                                currentShipmentId: initialShipmentId,
                                                                                                isDeliveredVN: currentShipment.is_delivered_vn
                                                                                            }
                                                                                        }}>
                                                                                            <Button variant="outline-success" className="me-2"><FaImages title="Thêm Hình"/></Button>
                                                                                        </Link>
                                                                                        <ConfirmPopup/>
                                                                                        {
                                                                                            props.role!=='staff'?<Button variant="outline-danger" className="me-2" onClick={(e) => deleteShippedOrderedProduct(e, sp_id)}><FaTrashCan title="Xóa"/></Button>:
                                                                                            ""
                                                                                        }

                                                                                    </td>


                                                                                )
                                                                            }
                                                                    </tr>
                                                                </tbody>
                                                                )

                                                        }):(
                                                            <tr>
                                                                <Alert variant="info">Chưa có sản phẩm. Hãy thêm sản phẩm vào đơn hàng vận chuyển</Alert>
                                                            </tr>


                                                        )
                                                    }

                                                </Table>
                                                </Accordion>
                                                )

                                        })
                                    }
                                    {
                                        currentShipment.is_delivered_vn?(""):(
                                            <Accordion>
                                                    <Accordion.Item eventKey="0" >
                                                        <Accordion.Header>Chọn Đơn Hàng Để Vận Chuyển</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form noValidate validated={validated}>
                                                                <Row>
                                                                    <Form.Group as={Col} controlId="orderValidation">
                                                                        <Form.Label>Sản Phẩm</Form.Label>
                                                                        <Form.Select
                                                                            required
                                                                            value={selectedOrder}
                                                                            isInvalid={!selectedOrder}
                                                                            onChange={onChangeSelectedOrder}
                                                                            >
                                                                                <option></option>
                                                                                {
                                                                                    allIncompleteOrder.map((order) =>{
                                                                                        return(
                                                                                            <option value={order.order_id} key={order.order_id}
                                                                                                    data-key={order.product_id}>{order.name  + "-" + order.vendor_name}</option>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }

                                                                        </Form.Select>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            Chọn đơn hàng
                                                                          </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} controlId="priceValidation">
                                                                        <Form.Label>Số Lượng Đặt</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            required
                                                                            disabled
                                                                            value={selectedOrderQuantity}
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} controlId="quantityValidation">
                                                                        <Form.Label>Số Lượng Đã Gửi (Nếu Có)</Form.Label>
                                                                         <Form.Control
                                                                            type="number"
                                                                            required
                                                                            disabled
                                                                            value={selectedOrderQuantityShipped}
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group as={Col}>
                                                                        <br/>
                                                                        <Button variant="outline-success" onClick={handleSubmit}><FaPlus size="1.5em"/>&nbsp;Thêm</Button>
                                                                    </Form.Group>
                                                                </Row>

                                                            </Form>

                                                        </Accordion.Body>

                                                    </Accordion.Item>
                                                </Accordion>
                                        )
                                    }

                                </Tab.Content>
                            </Tab>
                            <Tab eventKey="1" title="Thanh Toán" className="mb-3">
                                <Tab.Content>
                                    {
                                        props.role === 'admin'?(
                                            <Link to={{
                                                pathname: "/payments/create",
                                                state:{
                                                    currentShipment: currentShipment,
                                                    isNew: true
                                                }}}>
                                                <Button variant="success" className="mb-3">
                                                    <FaDollarSign />&nbsp;Thanh Toán
                                                </Button>
                                            </Link>
                                        ):""
                                    }
                                    <PaymentListTemplate
                                        token={props.token}
                                        role={props.role}
                                        payments={shipmentPayments}
                                        currentShipment={currentShipment}
                                        paid={currentShipment.total_payment}
                                        remaining={currentShipment.remaining_payment_amount}
                                        currentOrder={null}
                                        fromShipment={true}
                                        fromOrder={false}
                                        getCurrentPageInfo={getShipmentPageInfo}
                                    />
                                </Tab.Content>
                            </Tab>
                        </Tabs>

                    </div>

                )
            }
        </Container>
    )

}

export default ViewShipment;