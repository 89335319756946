import React, {useEffect, useState, useMemo} from "react";
import orderDataService from '../services/orders';
import productDataService from "../services/products";
import vendorDataService from "../services/vendors";
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DataPagination from "./pagination";
import ProductListTemplate from "./product-list-template";
import OrderListTemplate from "./order-list-template";
import {FaPencil} from "react-icons/fa6";
import {Tag} from "primereact/tag";
import utils from "../services/utils";
// import { ToastContainer, toast } from 'react-toastify';


const ViewVendor = props => {
    let initialVendorId = "";
    let initialEventKey = 0;
    const [currentProductPage, setCurrentProductPage] = useState(1);
    const [productRecordCount, setProductRecordCount] = useState(0);
    const [currentOrderPage, setCurrentOrderPage] = useState(1);
    const [orderRecordCount, setOrderRecordCount] = useState(0);
    const [incompleteOrders, setIncompleteOrders] = useState([]);
    const [completeOrders, setCompleteOrders] = useState([]);
    const [currentIncompletePage, setCurrentIncompletePage] = useState(1);
    const [incompleteOrdersCount, setIncompleteOrdersCount] = useState(0);
    const [currentCompletePage, setCurrentCompletePage] = useState(1);
    const [completeOrdersCount, setCompleteOrdersCount] = useState(0);
    // const toastCompleteNotification = () => toast("Chuyển đơn hàng sang \"Đã Hoàn Thành\" thành công!", {autoClose: 1000});
    // const toastIncompleteNotification = () => toast("Chuyển đơn hàng sang \"Chưa Hoàn Thành\" thành công!", {autoClose: 1000});
    // const toastDeleteNotification = () => toast("Xóa đơn hàng thành công!", {autoClose: 1000});
    const pageSize = 10;

    if(props.location.state && props.location.state.currentVendor){
        initialVendorId = props.location.state.currentVendor.vendor_id;
        if(props.location.state.eventKey){
            initialEventKey = props.location.state.eventKey;
        }
    }
    else{
        initialVendorId = props.location.pathname.replace('/vendor/', '').replace('/view', '');
    }
    const [vendor, setVendor] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);

    useEffect(() =>{
        retrieveVendor(initialVendorId);
        retrieveProductsPagination(currentProductPage, initialVendorId);
        // retrieveOrdersPagination(currentOrderPage, initialVendorId);
        retrieveIncompleteOrdersPagination(currentOrderPage);
        retrieveCompleteOrdersPagination(currentOrderPage);
        // retrieveProducts();
        // test();
    }, [props.token, currentProductPage, currentOrderPage]);

    const getCurrentPageInfo = () => {
        retrieveVendor(initialVendorId);
        retrieveProductsPagination(currentProductPage, initialVendorId);
        retrieveOrdersPagination(currentOrderPage, initialVendorId);
    }

    const retrieveVendor = (vendor_id) =>{
        vendorDataService.getOne(vendor_id,props.token)
        .then(response => {
            setVendor(response.data);
        })
        .catch(e=>{
            console.log(e);
        });
    }

    const retrieveIncompleteOrdersPagination = (currentPage) =>{
        orderDataService.getAllIncompleteOrCompleteOrderVendorPagination(currentPage,props.token, initialVendorId)
        .then(response => {
            setIncompleteOrders(response.data.results);
            setIncompleteOrdersCount(response.data.count);
        })
        .catch(e=>{
            console.log(e);
        });
    }

    const retrieveCompleteOrdersPagination = (currentPage) =>{
        orderDataService.getAllIncompleteOrCompleteOrderVendorPagination(currentPage,props.token, initialVendorId,true)
        .then(response => {
            setCompleteOrders(response.data.results);
            setCompleteOrdersCount(response.data.count);
        })
        .catch(e=>{
            console.log(e);
        });

    }

    const handleProductPagination = (currentPage) => {
        setCurrentProductPage(currentPage);
    }

    const handleOrderPagination = (currentPage) => {
        setCurrentOrderPage(currentPage);
    }

    const handleIncompletePagination = (currentPage) => {
        setCurrentIncompletePage(currentPage);
    }

    const handleCompletePagination = (currentPage) => {
        setCurrentCompletePage(currentPage);
    }

    const retrieveProductsPagination = (currentPage, vendor_id) =>{
        productDataService.getDataPagination(currentPage,props.token, vendor_id)
        .then(response => {
            handleFilteredProducts(vendor_id, response.data.results);
            // setProducts(response.data.results);
            setProductRecordCount(response.data.count);
        })
        .catch(e=>{
            console.log(e);
        });
    }

    const retrieveOrdersPagination = (currentPage, vendor_id) =>{
        orderDataService.getDataPagination(currentPage,props.token, vendor_id)
        .then(response => {
            console.log(response.data.results);
            handleFilteredOrders(vendor_id, response.data.results);
            // setProducts(response.data.results);
            setOrderRecordCount(response.data.count);;
        })
        .catch(e=>{
            console.log(e);
        });
    }


    // const retrieveProducts = () =>{
    //     productDataService.getAll(props.token)
    //     .then(response => {
    //         handleFilteredProducts(initialVendorId, response.data);
    //     })
    //     .catch(e=>{
    //         console.log(e);
    //     });
    // }
    // const deleteOrder = (orderId) =>{
    //     orderDataService.delete(orderId, props.token)
    //     .then(response => {
    //         // retrieveOrdersPagination(currentProductPage);
    //         toastDeleteNotification()
    //         retrieveIncompleteOrdersPagination(currentOrderPage);
    //         retrieveCompleteOrdersPagination(currentOrderPage);
    //     })
    //     .catch(e =>{
    //         console.log(e);
    //     });
    // }

    // const completeOrder = (orderId) => {
    //     orderDataService.complete(orderId, props.token)
    //     .then(response => {
    //         // retrieveOrdersPagination(currentProductPage);
    //         toastCompleteNotification();
    //         retrieveIncompleteOrdersPagination(currentOrderPage);
    //         retrieveCompleteOrdersPagination(currentOrderPage);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }

    // const completeOrder = (orderId, is_complete) => {
    //     orderDataService.complete(orderId, props.token)
    //     .then(response => {
    //         if(is_complete)
    //             toastIncompleteNotification();
    //         else
    //             toastCompleteNotification()
    //         retrieveIncompleteOrdersPagination(currentIncompletePage);
    //         retrieveCompleteOrdersPagination(currentOrderPage);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }


    const handleFilteredProducts = (vendor_id, products) => {
        const filtered = products.filter(product => product.vendor_id === vendor_id);
        setFilteredProducts(filtered);
    }

    const handleFilteredOrders = (vendor_id, orders) => {
        const filtered = orders.filter(order => order.vendor_id === vendor_id);
        setFilteredOrders(filtered);
    }

    // const deleteProduct = (product) =>{
    //     productDataService.delete(product, props.token)
    //     .then(response => {
    //         retrieveProductsPagination();
    //     })
    //     .catch(e =>{
    //         console.log(e);
    //     });
    // }

    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>

                        <h2>Chi Tiết Nhà Cung Cấp</h2>
                        <Card>
                            <Card.Body className="mb-3">
                                <Container fluid>
                                    <Row>
                                        <Col><Card.Title>{vendor.name}</Card.Title>
                                            {vendor.is_active?<Tag severity="success" icon="pi pi-check" value="Đang Cộng Tác"></Tag>:
                                                <Tag severity="danger" icon="pi pi-times" value="Ngưng Cộng Tác"></Tag>}</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <Card.Text><b>Số Điện Thoại: </b>{vendor.phone_no}</Card.Text>
                                        </Col>
                                        <Col>
                                        <Card.Text><b>Email: </b>{vendor.email}</Card.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <Card.Text><b>Facebook: </b>{vendor.facebook_handler}</Card.Text>
                                        </Col>
                                        <Col>
                                        <Card.Text><b>Tài Khoản: </b>{vendor.username}</Card.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <Card.Text><b>Telegram Chat Id: </b>{vendor.telegram_chat_id}</Card.Text>
                                        </Col>
                                        <Col>
                                        <Card.Text><b>Ghi Chú: </b>{vendor.notes}</Card.Text>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                        <div>
                            <Link to ={{
                                    pathname:"/vendors/",
                                }}>
                            <Button variant="primary" className="me-2"><b>&#8592;</b></Button>
                            </Link>
                            <Link to ={{
                                pathname:"/vendors/" + vendor.vendor_id,
                                state:{
                                    currentVendor:vendor
                                }
                            }}>
                            <Button variant="outline-primary" className="me-2"><FaPencil title="Chỉnh Sửa"/>&nbsp;Chỉnh Sửa</Button>
                            </Link>
                        </div>
                        <Tabs defaultActiveKey={initialEventKey}>
                            <Tab eventKey="0" title="Đơn Hàng Chưa Hoàn Thành">
                                <Tab.Content className='d-flex flex-column flex-grow-1'>
                                     <Link to={{
                                            pathname:"/orders/create",
                                            state:{
                                                currentVendor: vendor,
                                                fromVendor:true,
                                                eventKey: 0
                                            }
                                        }}>
                                        <Button variant="success" className="mb-3">
                                        Thêm Đơn Hàng
                                        </Button>
                                    </Link>
                                        <DataPagination
                                        pageSize={pageSize}
                                        recordCount={incompleteOrdersCount}
                                        handlePagination={handleIncompletePagination}
                                        currentPage={currentIncompletePage}
                                        />
                                        <OrderListTemplate
                                            orders={incompleteOrders}
                                            token={props.token}
                                            getCurrentPageInfo={getCurrentPageInfo}
                                            // completeOrder={completeOrder}
                                            // deleteOrder={deleteOrder}
                                            role='admin'
                                            completeTab={false}
                                        />

                                    </Tab.Content>

                                </Tab>
                                <Tab eventKey="1" title="Đơn Hàng Đã Hoàn Thành">
                                    <Tab.Content className='d-flex flex-column flex-grow-1'>
                                        <Link to={{
                                            pathname:"/orders/create",
                                            state:{
                                                currentVendor: vendor,
                                                fromVendor:true,
                                                eventKey: 1
                                            }
                                        }}>
                                        <Button variant="success" className="mb-3">
                                        Thêm Đơn Hàng
                                        </Button>
                                        </Link>
                                        <DataPagination
                                            pageSize={pageSize}
                                            recordCount={completeOrdersCount}
                                            handlePagination={handleCompletePagination}
                                            currentPage={currentCompletePage}
                                        />
                                        <OrderListTemplate
                                            orders={completeOrders}
                                            // completeOrder={completeOrder}
                                            // deleteOrder={deleteOrder}
                                            token={props.token}
                                            getCurrentPageInfo={getCurrentPageInfo}
                                            role='admin'
                                            completeTab={true}
                                        />

                                    </Tab.Content>
                                </Tab >
                                    {/*<DataPagination*/}
                                    {/*pageSize={pageSize}*/}
                                    {/*recordCount={orderRecordCount}*/}
                                    {/*handlePagination={handleOrderPagination}*/}
                                    {/*currentPage={currentOrderPage}*/}
                                    {/*/>*/}
                                    {/*<OrderListTemplate*/}
                                    {/*    orders={filteredOrders}*/}
                                    {/*    deleteOrder={deleteOrder}*/}
                                    {/*    completeOrder={completeOrder}*/}
                                    {/*    completeTab={true}*/}
                                    {/*    role={"admin"}*/}
                                    {/*/>*/}
                                    {/*<Table bordered hover>*/}
                                    {/*    <thead>*/}
                                    {/*        <tr>*/}
                                    {/*            <th>Tên Đơn Hàng</th>*/}
                                    {/*            <th>Nhà Cung Cấp</th>*/}
                                    {/*            <th>Ngày Xác Nhận</th>*/}
                                    {/*            <th>Ngày Dự Kiến Hoàn Thành</th>*/}
                                    {/*        </tr>*/}

                                    {/*    </thead>*/}
                                    {/*        {*/}
                                    {/*            filteredOrders.map((order) =>{*/}
                                    {/*                return(*/}
                                    {/*                    <tbody>*/}
                                    {/*                        <tr key={order.order_id} className={`${order.is_complete?"text-decoration-line-through":""}`}>*/}
                                    {/*                            <td><Link to ={{*/}
                                    {/*                                    pathname:"/order/"+ order.order_id + "/view",*/}
                                    {/*                                    state:{*/}
                                    {/*                                        currentOrder:order,*/}
                                    {/*                                        fromVendor:true,*/}
                                    {/*                                        currentVendor:vendor,*/}
                                    {/*                                    }*/}
                                    {/*                                }}>*/}
                                    {/*                                {order.name}*/}
                                    {/*                                </Link></td>*/}
                                    {/*                            <td>{order.vendor_name}</td>*/}
                                    {/*                            <td>{order.confirmed_date?moment(order.confirmed_date).format("LLL"):"Chưa Xác Nhận"}</td>*/}
                                    {/*                            <td >{order.estimated_date?moment(order.estimated_date).format("LL"):"Chưa Xác Định"}</td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <Link to ={{*/}
                                    {/*                                    pathname:"/orders/" + order.order_id,*/}
                                    {/*                                    state:{*/}
                                    {/*                                        currentOrder:order,*/}
                                    {/*                                        fromVendor:true,*/}
                                    {/*                                        currentVendor:vendor,*/}
                                    {/*                                    }*/}
                                    {/*                                }}>*/}
                                    {/*                                <Button variant="outline-info" className="me-2">Chỉnh Sửa</Button>*/}
                                    {/*                                </Link>*/}
                                    {/*                                <Button variant="outline-success" onClick={() => completeOrder(order.order_id)}>{order.is_complete?"Mark As Incomplete":"Mark As Completed"}</Button>*/}
                                    {/*                                <Button variant="outline-danger" onClick={() => deleteOrder(order.order_id)}>Xóa</Button>*/}
                                    {/*                            </td>*/}
                                    {/*                        </tr>*/}

                                    {/*                    </tbody>*/}

                                    {/*                )*/}
                                    {/*            }*/}

                                    {/*            )*/}
                                    {/*        }*/}
                                    {/*</Table>*/}
                                <Tab eventKey="3" title="Sản Phẩm">
                                <Tab.Content className='d-flex flex-column flex-grow-1'>
                                    <Link to={{
                                        pathname: "/products/create",
                                        state:{
                                            currentVendor:vendor,
                                            fromVendor: true,
                                            eventKey: 3
                                        }}}>
                                        <Button variant="success" className="mb-3">
                                        Thêm Sản Phẩm
                                        </Button>
                                    </Link>
                                    <DataPagination
                                    pageSize={pageSize}
                                    recordCount={productRecordCount}
                                    handlePagination={handleProductPagination}
                                    currentPage={currentProductPage}
                                    />
                                    <ProductListTemplate
                                        token={props.token}
                                        products={filteredProducts}
                                        getCurrentPageInfo={getCurrentPageInfo}
                                    />
                                    {/*<Table bordered hover>*/}
                                    {/*    <thead>*/}
                                    {/*        <tr>*/}
                                    {/*            <th>Tên Sản Phẩm</th>*/}
                                    {/*            <th>Nhà Cung Cấp</th>*/}
                                    {/*            <th>Tổng Số Từ Đơn Hàng</th>*/}
                                    {/*            <th>Loại Sản Phẩm</th>*/}
                                    {/*        </tr>*/}

                                    {/*    </thead>*/}
                                    {/*        {*/}
                                    {/*            filteredProducts.map((product) =>{*/}
                                    {/*                return(*/}
                                    {/*                    <tbody>*/}
                                    {/*                        <tr key={product.product_id}>*/}
                                    {/*                            <td><Link to ={{*/}
                                    {/*                                pathname:"/product/"+ product.product_id + "/view",*/}
                                    {/*                                state:{*/}
                                    {/*                                    currentProduct:product*/}
                                    {/*                                }*/}
                                    {/*                            }}>*/}
                                    {/*                            {product.name}*/}
                                    {/*                            </Link></td>*/}
                                    {/*                            <td>{product.vendor_name}</td>*/}
                                    {/*                            <td>{product.total_quantity_all_orders}</td>*/}
                                    {/*                            <td>{product.product_type}</td>*/}
                                    {/*                            <td>*/}
                                    {/*                                <Link to ={{*/}
                                    {/*                                    pathname:"/products/" + product.product_id,*/}
                                    {/*                                    state:{*/}
                                    {/*                                        currentProduct:product,*/}
                                    {/*                                        fromVendor:true,*/}
                                    {/*                                        currentVendor:vendor*/}
                                    {/*                                    }*/}
                                    {/*                                }}>*/}
                                    {/*                                <Button variant="outline-info" className="me-2">Chỉnh Sửa</Button>*/}
                                    {/*                                </Link>*/}
                                    {/*                                <Button variant="outline-danger" onClick={() => deleteProduct(product.product_id)}>Xóa</Button>*/}
                                    {/*                            </td>*/}
                                    {/*                        </tr>*/}

                                    {/*                    </tbody>*/}

                                    {/*                )*/}
                                    {/*            }*/}

                                    {/*            )*/}
                                    {/*        }*/}
                                    {/*    </Table>*/}

                                </Tab.Content>
                            </Tab>

                        </Tabs>

                    </div>
                    
                )

                
            }
        </Container>
    )

}

export default ViewVendor;