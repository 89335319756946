import React, {useEffect, useRef, useState} from "react";
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
// import { Button } from 'primereact/button';
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import orderDataService from "../services/orders";
import orderedProductDataService from "../services/orderedproducts";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Accordion from 'react-bootstrap/Accordion';
import shippedProductDataService from "../services/shippedproducts";
import shipmentDataService from "../services/shipments";
import { Toast } from 'primereact/toast';
import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup'; // To use confirmPopup method
import "primereact/resources/themes/lara-light-blue/theme.css";
import {
    FaFloppyDisk,
    FaPlus,
    FaImages,
    FaXmark,
    FaArrowLeft,
    FaTrash,
    FaPencil,
    FaTruckFast,
    FaPlane,
    FaDollarSign, FaTrashCan
} from "react-icons/fa6";
import {InputGroup, Tab, Tabs} from "react-bootstrap";
import {FaShippingFast} from "react-icons/fa";
import PaymentListTemplate from "./payment-list-template";
import paymentDataService from "../services/payments";
import CommonImagePreviewHandler from "./common-image-preview-handler";
import { Tag } from 'primereact/tag';
import utils from "../services/utils";
import usShipmentDataService from "../services/usshipments";
import VNCurrencyInput from "./vn-currency-input-mask";
import CommonGalleryDisplayHandler from "./common-gallery-preview-handler";


const ViewUShipment = props => {
    let newShipment = false;
    let initialUSShipmentId = "";
    let initialNote = "";
    // let initialOrderId = "";
    let initialShippingCost = 0;
    let isDelivered = false;
    let isEditLabel = false;
    let initialLabel = "";
    let initialVendorId = "";
    let initialActiveTab = 0;


    // if(props.location.state && props.location.state.currentOrder){
    //     initialOrderId = props.location.state.currentOrder.order_id;
    // }
    // else{
    //     initialShipmentId = props.location.pathname.replace('/shipment/', '').replace('/view', '');
    // }
    if(props.location.state && props.location.state.currentUSShipmentId){
        initialUSShipmentId = props.location.state.currentUSShipmentId;
    }
    else{
        initialUSShipmentId = props.location.pathname.replace('/usshipment/', '').replace('/view', '');
    }

    if(props.location.state && props.location.state.currentUSShipment){
        initialUSShipmentId = props.location.state.currentUSShipment.shipment_us_id;
        initialShippingCost = utils.formatCurrency(props.location.state.currentUSShipment.shipping_cost);
        initialLabel = props.location.state.currentUSShipment.label;
        initialNote = props.location.state.currentUSShipment.notes;
    }
    else{
        initialUSShipmentId = props.location.pathname.replace('/usshipment/', '').replace('/view', '');
    }

    if(props.location.state && props.location.state.eventKey){
        initialActiveTab = props.location.state.eventKey;
    }

    const [currentUSShipment, setCurrentUSShipment] = useState("");
    const [currentShippedProducts, setCurrentShippedProducts] = useState([]);
    const [selectedVNShipment, setSelectedVNShipment] = useState("");
    const [allVNDeliveredShipments, setAllVNDeliveredShipments] = useState([]);
    const [note, setNote] = useState(initialNote);
    const [validated, setValidated] = useState(false);
    const [shippingCost, setShippingCost] = useState(initialShippingCost);
    const [selectedShipmentQuantity, setSelectedShipmentQuantity] = useState(0);
    // const [selectedOrderQuantityShipped, setSelectedOrderQuantityShipped] = useState(0);
    // for shipments in the US Shipment
    const [selectedShipments, setSelectedShipments] = useState([]);
    const [editLabel, setEditLabel] = useState(isEditLabel);
    const [label, setLabel] = useState(initialLabel);
    const [shipmentUSStatus, setShipmentUSStatus] = useState("Mới");
    const [shipmentUSPayments, setShipmentUSPayments] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState("");
    const [trackingNumber, setTrackingNumber] = useState("");
    const toast = useRef(null);
    const [orders, setOrders] = useState([]);



    useEffect(() =>{
        // console.log(props.location.pathname.replace('/shipment/', '').replace('/view', ''));
        retrieveUSShipment(initialUSShipmentId);
        retrieveAllToUSShipments(initialUSShipmentId);
        retrieveShipmentPayments(initialUSShipmentId);
        // getShipmentPageInfo();

    }, [props.token]);

    const getShipmentPageInfo = () =>{
        retrieveUSShipment(initialUSShipmentId);
        retrieveAllToUSShipments(initialUSShipmentId);
        retrieveShipmentPayments(initialUSShipmentId);
    }

    const getPaymentInfo = () => {
        retrieveShipmentPayments(initialUSShipmentId);
    }

    const retrieveAllToUSShipments = (usShipmentId) =>{
        shipmentDataService.getAllShipmentByUSShipmentId(usShipmentId, props.token)
            .then( r=> {
                const vnShipments = r.data;
                setSelectedShipments(vnShipments);
                const currentShipments = vnShipments.map((vnShipment)=> vnShipment.shipment_id);
                var currentShipmentOrdersSet = new Set(currentShipments);
                let filter_shipments = "";
                // filter out current orders in the shipment
                currentShipmentOrdersSet.forEach(shipmentId =>{
                    filter_shipments = filter_shipments + shipmentId + "|"
                })
                // console.log('filter_shipments:', filter_shipments);
                if (filter_shipments.length > 0){
                    shippedProductDataService.getAllShippedProdForShipments(props.token, filter_shipments)
                    .then(r=>{
                        let shippedProducts = r.data;
                        const orderSet = new Set(shippedProducts.map(shipProduct => shipProduct.order_id));
                        setOrders(Array.from(orderSet));
                        // console.log('shippedProducts before:', shippedProducts);
                        shippedProducts.forEach((shippedProduct) =>{
                            if(shippedProduct.quantity_received_us === null){
                                shippedProduct.quantity_received_us = shippedProduct.quantity_received;
                            }
                        })
                        // console.log('shippedProducts after:', shippedProducts);
                        setCurrentShippedProducts(shippedProducts);
                    })
                    .catch(e => console.log(e));
                }
                shipmentDataService.getAllShipmentByStatus(props.token, 'vn')
                    .then(
                        r =>{
                            const allVNShipments = r.data;
                            // console.log('allVNShipments: ', allVNShipments);
                            // console.log('vnShipments', vnShipments);
                            const filtered_shipments = filterOutSelectedShipmentsFromAvailableShipments(allVNShipments);
                            // console.log('filter_shipments:', filtered_shipments);
                            if(filtered_shipments)
                                setAllVNDeliveredShipments(filtered_shipments);
                        }
                    )
                    .catch(e => console.log(e));

            })
            .catch(e => console.log(e));
    }

    const filterOutSelectedShipmentsFromAvailableShipments = (shipments) => {
        if (shipments.length > 0)
            // shipments.forEach((shipment)=>{
            //     filtered_shipments = filterOutSelectedShipment(shipment.shipment_id, filtered_shipments);
            // })
            return shipments.filter(shipment => shipment.shipment_us_id === null);
        // setAllVNDeliveredShipments(filtered_shipments);
    }

    // //to remove the selected order from add order list or the order in the shipment
    // const filterOutSelectedShipment = (shipmentId, shipmentOptions) =>{
    //     if(shipmentOptions.length>0){
    //         return shipmentOptions.filter(shipmentOption => shipmentOption.shipment_id !== shipmentId);
    //     }
    //
    // }

    const retrieveShipmentPayments = (shipmentId) =>{
        paymentDataService.getAllPaymentsForUSShipment(props.token, shipmentId)
            .then( r=>{
                const payments = r.data;
                setShipmentUSPayments(payments);
            })
            .catch(e => console.log(e))
    }



    const handleFilterShippedProduct = (shipmentId, shippedOrderedProducts) =>{
        return shippedOrderedProducts.filter(shippedOrderedProduct => shippedOrderedProduct.shipment_id === shipmentId)
    }




    const retrieveSelectedShipment = (shipmentId) =>{
        shipmentDataService.getOne(shipmentId, props.token)
            .then(response => {
                const shipment = response.data;
                // console.log('selected shipment: ', shipment)
                setSelectedShipmentQuantity(shipment.total_shipped_quantity);
                // setSelectedOrderQuantityShipped(shipment.order_true_shipped_quantity);
            })
    }

    const retrieveUSShipment = (shipmentId) =>{
        usShipmentDataService.getOne(shipmentId, props.token)
            .then(response => {
                const shipmentData = response.data;
                setCurrentUSShipment(shipmentData);
                if(shipmentData.shipping_cost > 0){
                    setShippingCost(utils.formatCurrency(shipmentData.shipping_cost));
                }
                setNote(shipmentData.notes);
                setLabel(shipmentData.label);
                setTrackingNumber(shipmentData.tracking_no);
                if(shipmentData.is_delivered){
                    setShipmentUSStatus("Đã Nhận")
                }
                if(shipmentData.payment_status === 'overpaid')
                    setPaymentStatus("Thanh Toán Lố")
                else if(shipmentData.payment_status === 'partiallypaid')
                    setPaymentStatus("Chưa Thanh Toán Đủ")
                else{
                    setPaymentStatus("Đã Thanh Toán");
                }
            })
            .catch(e =>{
                console.log(e);
            });
    }

    const onChangeShippingCost = e =>{
        const cost = e.target.value;
        // console.log('cost', cost.replace('.', ''));
        // console.log(parseInt(cost.replace('.', '')));
        setShippingCost(cost);
    }

    const onChangeTrackingNumber = e =>{
        const trackingNo = e.target.value;
        setTrackingNumber(trackingNo);
    }

    const onChangeSelectedShipment = e => {
        const selectedShipment = e.target.value;
        console.log(selectedShipment);
        setSelectedVNShipment(selectedShipment);
        if (selectedShipment!== '')
            retrieveSelectedShipment(selectedShipment);
    }

    const onChangeRemainingQuantity = (e, sp_id) => {
        const {name, value} = e.target;
        const editData = currentShippedProducts.map((item) =>
            item.sp_id === sp_id && name?{...item, [name]: value}:item
        )
        setCurrentShippedProducts(editData);
    }

    const onChangeNote = e => {
        const note = e.target.value;
        setNote(note);
    }

    const onChangeLabel = e => {
        const label = e.target.value;
        setLabel(label);
    }

    const updateEditLabel = () => {
        setEditLabel(true);
    }


    const handleShippingUS = (event) =>{
        confirmPopup({
            target: event.currentTarget,
            message: 'Bạn sẽ không thể thay đổi tình trạng vận chuyển sau khi nhấn "Xác Nhận". Bạn chắc chứ?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Xác Nhận",
            rejectLabel: "Quay Lại",
            accept: () => onClickShippingUS()
        })
    }

    const onClickShippingUS = () => {
        const shippingCostInt = utils.parseVNCurrencyInput(shippingCost);
        const shippedProductQuantityArray = currentShippedProducts.map((currentShipmentOrderProduct) => utils.getInt(currentShipmentOrderProduct.quantity_received_us));
        const shippedProductQuantitySum = shippedProductQuantityArray.reduce((partialSum, a) => partialSum+ a, 0);
        // console.log("shippedProductQuantitySum", shippedProductQuantitySum);
        // console.log("shippingCost:", shippingCost);
        const shipping_cost_per_item = shippedProductQuantitySum>0?utils.getInt(shippingCostInt/shippedProductQuantitySum):0;
        // console.log("shippedProductQuantitySum", shippedProductQuantitySum);
        // console.log("shippingCost:", shippingCost);
        currentShippedProducts.forEach(currentShippedProduct => {
            const shipProductData = {
                quantity_sent: utils.getInt(currentShippedProduct.quantity_sent),
                quantity_received: utils.getInt(currentShippedProduct.quantity_received),
                quantity_received_us: utils.getInt(currentShippedProduct.quantity_received_us),
                shipping_cost_per_item_us: shipping_cost_per_item,
                status: 'in_us',
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
                shipment_id: currentShippedProduct.shipment_id,
                op_id: currentShippedProduct.op_id
            }
            shippedProductDataService.update(currentShippedProduct.sp_id, shipProductData, props.token)
                .then(r=>{

                })
                .catch(e => console.log(e));
        })

        selectedShipments.forEach((selectedShipment) => {
            shipmentDataService.deliver(selectedShipment.shipment_id, "us", props.token)
                .then(r=>{

                }).catch(e => console.log(e));
        })
        usShipmentDataService.deliver(currentUSShipment.shipment_us_id, props.token)
            .then(r=>{
                orders.forEach((order)=>{
                    orderDataService.getOne(order, props.token)
                        .then(r=>{
                            const orderData = r.data;
                            if(orderData.order_quantity <= orderData.order_true_shipped_quantity){
                                orderDataService.complete(orderData.order_id, props.token)
                                    .then()
                                    .catch(e=>console.log(e));
                            }
                        })
                    .catch(e => console.log(e));
                })
                retrieveUSShipment(initialUSShipmentId);
            })
            .catch(e => console.log(e));
    }

    const saveShipment = () => {
        // console.log('shippingCost', shippingCost)
        // console.log('utils.getInt(shippingCost)', shippingCost.replace(/\D/g, ""));
        const shippingCostInt = utils.parseVNCurrencyInput(shippingCost);
        if (currentUSShipment.shipping_cost !== shippingCostInt || currentUSShipment.notes !== note || currentUSShipment.label !==label){
            var data = {
                label: label,
                shipping_cost: shippingCostInt,
                tracking_no: trackingNumber,
                notes:note,
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
            }
            usShipmentDataService.update(initialUSShipmentId, data, props.token)
                .then(r => {
                    setEditLabel(false);
                    retrieveUSShipment(initialUSShipmentId);
                    // getShipmentPageInfo();
                    retrieveAllToUSShipments(initialUSShipmentId);
                })
                .catch(e =>{
                    console.log(e);
                })
        }
        const shippedProductQuantityArray = currentShippedProducts.map((currentShipmentOrderProduct) => utils.getInt(currentShipmentOrderProduct.quantity_received_us));
        const shippedProductQuantitySum = shippedProductQuantityArray.reduce((partialSum, a) => partialSum+ a, 0);
        // console.log("shippedProductQuantitySum", shippedProductQuantitySum);
        // console.log("shippingCost:", shippingCost);
        const shipping_cost_per_item = shippedProductQuantitySum>0?utils.getInt(shippingCostInt/shippedProductQuantitySum):0;
        currentShippedProducts.forEach((currentShipmentShippedProduct) => {
            const shippedProductData = {
                shipment_id: currentShipmentShippedProduct.shipment_id,
                op_id: currentShipmentShippedProduct.op_id,
                quantity_sent: utils.getInt(currentShipmentShippedProduct.quantity_sent),
                quantity_received: utils.getInt(currentShipmentShippedProduct.quantity_received),
                quantity_received_us: utils.getInt(currentShipmentShippedProduct.quantity_received_us),
                shipping_cost_per_item_us: shipping_cost_per_item,
                total_quantity: currentShipmentShippedProduct.total_quantity,
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
            }
            shippedProductDataService.update(currentShipmentShippedProduct.sp_id, shippedProductData, props.token)
                .then(er=>{
                })
                .catch(e => console.log(e));
        })
        toast.current.show({ severity: 'success', detail: "Cập nhật thông tin hợp lệ thành công!" });

    }

    const saveQuantityFields = (e, sp_id) =>{
        const editData = currentShippedProducts.filter((item) => item.sp_id === sp_id);
        const quantity_sent = editData[0].quantity_sent;
        const quantity_received = editData[0].quantity_received;
        const total_quantity = editData[0].total_quantity;
        const quantity_received_us = editData[0].quantity_received_us;
        const shippedProductUpdateData = {
            shipment_id: editData[0].shipment_id,
            op_id: editData[0].op_id,
            quantity_sent: parseInt(quantity_sent),
            quantity_received:parseInt(quantity_received),
            total_quantity: total_quantity,
            quantity_received_us: quantity_received_us,
            last_modified_by: localStorage.getItem('username'),
            last_modified_date: moment(),
        }
        shippedProductDataService.update(sp_id, shippedProductUpdateData, props.token)
            .then(r=>{

            })
            .catch(e=>{
                console.log(e);
            });
        if(quantity_received_us!==0 && quantity_received_us <= quantity_received){
            // toastUpdateNotification();
            toast.current.show({ severity: 'success', detail: "Cập nhật thông tin hợp lệ thành công!" });
            retrieveUSShipment(initialUSShipmentId);
            retrieveAllToUSShipments(initialUSShipmentId);
        }

    }

    const addSelectedShipmentToUSShipment = (shipmentId) => {
        // console.log('initialUSShipmentId: ', initialUSShipmentId);
        const shipmentData = {
            shipment_us_id: initialUSShipmentId,
            last_modified_by: localStorage.getItem('username'),
            last_modified_date: moment()
        }
        // console.log("shipmentId: ", shipmentId);
        // console.log('shipmentData', shipmentData);
        shipmentDataService.update(shipmentId, shipmentData, props.token)
            .then(r=>{
                setSelectedVNShipment("");
            })
            .catch(e => console.log(e));

        if(currentUSShipment.label !== label || currentUSShipment.shipping_cost !== shippingCost ||
            currentUSShipment.notes !== note){
            const shipmentUpdateData = {
                label: label,
                notes: note,
                shipping_cost: shippingCost,
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment()
            }
            usShipmentDataService.update(initialUSShipmentId, shipmentUpdateData, props.token)
                .then(r => {
                    // retrieveUSShipment(initialUSShipmentId)
                    // // getShipmentPageInfo();
                    // retrieveAllToUSShipments(initialUSShipmentId);
                })
                .catch(e =>{
                    console.log(e);
                })
        }
        retrieveUSShipment(initialUSShipmentId);
        // getShipmentPageInfo();
        retrieveAllToUSShipments(initialUSShipmentId);
    }

    const deleteShipmentFromShipmentUS = (event, shipment_id) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Bạn muốn bỏ đơn hàng này khỏi đơn vận chuyển?',
            acceptLabel:"Xác Nhận",
            rejectLabel: "Không",
            icon: 'pi pi-exclamation-triangle',
            accept: () => handleDeleteShipment(event, shipment_id),
        })
    }

    const handleDeleteShipment = (e, shipment_id) => {
        // console.log('shipment_id',shipment_id);
        let updatedShipmentData = {
            shipment_us_id: null,
            last_modified_by: localStorage.getItem('username'),
            last_modified_date: moment(),
        }
        shipmentDataService.update(shipment_id, updatedShipmentData, props.token)
            .then(r=>{
                shippedProductDataService.getAllShippedProdForShipments(props.token, shipment_id)
                    .then(r => {
                        const shippedProducts = r.data;
                        shippedProducts.forEach((shippedProduct) => {
                            let spData = {
                                quantity_received_us: null,
                                shipment_id: shippedProduct.shipment_id,
                                op_id: shippedProduct.op_id,
                                last_modified_by: localStorage.getItem('username'),
                                last_modified_date: moment(),
                            }
                            // console.log('spData:', spData);
                            shippedProductDataService.update(shippedProduct.sp_id, spData, props.token)
                                .then( r=>{
                                 }
                                )
                                .catch(e => console.log(e));
                        });
                        retrieveAllToUSShipments(initialUSShipmentId);
                        // toastDeleteNotification();
                    })
                    .catch(e => console.log(e));
            })
            .catch(e => console.log(e));

    }

    // const deleteShippedOrderedProduct = (event, sp_id) => {
    //     confirmPopup({
    //         target: event.currentTarget,
    //         message: 'Bạn muốn bỏ sản phẩm khỏi đơn vận chuyển?',
    //         icon: 'pi pi-exclamation-triangle',
    //         acceptLabel:"Xác Nhận",
    //         rejectLabel: "Không",
    //         accept: () => handleDeleteShippedOrderedProduct(event, sp_id),
    //     })
    // }
    // const handleDeleteShippedOrderedProduct = (e, sp_id) => {
    //     shippedProductDataService.delete(sp_id, props.token)
    //         .then( r => {
    //             // toastDeleteNotification();
    //             // retrieveUSShipment(initialShipmentId);
    //             retrieveAllToUSShipments(initialUSShipmentId);
    //         }
    //         )
    //         .catch(e => console.log(e));
    //
    // }


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        setValidated(true);
        console.log('selectedVNShipment in handle submit: ',selectedVNShipment);
        if(selectedVNShipment !== ''){
           addSelectedShipmentToUSShipment(selectedVNShipment);
        }
        retrieveUSShipment(initialUSShipmentId);
        retrieveAllToUSShipments(initialUSShipmentId);
    // getShipmentPageInfo();
  };


    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <Toast ref={toast} />
                        <h2>Chi Tiết Đơn Vận Chuyển Qua Mỹ</h2>
                        <Card>
                            <Card.Body>
                                <Container fluid>
                                    <Row>
                                        {!editLabel?<Col><Card.Title>
                                            {
                                                currentUSShipment.is_delivered?<Tag severity="success" value={shipmentUSStatus}></Tag>:<Tag severity="info" value="Mới"></Tag>

                                            }
                                            &nbsp;&nbsp;{label}&nbsp;<Button onClick={updateEditLabel}><FaPencil size="0.9em"/></Button></Card.Title></Col>:(
                                            <>
                                                <InputGroup>
                                                    <InputGroup.Text>Tên Đơn Vận Chuyển</InputGroup.Text>
                                                    <Form.Control
                                                            type='text'
                                                            placeholder="Nhập tên đơn vận chuyển"
                                                            value={label}
                                                            onChange={(e) =>onChangeLabel (e)}
                                                        />
                                                        <ConfirmPopup/>
                                                </InputGroup>
                                            </>
                                        )}
                                    </Row>
                                    <Row>
                                        <InputGroup>
                                            <InputGroup.Text className="mb-3">Test Phí Vận Chuyển</InputGroup.Text>
                                          {/*  <Form.Control*/}
                                          {/*      className="mb-3"*/}
                                          {/*      type="numeric"*/}
                                          {/*      value={shippingCost}*/}
                                          {/*      onChange={onChangeShippingCost}*/}
                                          {/*  // onMouseLeave={saveShippingCost}*/}
                                          {/*/>*/}
                                            <VNCurrencyInput value={shippingCost} onChange={onChangeShippingCost} type="text" className="border-solid border-1 p-2 mb-3"/>
                                        {/*    <InputGroup.Text className="mb-3">Phí Vận Chuyển</InputGroup.Text>*/}
                                        {/*    <Form.Control*/}
                                        {/*        className="mb-3"*/}
                                        {/*        type="numeric"*/}
                                        {/*        value={shippingCost}*/}
                                        {/*        onChange={onChangeShippingCost}*/}
                                        {/*    // onMouseLeave={saveShippingCost}*/}
                                        {/*  />*/}
                                            {
                                            paymentStatus === "Thanh Toán Lố"?<Tag severity="danger" value={paymentStatus} icon="pi pi-times" className="mb-3"></Tag>:
                                                paymentStatus === "Chưa Thanh Toán Đủ"?<Tag severity="warning" value={paymentStatus} icon="pi pi-exclamation-triangle" className="mb-3"></Tag>:
                                                    <Tag severity="success" value={paymentStatus} className="mb-3"></Tag>
                                            }
                                        </InputGroup>
                                        <InputGroup>
                                            <InputGroup.Text className="mb-3">Tracking #.</InputGroup.Text>
                                            <Form.Control
                                                className="mb-3"
                                                type="text"
                                                value={trackingNumber}
                                                onChange={onChangeTrackingNumber}
                                            // onMouseLeave={saveShippingCost}
                                          />
                                        </InputGroup>
                                    </Row>
                                    <Row>
                                        <Col>
                                             <Form.Group as={Col}>
                                                <Form.Label>Ghi Chú</Form.Label>
                                                 <Form.Control
                                                    as="textarea"
                                                    row={3}
                                                    value={note}
                                                    onChange={onChangeNote}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ConfirmPopup />
                                            <Link to ={{
                                                pathname:"/usshipments/",
                                            }}>
                                        <Button variant="outline-primary" className="me-2"><FaArrowLeft title="Quay Lại" size="1.2em"/></Button>
                                        </Link>

                                                    <Button variant="outline-warning" onClick={saveShipment} className="me-2"><FaFloppyDisk size="1.2em"/>&nbsp;Cập Nhật</Button>

                                            {
                                                // props.role!=='vendor'?(currentUSShipment.is_delivered_us?"":(
                                                //
                                                //     currentUSShipment.is_delivered_vn?(props.role==='admin'?(<Button variant="outline-success" onClick={(e) => handleShippingUS(e)} className="me-2"><FaPlane size="1.2em"/>&nbsp;Đã Nhận (US)</Button>):""):
                                                //         <Button variant="outline-success" onClick={(e) => handleShippingVN(e)}><FaShippingFast size="1.2em" className="me-2"/>&nbsp;Đã Nhận (VN)</Button>
                                                // )):""
                                                !currentUSShipment.is_delivered?<Button variant="outline-success" onClick={(e) => handleShippingUS(e)}><FaPlane size="1.2em" className="me-2"/>&nbsp;Đã Nhận</Button>:""
                                            }

                                        </Col>

                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                        <Tabs defaultActiveKey={initialActiveTab} className="mb-3">
                            <Tab eventKey="0" title="Thông Tin Giao Hàng" className="mb-3">
                                <Tab.Content>
                                    {
                                        selectedShipments.length >0 ?
                                        selectedShipments.map((currentSelectedVNShipment) =>{
                                            return(
                                                <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0" >
                                                    <Accordion.Header>{currentSelectedVNShipment.label + " của " + currentSelectedVNShipment.vendor_name} &nbsp; &nbsp; {currentUSShipment.is_delivered?"":<Button id={currentSelectedVNShipment.shipment_id} variant="outline-danger" onClick={(e) =>deleteShipmentFromShipmentUS(e, currentSelectedVNShipment.shipment_id)}><FaTrashCan title="Xóa" size="1.2em"/></Button>}</Accordion.Header>
                                                </Accordion.Item>
                                                    <Table bordered hover>
                                                    <thead>
                                                    <tr>

                                                        <th>Sản Phẩm</th>
                                                        <th>Hình Lúc Gửi</th>
                                                        <th>Số Lượng Nhận Ở VN </th>
                                                        <th>Số Lượng Nhận Ở Mỹ</th>
                                                        <th></th>
                                                    </tr>
                                                    </thead>
                                                        <tbody>
                                                    {
                                                        // currentShippedProducts.length > 0?
                                                        handleFilterShippedProduct(currentSelectedVNShipment.shipment_id, currentShippedProducts).map(({sp_id,
                                                                                                                                                   product_name,
                                                                                                                                                   quantity_received,
                                                                                                                                                   quantity_received_us}) =>{
                                                            return(
                                                                // <tbody>
                                                                    <tr key={sp_id}>
                                                                        <td>{product_name}</td>

                                                                        <td>
                                                                            {/*<CommonImagePreviewHandler*/}
                                                                            {/*    token={props.token}*/}
                                                                            {/*    value={sp_id}*/}
                                                                            {/*    keyId={"sp_id"}*/}
                                                                            {/*/>*/}
                                                                            <CommonGalleryDisplayHandler
                                                                                    token={props.token}
                                                                                    value={sp_id}
                                                                                    keyId={"sp_id"}
                                                                                />
                                                                        </td>
                                                                        <td>{quantity_received}</td>

                                                                        <td><Form.Group as={Col} controlId="remainingValidation">
                                                                            <Form.Control
                                                                                type="number"
                                                                                name="quantity_received_us"
                                                                                placeholder={quantity_received_us}
                                                                                required
                                                                                isInvalid={!parseInt(quantity_received_us) > 0 || quantity_received_us > quantity_received}
                                                                                value={quantity_received_us}
                                                                                onChange={(e) => onChangeRemainingQuantity(e, sp_id)}
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                Kiểm tra lại số lượng!
                                                                            </Form.Control.Feedback>

                                                                        </Form.Group>
                                                                        </td>
                                                                            {/*{*/}
                                                                            {/*    currentUSShipment.is_delivered?(*/}
                                                                            {/*        <td>*/}
                                                                            {/*            <Link to ={{*/}
                                                                            {/*                pathname:"/shippedproduct/" + sp_id + '/view',*/}
                                                                            {/*                state:{*/}
                                                                            {/*                    currentShippedProductId:sp_id,*/}
                                                                            {/*                    currentShipmentId: initialUSShipmentId,*/}
                                                                            {/*                    isDeliveredVN: currentUSShipment.is_delivered_vn*/}
                                                                            {/*                }*/}
                                                                            {/*            }}>*/}
                                                                            {/*                <Button variant="outline-success" className="me-2"><FaImages title="Thêm Hình"/></Button>*/}
                                                                            {/*            </Link>*/}
                                                                            {/*        </td>*/}
                                                                            {/*    ):(*/}

                                                                        <td><Button variant="outline-warning" className="me-2" onClick={(e) => saveQuantityFields(e, sp_id)}><FaFloppyDisk title="Cập Nhật"/></Button>
                                                                            <Link to ={{
                                                                                pathname:"/shippedproduct/" + sp_id + '/view',
                                                                                state:{
                                                                                    currentShippedProductId:sp_id,
                                                                                    currentShipmentId: initialUSShipmentId,
                                                                                    isDeliveredVN: currentUSShipment.is_delivered_vn
                                                                                }
                                                                            }}>
                                                                                <Button variant="outline-success" className="me-2"><FaImages title="Thêm Hình"/></Button>
                                                                            </Link>
                                                                            {/*<ConfirmPopup/>*/}
                                                                            {/*{*/}
                                                                            {/*    props.role!=='staff'?<Button variant="outline-danger" className="me-2" onClick={(e) => deleteShippedOrderedProduct(e, sp_id)}><FaTrash title="Xóa"/></Button>:*/}
                                                                            {/*    ""*/}
                                                                            {/*}*/}

                                                                        </td>


                                                                            {/*    )*/}
                                                                            {/*}*/}
                                                                    </tr>
                                                                // {/*</tbody>*/}
                                                                )

                                                        })
                                                        //     :(
                                                        //     <tr>
                                                        //         <Alert variant="info">Chưa có sản phẩm. Hãy thêm sản phẩm vào đơn hàng vận chuyển</Alert>
                                                        //     </tr>
                                                        // )
                                                    }
                                                    </tbody>
                                                </Table>
                                                </Accordion>
                                                )

                                        }): ""
                                    }
                                    {
                                        currentUSShipment.is_delivered?(""):(
                                            <Accordion>
                                                    <Accordion.Item eventKey="0" >
                                                        <Accordion.Header>Chọn Đơn Giao Hàng Đã Nhận Ở Việt Nam Để Vận Chuyển Qua Mỹ</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Form noValidate validated={validated}>
                                                                <Row>
                                                                    <Form.Group as={Col} controlId="orderValidation">
                                                                        <Form.Label>Đơn Hàng</Form.Label>
                                                                        <Form.Select
                                                                            required
                                                                            value={selectedVNShipment}
                                                                            isInvalid={!selectedVNShipment}
                                                                            onChange={onChangeSelectedShipment}
                                                                            >
                                                                                <option></option>
                                                                                {
                                                                                    allVNDeliveredShipments.map((vnShipment) =>{
                                                                                        return(
                                                                                            <option value={vnShipment.shipment_id} key={vnShipment.shipment_id}
                                                                                                    data-key={vnShipment.shipment_id}>{vnShipment.label  + " - " + vnShipment.vendor_name}</option>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }

                                                                        </Form.Select>
                                                                        <Form.Control.Feedback type="invalid">
                                                                            Chọn đơn giao hàng ở Việt Nam
                                                                          </Form.Control.Feedback>
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} controlId="priceValidation">
                                                                        <Form.Label>Số Lượng Đã Nhận Ở Việt Nam</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            required
                                                                            disabled
                                                                            value={selectedShipmentQuantity}
                                                                        />
                                                                    </Form.Group>
                                                                    {/*<Form.Group as={Col} controlId="quantityValidation">*/}
                                                                    {/*    <Form.Label>Số Lượng Đã Gửi (Nếu Có)</Form.Label>*/}
                                                                    {/*     <Form.Control*/}
                                                                    {/*        type="number"*/}
                                                                    {/*        required*/}
                                                                    {/*        disabled*/}
                                                                    {/*        value={selectedOrderQuantityShipped}*/}
                                                                    {/*    />*/}
                                                                    {/*</Form.Group>*/}
                                                                    <Form.Group as={Col}>
                                                                        <br/>
                                                                        <Button variant="outline-success" onClick={handleSubmit}><FaPlus size="1.5em"/>&nbsp;Thêm</Button>
                                                                    </Form.Group>
                                                                </Row>

                                                            </Form>

                                                        </Accordion.Body>

                                                    </Accordion.Item>
                                                </Accordion>
                                        )
                                    }

                                </Tab.Content>
                            </Tab>
                            <Tab eventKey="1" title="Thanh Toán" className="mb-3">
                                <Tab.Content>
                                    {
                                        props.role === 'admin'?(
                                            <Link to={{
                                                pathname: "/payments/create",
                                                state:{
                                                    currentUSShipment: currentUSShipment,
                                                    isNew: true
                                                }}}>
                                                <Button variant="success" className="mb-3">
                                                    <FaDollarSign />&nbsp;Thanh Toán
                                                </Button>
                                            </Link>
                                        ):""
                                    }
                                    <PaymentListTemplate
                                        token={props.token}
                                        role={props.role}
                                        payments={shipmentUSPayments}
                                        currentShipment={null}
                                        paid={currentUSShipment.total_payment}
                                        remaining={currentUSShipment.remaining_payment_amount}
                                        currentOrder={null}
                                        currentUSShipment={currentUSShipment}
                                        fromShipment={false}
                                        fromOrder={false}
                                        fromUSShipment={true}
                                        getCurrentPageInfo={getShipmentPageInfo}
                                    />
                                </Tab.Content>
                            </Tab>
                        </Tabs>

                    </div>

                )


            }
        </Container>
    )

}

export default ViewUShipment;