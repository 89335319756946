import CommonDataService from './commondataservice';
import axios from "axios";

class ProductDataService extends CommonDataService{
    async getSearchProductDataPagination(pageNumber,token, name, vendor, type){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view/all?p=${pageNumber}&name=${name}&vendor=${vendor}&type=${type}`, {timeout:5000});
    }

    async getSearchProduct(token, name, vendor, type){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view?name=${name}&vendor=${vendor}&type=${type}`, {timeout:5000});
    }

    transformToNote(product){
        return [
            {
                key: '0',
                label: "Số Lượng Ở Mỹ: " + product.total_quantity_us,
                data: product.total_quantity_us,
                icon: 'pi pi-fw pi-box',
                children: [
                    {
                        key: '0-0',
                        label: 'Từ Đơn Hàng Đã Qua Mỹ: ' + product.total_quantity_us_from_order,
                        data: product.total_quantity_us_from_order,
                        icon: 'pi pi-fw pi-box',
                    },
                    {
                        key: '0-1',
                        label: 'Trước Khi Sử Dụng Hệ Thống: ' + product.quantity_before_new_system,
                        data: product.quantity_before_new_system,
                        icon: 'pi pi-fw pi-box',
                    },
                    {
                        key: '0-2',
                        label: 'Đã Bán: ' + product.sold_quantity,
                        data: product.sold_quantity,
                        icon: 'pi pi-fw pi-cart-plus',
                    },
                    {
                        key: '0-3',
                        label: 'Còn Lại Trong Kho: ' + product.in_stock_quantity,
                        data: product.in_stock_quantity,
                        icon: 'pi pi-fw pi-box',
                    }
                ]
            },
            {
                key: '1',
                label: 'Số Lượng Từ Các Đơn Hàng: ' + product.total_quantity_all_orders,
                data: product.total_quantity_all_orders,
                icon: 'pi pi-fw pi-box',
                children: [
                    {
                        key: '0-0',
                        label: 'Đang Ở Việt Nam: ' + product.total_quantity_vn,
                        data: product.total_quantity_vn,
                        icon: 'pi pi-fw pi-box',
                    },
                    {
                        key: '0-1',
                        label: 'Đang Chờ Giao: ' + (product.total_quantity_all_orders - product.total_quantity_vn - product.total_quantity_us_from_order),
                        data: (product.total_quantity_all_orders - product.total_quantity_vn - product.total_quantity_us_from_order),
                        icon: 'pi pi-spin pi-spinner',
                    },
                    {
                        key: '0-2',
                        label: 'Từ Các Đơn Hàng Đã Qua Mỹ: ' + product.total_quantity_us_from_order,
                        data: product.total_quantity_us_from_order,
                        icon: 'pi pi-fw pi-box',
                    },

                ]
            },

        ]
    }
}

const productDataService = new ProductDataService('products', 'product');
export default productDataService;