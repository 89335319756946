import React, {useState, useEffect} from "react";
import vendorDataService from '../services/vendors';
import {Link} from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Alert from 'react-bootstrap/Alert';
import DataPagination from "./pagination";
import {InputGroup} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {FaSearch} from "react-icons/fa";
import productDataService from "../services/products";
import {FaPencil} from "react-icons/fa6";
import {Tag} from "primereact/tag";
import utils from "../services/utils";


const VendorList = props => {
    const[vendors, setVendors] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordCount, setRecordCount] = useState(0);
    const [vendorSearchTerm, setVendorSearchTerm] = useState("");
    const pageSize = 10;

    useEffect(() =>{
        retrieveVendorPagination(currentPage);
    }, [props.token, currentPage]);


    const retrieveVendorPagination = (currentPage) =>{
        vendorDataService.getDataPagination(currentPage,props.token)
        .then(response => {
            setVendors(response.data.results);
            setRecordCount(response.data.count);
        })
        .catch(e=>{
            console.log(e);
        });
    }
    // const deleteVendor = (orderId) =>{
    //     vendorDataService.delete(orderId, props.token)
    //     .then(response => {
    //         retrieveVendorPagination(currentPage);
    //     })
    //     .catch(e =>{
    //         console.log(e);
    //     });
    // }
    const onChangeVendorSearchTerm = e => {
        const searchTerm = e.target.value;
        setVendorSearchTerm(searchTerm);
    }

    const activeVendor = (vendorId) => {
        vendorDataService.activate_deactivate(vendorId, props.token)
        .then(response => {
            retrieveVendorPagination(currentPage);
        })
        .catch(e => {
            console.log(e);
        });
    }

    const handlePagination = (currentPage) => {
        setCurrentPage(currentPage);
    }

    const retrieveSearchProducts = () => {
        if (vendorSearchTerm.trim().length !== 0){
                vendorDataService.getSearchVendorDataPagination(currentPage, props.token, vendorSearchTerm)
                .then( r=> {
                    const data = r.data;
                    setVendors(data.results);
                    setRecordCount(data.count);
                })
                .catch(e => {
                    console.log(e);
                    setCurrentPage(1);
                    productDataService.getSearchProductDataPagination(1, props.token, vendorSearchTerm)
                        .then( r=> {
                            const data = r.data;
                            setVendors(data.results);
                            setRecordCount(data.count);
                        })
                        .catch(e => console.log(e));
                });
            }
        else{
            retrieveVendorPagination(currentPage);
        }

    }


    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <h2>Danh Sách Nhà Cung Cấp</h2>
                        <Link to={"/vendors/create"}>
                            <Button variant="success" className="mb-3">
                            Thêm Nhà Cung Cấp
                            </Button>
                        </Link>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Nhà Cung Cấp</InputGroup.Text>
                            <Form.Control
                                placeholder="Nhà Cung Cấp"
                                value={vendorSearchTerm}
                                onChange={onChangeVendorSearchTerm}
                            />
                            <Button onClick={retrieveSearchProducts}><FaSearch/></Button>
                        </InputGroup>
                        <DataPagination
                        pageSize={pageSize}
                        recordCount={recordCount}
                        handlePagination={handlePagination}
                        currentPage={currentPage}
                        />
                        
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>Nhà Cung Cấp</th>
                                    <th>Tài Khoản</th>
                                    <th>Email</th>
                                    <th>Số Điện Thoại</th>
                                    <th>Facebook</th>
                                    <th>Trạng Thái</th>
                                    <th></th>
                                </tr>
                                
                            </thead>
                                {
                                    vendors.map((vendor) =>{
                                        return(
                                            <tbody>
                                                <tr key={vendor.vendor_id}>
                                                    <td><Link to ={{
                                                            pathname:"/vendor/"+ vendor.vendor_id + "/view",
                                                            state:{
                                                                currentVendor:vendor
                                                            }
                                                        }}>
                                                        {vendor.name}
                                                        </Link></td>
                                                    <td>{vendor.username}</td>
                                                    <td>{vendor.email}</td>
                                                    <td>{vendor.phone_no}</td>
                                                    <td >{vendor.facebook_handler}</td>
                                                    <td >{vendor.is_active?<Tag severity="success" icon="pi pi-check" value="Đang Cộng Tác"></Tag>:
                                                        <Tag severity="danger" icon="pi pi-times" value="Ngưng Cộng Tác"></Tag>}</td>
                                                    <td>
                                                        <Link to ={{
                                                            pathname:"/vendors/" + vendor.vendor_id,
                                                            state:{
                                                                currentVendor:vendor
                                                            }
                                                        }}>
                                                        <Button variant="outline-info" className="me-2"><FaPencil title="Chỉnh Sửa"/></Button>
                                                        </Link>
                                                        {/*<Button variant="outline-success" onClick={() => activeVendor(vendor.vendor_id)}>{vendor.is_active?"Deactivate Vendor":"Activate Vendor"}</Button>*/}
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                            
                                        )
                                    }

                                    )
                                }
                        </Table>

                    </div>
                )
            }
        </Container>
    )
}



export default VendorList;