import CommonDataService from './commondataservice';
import axios from "axios";

class ProductPhotoDataService extends CommonDataService{
    async getAllProductPhotos(token, productId){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view?product_id=${productId}`, {timeout:5000})
    }
}

const productPhotoDataService = new ProductPhotoDataService('productphotos', 'productphoto');
export default productPhotoDataService;