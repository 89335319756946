import React, {useEffect, useRef, useState} from "react";
import productDataService from '../services/products'
import vendorDataService from '../services/vendors';
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import { AutoComplete } from "primereact/autocomplete";
import "primereact/resources/themes/lara-light-blue/theme.css";
import categoryDataService from "../services/categories";
import productCategoryDataService from "../services/productcategories";
import {InputGroup} from "react-bootstrap";
import VNCurrencyInput from "./vn-currency-input-mask";
import utils from "../services/utils";
import {FaArrowLeft, FaPencil} from "react-icons/fa6";
import { Toast } from 'primereact/toast';
import {v4 as uuidv4} from "uuid";
import USCurrencyInput from "./us-currency-input-mask";

const AddProduct = props => {
    let editing = false;
    let initialNote= "";
    let intitialProductType="";
    let initialProductName = "";
    let initialVendor = "";
    let initialVendorName = "";
    let fromVendor = false;
    let initialVendorFromVendor = "";
    let initialPrice = 0;
    let initialBeforeQuantity = 0;
    let initialProductId = "";
    let initialSellingPrice = "";
    let initialVendorSearchName = "";
    let initialSearchProductTerm = '';
    let initialSearchVendorTerm = '';
    let initialSearchCategoryTerm = '';

    if(props.location.state && props.location.state.currentProduct){
        // console.log('currentProduct:', props.location.state.currentProduct);
        editing = true;
        initialNote = props.location.state.currentProduct.notes;
        initialProductId = props.location.state.currentProduct.product_id;
        intitialProductType = props.location.state.currentProduct.product_type;
        initialProductName = props.location.state.currentProduct.name;
        initialVendor = props.location.state.currentProduct.vendor_id;
        initialVendorName = props.location.state.currentProduct.vendor_name;
        initialVendorSearchName = props.location.state.currentProduct.vendor_name + "|" + props.location.state.currentProduct.vendor_facebook_handler
        initialBeforeQuantity = props.location.state.currentProduct.quantity_before_new_system;
        initialSellingPrice = utils.formatCurrency(props.location.state.currentProduct.selling_price, 'us');
        initialPrice = utils.formatCurrency(props.location.state.currentProduct.price);
        fromVendor = props.location.state.fromVendor;
        if(fromVendor)
            initialVendorFromVendor = props.location.state.currentVendor;
    }

    if(props.location.state && props.location.state.searchProductTerm){
        initialSearchProductTerm = props.location.state.searchProductTerm;

    }
    if(props.location.state && props.location.state.searchVendorTerm){
        initialSearchVendorTerm = props.location.state.searchVendorTerm;

    }
    if(props.location.state && props.location.state.searchCategoryTerm){
        initialSearchCategoryTerm = props.location.state.searchCategoryTerm;
    }

    const[vendors, setVendors] = useState([]);
    const[selectedVendor, setSelectedVendor] = useState(initialVendor);
    // const[productType, setProductType] = useState(intitialProductType);
    const[submitted, setSubmitted] = useState(false);
    const [productName, setProductName] = useState(initialProductName);
    const[note, setNote] = useState(initialNote);
    const[price, setPrice] = useState(initialPrice);
    const[sellingPrice, setSellingPrice] = useState(initialSellingPrice);
    const [beforeQuantity, setBeforeQuantity] = useState(initialBeforeQuantity);
    const [selectedCategory, setSelectedCategory] = useState(intitialProductType);
    const [filteredCategories, setFilteredCategories] = useState(null);
    const [selectedVendorName, setSelectedVendorName] = useState(initialVendorSearchName);
    const [filteredVendors, setFilteredVendors] = useState(null);
    const [categories, setCategories] = useState([]);
    const [newProductId, setNewProductId] = useState("");
    const toast = useRef(null);

    const toastErrorNotification = () => {
        toast.current.show({severity:'error', summary: 'Có Lỗi', detail:'Kiểm tra lại Tên Sản Phẩm và Nhà Cung Cấp', life: 2000});
    }



    useEffect(() =>{
        retrieveVendors();
        retrieveCategories();
    },[props.token]);

    const retrieveVendors = () =>{
        vendorDataService.getAll(props.token).then(response=>{
            setVendors(response.data);
        }).catch(e =>{
            console.log(e);
        });
    }

    const retrieveCategories = () => {
        categoryDataService.getAll(props.token)
            .then( r=>{
                // console.log(r.data);
                setCategories(r.data);
            })
            .catch(e=> console.log(e));
    }

    const search = (event) => {
        setTimeout(()=>{
            let _filteredCategories;
            const categoriesName = categories.map(category => category.name)
            if(!event.query.trim().length){

                _filteredCategories = [...categoriesName];
            }
            else{
                console.log(event.query);
                _filteredCategories = categoriesName.filter((category) =>{
                    return category.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            setFilteredCategories(_filteredCategories);
        }, 250)
    }

    const searchVendor = (event) => {
        setTimeout(()=>{
            let _filteredVendorNames;
            const vendorNames = vendors.map(vendor => vendor.name + "|" + vendor.facebook_handler);
            if(!event.query.trim().length){

                _filteredVendorNames = [...vendorNames];
            }
            else{
                // console.log(event.query);
                _filteredVendorNames = vendorNames.filter((vendorName) =>{
                    return vendorName.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            setFilteredVendors(_filteredVendorNames);
        }, 250)
    }

    const onChangeVendor = e => {
        // const selectedVendor = e.target.value;
        // setSelectedVendor(selectedVendor);
        setSelectedVendorName(e.target.value);
        const selectedVendor = (e.target.value).split("|") ;
        const selectVendorName = selectedVendor[0];
        const facebookHandler = selectedVendor[1];
        const vendor = vendors.filter(vendor => vendor.name === selectVendorName && vendor.facebook_handler===facebookHandler);
        // console.log('vendor:', vendor);
        // console.log('vendor_id:', vendor[0].vendor_id);
        if(vendor.length>0)
            setSelectedVendor(vendor[0].vendor_id);
    }

    const onChangeBeforeQuantity = e => {
        const beforeQuantity = e.target.value;
        setBeforeQuantity(beforeQuantity);
    }

    const onChangePrice = e => {
        const price = e.target.value;
        setPrice(price);
    }

    const onChangeSellingPrice = e => {
        const price = e.target.value;
        setSellingPrice(price);
    }


    const onChangeProductName = e => {
        const productName = e.target.value;
        setProductName(productName);
    }

    const onChangeNote = e =>{
        const note = e.target.value;
        setNote(note);
    }

    // const onChangeProductType = e => {
    //     const productType = e.target.value;
    //     setProductType(productType);
    // }

    // const onClickAddCategory = () =>{
    //     console.log(selectedCategory);
    //     const category = categories.filter(category => category.name === selectedCategory);
    //     console.log(category);
        // console.log(category[0].category_id);
        // let productCategoryData = {
        //     category_id: category[0].category_id,
        //     product_id: initialProductId,
        //     created_by: localStorage.getItem('username'),
        //     last_modified_by: localStorage.getItem('username')
        // }
        // console.log(productCategoryData);
        // productCategoryDataService.create(productCategoryData, props.token)
        //     .then( r => {
        //         setSelectedCategory(null);
        //     })
        //     .catch(e => console.log(e));
    // }

    const saveProduct = (e) => {
        e.preventDefault();
        var data;
        if(editing){
            data = {
                vendor_id: selectedVendor,
                product_type: selectedCategory,
                notes: note,
                name: productName,
                quantity_before_new_system: beforeQuantity,
                price: utils.parseVNCurrencyInput(price),
                selling_price: utils.parseUSCurrencyInput(sellingPrice),
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
            }
            // console.log(data);
            productDataService.update(props.location.state.currentProduct.product_id, data, props.token).then(response =>{
                setSubmitted(true);
            }).catch(e =>{
                console.log(e);
                toastErrorNotification();
            })
            
        }
        else{
            const product_id = uuidv4();
            setNewProductId(product_id);
            data = {
                product_id: product_id,
                vendor_id: selectedVendor,
                notes: note,
                price: utils.parseVNCurrencyInput(price),
                selling_price: utils.parseUSCurrencyInput(sellingPrice),
                product_type: selectedCategory,
                name: productName,
                quantity_before_new_system: beforeQuantity,
                created_by: localStorage.getItem('username'),
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
            }
            productDataService.create(data, props.token).then(response =>{
                setSubmitted(true);
            }).catch(e =>{
                console.log(e);
                toastErrorNotification();
            });
        }
        
    }

    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(submitted?(
                    <div>
                        <h4>Sản phẩm {editing?"được cập nhật":"được tạo"} thành công</h4>
                        {
                            !editing?<Link to={{
                                pathname: "/product/" + newProductId + '/view'
                            }}>
                                Đi tới trang sản phẩm mới tạo <br/>
                            </Link>:
                                <Link to={{
                                    pathname: "/product/" + initialProductId + '/view',
                                    state:{
                                        searchProductTerm: initialSearchProductTerm,
                                        searchVendorTerm: initialSearchVendorTerm,
                                        searchCategoryTerm: initialSearchCategoryTerm
                                    }
                                    }}>
                                    Quay Lại Trang Sản Phẩm<br/>
                            </Link>
                        }
                        <Link to={{
                            pathname:"/products/",
                            state:{
                                searchProductTerm: initialSearchProductTerm,
                                searchVendorTerm: initialSearchVendorTerm,
                                searchCategoryTerm: initialSearchCategoryTerm
                            }
                        }}>Quay lại danh sách sản phẩm</Link>
                        {
                            <div>
                                <br/>
                                {
                                fromVendor?(<Link to={{
                                    pathname: "/vendor/" + initialVendor + '/view',
                                    state: {
                                        currentVendor: initialVendorFromVendor
                                    }

                                }}>Quay lại nhà cung cấp</Link>):''}
                            </div>

                        }
                    </div>
                ):(
                    <Form onSubmit={saveProduct}>
                        <Toast ref={toast} />
                        {/*<Form.Group className="mb-3">*/}
                        {/*    <Form.Label>Tên Sản Phẩm</Form.Label>*/}
                        {/*    <Form.Control*/}
                        {/*        type='text'*/}
                        {/*        placeholder="Nhập tên sản phẩm"*/}
                        {/*        value={productName}*/}
                        {/*        onChange={onChangeProductName}*/}
                        {/*    />*/}
                        {/*</Form.Group>*/}
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Tên Sản Phẩm</InputGroup.Text>
                            <Form.Control
                                type='text'
                                placeholder="Nhập tên sản phẩm"
                                value={productName}
                                isInvalid={productName.length <=0}
                                onChange={onChangeProductName}
                            />
                            <Form.Control.Feedback type="invalid">
                              Điền vào tên sản phẩm!
                            </Form.Control.Feedback>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Nhà Cung Cấp</InputGroup.Text>
                            <AutoComplete invalid={selectedVendorName.length<1} value={selectedVendorName} suggestions={filteredVendors} completeMethod={searchVendor} onChange={(e) => onChangeVendor(e)} dropdown />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Giá Nhập</InputGroup.Text>
                            <VNCurrencyInput value={price} onChange={onChangePrice} type="text" className="border-solid border-1 p-2"/>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Giá Bán</InputGroup.Text>
                            <USCurrencyInput value={sellingPrice} onChange={onChangeSellingPrice} type="text" className="border-solid border-1 p-2"/>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Số Lượng Đang Có Trước Hệ Thống Mới</InputGroup.Text>
                            <Form.Control
                                type='number'
                                placeholder="Nhập số lượng sản phẩm trước khi sử dụng hẹ thống mới"
                                value={beforeQuantity}
                                onChange={onChangeBeforeQuantity}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Phân Loại Sản Phẩm</InputGroup.Text>
                            <AutoComplete value={selectedCategory} suggestions={filteredCategories} completeMethod={search} onChange={(e) => setSelectedCategory(e.value)} dropdown />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Ghi chú</InputGroup.Text>
                            <Form.Control
                                as='textarea'
                                rows={3}
                                placeholder="Nhập ghi chú"
                                value={note}
                                onChange={onChangeNote}
                            />
                        </InputGroup>
                        <Link to={{
                            pathname:"/products/",
                            state:{
                                searchProductTerm: initialSearchProductTerm,
                                searchVendorTerm: initialSearchVendorTerm,
                                searchCategoryTerm: initialSearchCategoryTerm
                            }
                        }}><Button className="me-2"><FaArrowLeft/></Button></Link>
                        <Button className="me-2" variant="info" type="submit">{editing?"Cập Nhật":"Tạo Mới"}</Button>
                    </Form>
                ))

                
            }
        </Container>
    )

}

export default AddProduct;