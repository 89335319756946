import axios from 'axios';

export default class CommonDataService{
    constructor(objectNames, objectName){
        this.objectName = objectName;
        this.objectNames = objectNames;
        this.baseURL = 'http://66.42.91.241:8000/api/'
    }
    async getAll(token, filter=''){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        if(filter)
            return await axios.get(this.baseURL+`${this.objectNames}/view?filtered_id=${filter}`);
        return await axios.get(this.baseURL+ this.objectNames+ '/view');
    }

    async getDataPagination(pageNumber,token, filter=''){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        if(filter)
            return await axios.get(this.baseURL+`${this.objectNames}/view/all?p=${pageNumber}&filtered_id=${filter}`);
        return await axios.get(this.baseURL+`${this.objectNames}/view/all?p=${pageNumber}`);
    }


    async create(data, token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.post(this.baseURL+ this.objectNames +'/create', data);
    }

    async update(id, data, token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.put(this.baseURL+`${this.objectNames}/${id}`, data);
    }

    async getOne(id, token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectName}/${id}`);
    }

    async delete(id, token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.put(this.baseURL+`${this.objectName}/${id}/delete`);
    }
}

// export default new CommonDataService();