import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import {Link, NavLink} from "react-router-dom";
import {InputGroup} from "react-bootstrap";
import utils from "../services/utils";

const HomePage = props => {
    return(
        <Container>
            {
                 utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <h2>Xin Chào!</h2>
                        {
                            props.role==="admin"?(
                                ""
                            ):(
                                props.role==="staff"?(
                                    <InputGroup>
                                        <InputGroup.Text>Hãy đến trang &nbsp;
                                        <NavLink class="nav-link" to={"/shipments"}><b>Giao Hàng</b></NavLink>
                                        &nbsp; để bắt đầu</InputGroup.Text>
                                    </InputGroup>

                                ):
                                    (
                                    <InputGroup>
                                        <InputGroup.Text>Hãy đến trang &nbsp;
                                        <NavLink class="nav-link" to={"/orders"}><b>Đơn Hàng</b></NavLink>
                                        &nbsp; để bắt đầu</InputGroup.Text>
                                    </InputGroup>
                                    )
                            )
                        }
                    </div>


                 )
            }

        </Container>
    )
}

export default HomePage;