import CommonDataService from './commondataservice';
import axios from "axios";
// import axios from "axios";

class FairProductDataService extends CommonDataService{
    async getFairProductsByProduct(productId,token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view?productId=${productId}`, {timeout:5000});
    }
}

const fairProductDataService = new FairProductDataService('fairproducts', 'fairproduct');
export default fairProductDataService;