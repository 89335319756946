import React, {useEffect, useRef, useState} from "react";
import productDataService from '../services/products'
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import {InputGroup} from "react-bootstrap";
import paymentDataService from "../services/payments";
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import {FaArrowLeft} from "react-icons/fa6";
import orderDataService from "../services/orders";
import shipmentDataService from "../services/shipments";
import paymentPhotoDataService from "../services/paymentphotos";
import CommonImageUploadHandler from "./common-image-upload-handler";
import CommonImageDisplayHandler from "./common-image-display-handler";
import ShippedPhotoImageHandler from "./shipped-photo-image-handler";
import utils from "../services/utils";
import VNCurrencyInput from "./vn-currency-input-mask";
import USCurrencyInput from "./us-currency-input-mask";

const AddPayment = props => {
    let editing = false;
    let initialNote= "";
    let initialPaymentId="";
    let initialPaymentDate = null;
    let initialPaymentAmount = 0;
    let initialOrderId = "";
    let fromOrder = false;
    let initialShipmentId = "";
    let fromShipment = false;
    let fromUSShipment = false;
    let initialBankName = "";
    let initialTransactionId = "";
    let initialIsDeposit = false;
    let initialIsPaid = true;
    let initialVendorName = "";
    let initialOrder = null;
    let initialShipment = null;
    let initialUSShipment = null;
    let initialUSShipmentId = "";
    let initialRemainingOrderPaymentAmount = 0;
    let initialRemainingShipmentPaymentAmount = 0;
    let initialFairId = "";
    let initialFair = null;
    let fromFair = false;
    let adjustPayment = 0;
    let isUploadPhoto = false;
    const [isUploadPhotos, setIsUploadPhotos] = useState(isUploadPhoto);
    let initialPaidTo = "";


    if(props.location.state && props.location.state.currentOrder){
        editing = !props.location.state.isNew;
        initialOrderId = props.location.state.currentOrder.order_id;
        fromOrder = true;
        initialOrder = props.location.state.currentOrder;
        initialVendorName = props.location.state.currentOrder.vendor_name;
        initialRemainingOrderPaymentAmount = props.location.state.currentOrder.remaining_payment_amount;
        adjustPayment += initialRemainingOrderPaymentAmount;
        initialPaymentAmount = initialRemainingOrderPaymentAmount;
    }

    if(props.location.state && props.location.state.currentShipment){
        editing = !props.location.state.isNew;
        initialShipmentId = props.location.state.currentShipment.shipment_id;
        fromShipment = true;
        initialShipment = props.location.state.currentShipment;
        initialRemainingShipmentPaymentAmount = props.location.state.currentShipment.remaining_payment_amount
        initialVendorName = props.location.state.currentShipment.vendor_name;
        adjustPayment += initialRemainingShipmentPaymentAmount;
        initialPaymentAmount = initialRemainingShipmentPaymentAmount;
    }

    if(props.location.state && props.location.state.currentUSShipment){
        editing = !props.location.state.isNew;
        initialUSShipmentId = props.location.state.currentUSShipment.shipment_us_id;
        fromUSShipment = true;
        initialUSShipment = props.location.state.currentUSShipment;
        initialRemainingShipmentPaymentAmount = props.location.state.currentUSShipment.remaining_payment_amount
        adjustPayment += initialRemainingShipmentPaymentAmount;
        initialPaymentAmount = initialRemainingShipmentPaymentAmount;
    }

    if(props.location.state && props.location.state.currentFair){
        editing = !props.location.state.isNew;
        initialFairId = props.location.state.currentFair.fair_id;
        fromFair = true;
        initialFair = props.location.state.currentFair;
    }

    if(props.location.state && props.location.state.currentPayment){
        editing = true;
        initialShipmentId = props.location.state.currentPayment.shipment_id;
        initialUSShipmentId = props.location.state.currentPayment.shipment_id;
        initialOrderId = props.location.state.currentPayment.order_id;
        initialNote =  props.location.state.currentPayment.notes;
        initialPaymentId = props.location.state.currentPayment.payment_id;
        // console.log('props.location.state.currentPayment.payment_amount', Number(props.location.state.currentPayment.payment_amount));
        // console.log('props.location.state.currentPayment.payment_amount', typeof(props.location.state.currentPayment.payment_amount));
        adjustPayment += utils.getInt(props.location.state.currentPayment.payment_amount);
        // console.log("inital adjustPayment", adjustPayment);
        initialPaymentDate = utils.formatDatePicker(props.location.state.currentPayment.payment_date);
        initialPaymentAmount = utils.formatCurrency(props.location.state.currentPayment.payment_amount);
        initialBankName = props.location.state.currentPayment.bank_name;
        initialIsDeposit = props.location.state.currentPayment.is_deposit;
        initialIsPaid = props.location.state.currentPayment.is_paid;
        initialTransactionId = props.location.state.currentPayment.transaction_id;
        initialVendorName = props.location.state.currentPayment.vendor_name;
        initialPaidTo = props.location.state.currentPayment.paid_to;

    }

    const [payment, setPayment] = useState(null);
    const [note, setNote] = useState(initialNote);
    const [paymentDate, setPaymentDate] = useState(initialPaymentDate);
    const [paymentAmount, setPaymentAmount] = useState(initialPaymentAmount);
    const [bankName, setBankName] = useState(initialBankName);
    const [isDeposit, setIsDeposit] = useState(initialIsDeposit);
    const [isPaid, setIsPaid] = useState(initialIsPaid);
    const [shipmentId, setShipmentId] = useState(initialShipmentId);
    const [orderId, setOrderId] = useState(initialOrderId);
    const [fairId, setFairId] = useState(initialFairId);
    const [usShipmentId, setUSShipmentId] = useState(initialUSShipmentId);
    const [transactionId, setTransactionId] = useState(initialTransactionId);
    const [vendorName, setVendorName] = useState(initialVendorName);
    const toast = useRef(null);
    const [isNew, setIsNew] = useState(!editing);
    const [remainingOrderPaymentAmount, setRemainingOrderPaymentAmount] = useState(utils.formatCurrency(initialRemainingOrderPaymentAmount));
    const [remainingShipmentPaymentAmount, setRemainingShipmentPaymentAmount] = useState(utils.formatCurrency(initialRemainingShipmentPaymentAmount));
    const [paidTo, setPaidTo] = useState(initialPaidTo);




    useEffect(() =>{
        retrievePayment(initialPaymentId);
    },[props.token]);

    const refreshPage = () => {
        retrievePayment(initialPaymentId);
    }

    const retrievePayment = (paymentId) => {
        if(paymentId.trim().length !== 0){
            paymentDataService.getOne(paymentId, props.token)
            .then(r => {
                const payment = r.data;
                setPayment(payment);
                setNote(payment.notes);
                setPaymentDate(utils.formatDatePicker(payment.payment_date));
                setPaymentAmount(utils.formatCurrency(payment.payment_amount));
                setBankName(payment.bank_name);
                setIsDeposit(payment.is_deposit);
                setIsPaid(payment.is_paid);
                setOrderId(payment.order_id);
                setShipmentId(payment.shipment_id);
                setFairId(payment.fair_id);
                setUSShipmentId(payment.shipment_us_id)
                setTransactionId(payment.transaction_id);
                setVendorName(payment.vendor_name);
                setPaidTo(payment.paid_to);
            })
            .catch(e => console.log(e));
        }
    }



    const onChangeNote = e => {
        setNote(e.target.value);
    }

    const onChangePaymentDate = e => {
        setPaymentDate(e.target.value);
    }

    const onChangePaidTo = e => {
        setPaidTo(e.target.value);
    }

    const onChangePaymentAmount = e => {
        setPaymentAmount(e.target.value);
        let converseAmount;
        if(fromFair){
            converseAmount = utils.parseUSCurrencyInput(e.target.value);
        }
        else{
            converseAmount = utils.parseVNCurrencyInput(e.target.value);
        }

        // console.log('adjustPayment: ', adjustPayment);
        // console.log(converseAmount);
        if(fromOrder){
            // initialRemainingPayment = initialRemainingOrderPaymentAmount === parseInt(initialRemainingOrderPaymentAmount,
            // 10)?initialRemainingOrderPaymentAmount:parseInt(initialRemainingOrderPaymentAmount.replace('.', ''));
            setRemainingOrderPaymentAmount(utils.formatCurrency(adjustPayment - converseAmount));
        }
        else if(fromShipment || fromUSShipment){
            // initialRemainingPayment = initialRemainingShipmentPaymentAmount === parseInt(initialRemainingShipmentPaymentAmount,
            // 10)?initialRemainingShipmentPaymentAmount:parseInt(initialRemainingShipmentPaymentAmount.replace('.', ''));
            setRemainingShipmentPaymentAmount(utils.formatCurrency(adjustPayment - converseAmount));
        }


    }

    const onChangeBankName = e => {
        setBankName(e.target.value);
    }

    const onChangeTransactionId = e => {
        setTransactionId(e.target.value);
    }

    const onClickUploadPicture = () => {
        const isUpload = !isUploadPhotos;
        setIsUploadPhotos(isUpload);
    }

    const savePayment = (e) => {
        e.preventDefault();
        var data;
        let paymentAmountParse;
        if(fromFair){
            paymentAmountParse = utils.parseUSCurrencyInput(paymentAmount);
        }
        else{
            paymentAmountParse = utils.parseVNCurrencyInput(paymentAmount);
        }

        if(!isNew){
            data = {
                notes: note,
                payment_date: moment(paymentDate).add(12, 'hours'),
                bank_name: bankName,
                payment_amount: paymentAmountParse,
                order_id: orderId,
                shipment_id: shipmentId,
                shipment_us_id: usShipmentId,
                is_deposit: isDeposit,
                transaction_id: transactionId,
                fair_id: fairId,
                paid_to: paidTo,
                is_paid: true,
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
            }
            paymentDataService.update(payment.payment_id, data, props.token).then(response =>{
                toast.current.show({ severity: 'info', detail: "Cập nhật biên lai thanh toán thành công!" });
            }).catch(e =>{
                toast.current.show({ severity: 'danger', detail: "Có lỗi! Hãy kiểm tra lại thông tin" });
                console.log(e);
            })

        }
        else{
            data = {
                notes: note,
                payment_date: moment(paymentDate).add(12, 'hours'),
                bank_name: bankName,
                payment_amount: paymentAmountParse,
                order_id: orderId,
                shipment_id: shipmentId,
                shipment_us_id: usShipmentId,
                fair_id: fairId,
                is_deposit: isDeposit,
                transaction_id: transactionId,
                paid_to: paidTo,
                created_by: localStorage.getItem('username'),
                last_modified_by: localStorage.getItem('username'),
            }
            paymentDataService.create(data, props.token).then(response =>{
                toast.current.show({ severity: 'info', detail: "Tạo biên lai thanh toán thành công!" });
                setIsNew(false);
            }).catch(e =>{
                console.log(e);
                toast.current.show({ severity: 'danger', detail: "Có lỗi! Hãy kiểm tra lại thông tin" });
            });
        }

    }

    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <Container>

                    <Form className="mb-3" onSubmit={savePayment}>
                        <Toast ref={toast} />
                        <InputGroup className="mb-3 xl:w-3">
                            <InputGroup.Text>Ngày Thanh Toán</InputGroup.Text>
                            <Form.Control
                                type='date'
                                placeholder="Nhập ngày thanh toán"
                                value={paymentDate}
                                onChange={onChangePaymentDate}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Số Tiền</InputGroup.Text>
                            {/*<Form.Control*/}
                            {/*    type='numeric'*/}
                            {/*    placeholder="Nhập số tiền"*/}
                            {/*    value={paymentAmount}*/}
                            {/*    onChange={onChangePaymentAmount}*/}
                            {/*/>*/}
                            {
                                fromFair?<USCurrencyInput value={paymentAmount} onChange={onChangePaymentAmount} type="text" className="border-solid border-1 p-2 xl:w-11"/>:
                                    <VNCurrencyInput value={paymentAmount} onChange={onChangePaymentAmount} type="text" className="border-solid border-1 p-2 xl:w-11"/>
                            }

                        </InputGroup>
                        {
                            fromOrder?(
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>Số Tiền Còn Cần Trả</InputGroup.Text>
                                    <Form.Control
                                        type='text'
                                        disabled
                                        value={remainingOrderPaymentAmount}
                                    />
                                </InputGroup>
                            ):""
                        }
                        {
                            fromShipment?(
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>Số Tiền Còn Cần Trả</InputGroup.Text>
                                    <Form.Control
                                        type='text'
                                        disabled
                                        value={remainingShipmentPaymentAmount}
                                    />
                                </InputGroup>
                            ):""
                        }

                        {
                            fromUSShipment?(
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>Số Tiền Còn Cần Trả</InputGroup.Text>
                                    <Form.Control
                                        type='text'
                                        disabled
                                        value={remainingShipmentPaymentAmount}
                                    />
                                </InputGroup>
                            ):""
                        }{
                        (!fromUSShipment && !fromFair)?(
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>Thanh Toán Cho</InputGroup.Text>
                                    <Form.Control
                                        type='text'
                                        disabled
                                        value={vendorName}
                                    />
                                </InputGroup>
                                ):(
                                    <InputGroup className="mb-3">
                                    <InputGroup.Text>Thanh Toán Cho</InputGroup.Text>
                                    <Form.Control
                                        type='text'
                                        value={paidTo}
                                        onChange={onChangePaidTo}
                                    />
                                 </InputGroup>
                            )
                        }


                        <InputGroup className="mb-3">
                            <InputGroup.Text>Tên Ngân Hàng</InputGroup.Text>
                            <Form.Control
                                type='text'
                                placeholder="Nhập tên ngân hàng"
                                value={bankName}
                                onChange={onChangeBankName}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>ID Giao Dịch</InputGroup.Text>
                            <Form.Control
                                type='text'
                                placeholder="Nhập ID giao dịch"
                                value={transactionId}
                                onChange={onChangeTransactionId}
                            />
                        </InputGroup>
                        {
                            fromOrder?(
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>Đây là giao dịch trả tiền đặt cọc?</InputGroup.Text>
                                    <Checkbox onChange={e => setIsDeposit(e.checked)} checked={isDeposit}></Checkbox>
                                </InputGroup>
                            ):""
                        }

                        <InputGroup className="mb-3">
                            <InputGroup.Text>Ghi chú</InputGroup.Text>
                            <Form.Control
                                as='textarea'
                                rows={3}
                                placeholder="Nhập ghi chú"
                                value={note}
                                onChange={onChangeNote}
                            />
                        </InputGroup>
                        {
                            fromOrder?(<Link to ={{
                                        pathname:"/order/" + initialOrder.order_id + "/view",
                                        state:{
                                            currentOrder: initialOrder,
                                            currentVendorId: initialOrder.vendor_id,
                                            eventKey: 2
                                        }
                                    }}>
                                <Button variant="primary" className="me-2"><FaArrowLeft/></Button>
                                </Link>):""
                        }
                        {
                            fromShipment?(<Link to ={{
                                        pathname:"/shipment/" + shipmentId + "/view",
                                        state:{
                                            currentShipment: initialShipment,
                                            eventKey: 1
                                        }
                                    }}>
                                <Button variant="primary" className="me-2"><FaArrowLeft/></Button>
                                </Link>):""

                        }
                        {
                            fromUSShipment?(<Link to ={{
                                        pathname:"/usshipment/" + usShipmentId + "/view",
                                        state:{
                                            currentUSShipment: initialUSShipment,
                                            eventKey: 1
                                        }
                                    }}>
                                <Button variant="primary" className="me-2"><FaArrowLeft/></Button>
                                </Link>):""

                        }
                        {
                            fromFair?(<Link to ={{
                                        pathname:"/fair/" + fairId + "/view",
                                        state:{
                                            currentFair: initialFair,
                                            eventKey: 1
                                        }
                                    }}>
                                <Button variant="primary" className="me-2"><FaArrowLeft/></Button>
                                </Link>):""
                        }
                        {
                            isNew?<Button variant="info" className="me-2" type="submit"
                                          // onClick={savePayment}
                            >Tạo Mới</Button>:""
                        }
                        {
                            editing?<Button variant="info" className="me-2" type="submit"
                                            // onClick={savePayment}
                            >Cập Nhật</Button>:""
                        }

                    </Form>
                        {
                            editing?(
                                <Container>
                                    <Button variant="outline-primary" className="me-2" onClick={onClickUploadPicture}>{"Thêm Hình"}</Button>
                                        <Container>
                                            <div className="mb-3">
                                                {isUploadPhotos?<CommonImageUploadHandler
                                                    token={props.token}
                                                    keyId={"payment_id"}
                                                    valueId={initialPaymentId}
                                                />:""}
                                            </div>

                                        </Container>

                                    <CommonImageDisplayHandler
                                        token={props.token}
                                        keyId={"payment_id"}
                                        value={initialPaymentId}
                                    />
                                </Container>
                            ):""
                        }

                    </Container>
                )
            }
        </Container>
    )

}

export default AddPayment;