import React, {useState, useEffect, useRef} from "react";
import categoryDataService from "../services/categories";
import {Link} from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Alert from 'react-bootstrap/Alert';
import DataPagination from "./pagination";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Toast } from 'primereact/toast';
import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup'; // To use confirmPopup method
// import "primereact/resources/themes/lara-light-blue/theme.css";
import {
    FaFloppyDisk,
    FaPlus,
    FaImages,
    FaXmark,
    FaArrowLeft,
    FaTrash,
    FaPencil,
    FaTruckFast,
    FaPlane,
    FaTrashCan
} from "react-icons/fa6";
import {InputGroup} from "react-bootstrap";
import {FaShippingFast} from "react-icons/fa";
import shippedProductDataService from "../services/shippedproducts";
import moment from "moment/moment";
import utils from "../services/utils";

const CategoryList = (props) => {
    const[categories, setCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordCount, setRecordCount] = useState(0);
    const [name, setName] = useState("");
    const [validated, setValidated] = useState(false);
    const pageSize = 10;
    const toast = useRef(null);
    // const toastUpdateNotification = () => toast("Cập nhật thông tin hợp lệ thành công!", {autoClose: 1000});
    const toastDeleteNotification = () => toast.current.show({ severity: 'info', detail: "Xóa thành công!" });

    useEffect(() =>{
        retrieveCategoriesPagination(currentPage);
    }, [props.token, currentPage]);


    const retrieveCategoriesPagination = (currentPage) =>{
        categoryDataService.getDataPagination(currentPage,props.token)
        .then(response => {
            setCategories(response.data.results);
            setRecordCount(response.data.count);
        })
        .catch(e=>{
            console.log(e);
        });
    }

    const onChangeName = e => {
        const name = e.target.value;
        setName(name);
    }

    const handlePagination = (currentPage) => {
        setCurrentPage(currentPage);
    }

    const saveCategory = () =>{
        let data = {
            name: name,
            last_modified_by: localStorage.getItem('username'),
            created_by: localStorage.getItem('username'),
        }
        categoryDataService.create(data, props.token)
            .then(r=>{
                retrieveCategoriesPagination(currentPage);
            })
            .catch(e => console.log(e));
    }

    const deleteCategory = (event, category_id) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Bạn muốn bỏ phân loại sản phẩm này?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel:"Xác Nhận",
            rejectLabel: "Không",
            accept: () => handleDeleteCategory(event, category_id),
        })
    }
    const handleDeleteCategory = (e, category_id) => {
        categoryDataService.delete(category_id, props.token)
            .then( r => {
                toastDeleteNotification();
                // retrieveShipment(initialShipmentId);
                retrieveCategoriesPagination(currentPage);
            }
            )
            .catch(e => console.log(e));

    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        setValidated(true);
        saveCategory();
    // getShipmentPageInfo();
  };

    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <h2>Danh Sách Phân Loại Sản Phẩm</h2>
                        <ConfirmPopup/>
                        <Toast ref={toast} />
                        <DataPagination
                        pageSize={pageSize}
                        recordCount={recordCount}
                        handlePagination={handlePagination}
                        currentPage={currentPage}
                        />
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>Phân Loại</th>
                                    <th>Ngày Tạo</th>
                                </tr>

                            </thead>
                                {
                                    categories.map((category) =>{
                                        return(
                                            <tbody>
                                                <tr key={category.category_id}>
                                                    <td><Link to ={{
                                                            pathname:"/category/"+ category.category_id + "/view",
                                                            state:{
                                                                currentCategory:category
                                                            }
                                                        }}>
                                                        {category.name}
                                                        </Link></td>
                                                    <td>{moment(category.created_date).format('LL')}</td>
                                                    <td>
                                                        <Link to ={{
                                                            pathname:"/category/" + category.category_id + "/view",
                                                            state:{
                                                                currentCategory:category
                                                            }
                                                        }}>
                                                        <Button variant="outline-info" className="me-2"><FaPencil title="Chỉnh Sửa"/></Button>
                                                        </Link>
                                                        <Button variant="outline-danger" onClick={(e) => deleteCategory(e, category.category_id)}><FaTrashCan title="Xóa"/></Button>
                                                    </td>
                                                </tr>

                                            </tbody>

                                        )
                                    }

                                    )
                                }
                        </Table>
                        <Accordion>
                                        <Accordion.Item eventKey="0" >
                                            <Accordion.Header>Thêm Phân Loại</Accordion.Header>
                                            <Accordion.Body>
                                                <Form>
                                                    <Row>
                                                        <Form.Group as={Col} controlId="priceValidation">
                                                            <Form.Label>Tên</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                required
                                                                value={name}
                                                                isInvalid={!name}
                                                                onChange={onChangeName}

                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Điền tên
                                                              </Form.Control.Feedback>
                                                        </Form.Group>
                                                        <Form.Group as={Col}>
                                                            <br/>
                                                            <Button variant="outline-success" onClick={handleSubmit}><FaPlus size="1.5em"/>&nbsp;Thêm</Button>
                                                        </Form.Group>
                                                    </Row>

                                                </Form>

                                            </Accordion.Body>

                                        </Accordion.Item>
                                    </Accordion>
                    </div>
                )
            }

        </Container>
    )
}



export default CategoryList;