import Pagination from 'react-bootstrap/Pagination';
import React from 'react';
// import 'primeflex/primeflex.css';

const DataPagination = ({ pageSize, recordCount, handlePagination, currentPage }) => {
    let paginationNumber = []
    for (let i = 1; i <= Math.ceil(recordCount / pageSize); i++) {
        paginationNumber.push(i);
    }
    return (
        <div className='pagination'>
            {
                paginationNumber.map((data) => (
                    <Pagination>
                        <Pagination.Item key={data} onClick={() => handlePagination(data)} active={currentPage===data}>{data}</Pagination.Item>
                    </Pagination>
                    
                ))
            }
        </div>
    )
}
export default DataPagination