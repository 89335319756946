import CommonDataService from './commondataservice';
import axios from "axios";

class OrderedProductDataService extends CommonDataService{
    async getProductPriceRecords(product_id, token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view/price?product_id=${product_id}`, {timeout:10000});
    }

    async getOrderedProductsByProductId(token, productId){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view?product_id=${productId}`);
    }
}

const orderedProductDataService = new OrderedProductDataService('orderedproducts', 'orderedproduct');
export default orderedProductDataService;