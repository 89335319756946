import React, {useEffect, useState} from "react";
import moment from "moment";

class Utils{

    getBaseUrl = (env) =>{
        if(env === 'prod')
            return "http://digitalmist.cloud/";
        return "http://localhost:3000/";
    }

    formatCurrency = (value, locale="vn") => {
        if(locale === "us")
            return Intl.NumberFormat('en-US', {style : 'currency', currency : 'USD'}).format(value);
        else
            return Intl.NumberFormat('vi-VN', {style : 'currency', currency : 'VND'}).format(value);
    }

    formatDate = (value) =>{
        return moment(value).format('LL');
    }

    formatDatePicker = (value) => {
        return moment(value).format('yyyy-MM-DD')
    }

    formatDatePickerForSearch = (value) => {
        return moment(value).format('MM-DD-yyyy');
    }

    formatDateTime = (value) =>{
        return moment(value).format('LLL');
    }

    formatDateUndefined = (value, undefinedTerm="Chưa Xác Định") =>{
        return value?moment(value).format('LL'):undefinedTerm;
    }

    formatDateTimeUndefined = (value, undefinedTerm="Chưa Xác Định") =>{
        return value?moment(value).format('LLL'):undefinedTerm;
    }

    getInt = (value) => {
        if(value === null)
            return null;
        else if(value===0)
            return 0;
        else{
            // console.log('value:', value);
            return parseInt(Number(value), 10)?parseInt(Number(value)):parseInt(value.replace('.', ''));
        }
      // return value === null?null:(parseInt(value, 10)?value:(value===0?0:parseInt(value.replace('.', ''))));
    }

    parseVNCurrencyInput = (value) => {
        // console.log('value:', value);
        if(value === null)
            return 0;
        else if(value===0)
            return 0;
        else{
            // console.log('type of value:', typeof(value));
            if(typeof(value)==='string' && value.trim().length===0)
                return 0;
            else if(typeof(value)==='string'){
                return parseInt(value.replace(/\D/g, ""));
            }
            else{
                return value;
            }

        }
    }

    parseUSCurrencyInput = (value) => {
        // console.log('value:', value);
        if(value === null)
            return 0;
        else if(value===0)
            return 0;
        else{
            // console.log('type of value:', typeof(value));
            if(typeof(value)==='string' && value.trim().length===0)
                return 0;
            else if(typeof(value)==='string'){
                if(value.includes(','))
                    value = value.replace(',', '');
                if(value.includes('$'))
                    value = value.replace('$', '');
                return parseFloat(value);
            }
            else{
                return value;
            }

        }
    }

    checkTokenExpired = (token, tokenExpiredDate) => {
        // console.log('moment.now()', moment.now());
        // console.log(' moment(tokenExpiredDate)',  moment.unix(tokenExpiredDate).format('LLL'));
        // console.log('tokenExpiredDate',  tokenExpiredDate);
        return (token === null || token === "" || tokenExpiredDate === null || tokenExpiredDate === "" || moment.now() > moment.unix(tokenExpiredDate));
    }


}

const utils = new Utils();

// export default new UserDataService('users', 'user');
export default utils;