import React, {useState, useEffect} from "react";
import productDataService from '../services/products';
import {Link} from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Alert from 'react-bootstrap/Alert';
import DataPagination from "./pagination";
import ProductListTemplate from "./product-list-template";
import categoryDataService from "../services/categories";
import {InputGroup} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import {AutoComplete} from "primereact/autocomplete";
import {FaSearch} from "react-icons/fa";
import utils from "../services/utils";

const ProductList = (props) => {
    // return (
    //     <ProductListTemplate
    //     token={props.token}
    //     />
    // )
    let initialSearchProductTerm = '';
    let initialSearchVendorTerm = '';
    let initialSearchCategoryTerm = '';

    if(props.location.state && props.location.state.searchProductTerm){
        initialSearchProductTerm = props.location.state.searchProductTerm;

    }
    if(props.location.state && props.location.state.searchVendorTerm){
        initialSearchVendorTerm = props.location.state.searchVendorTerm;

    }
    if(props.location.state && props.location.state.searchCategoryTerm){
        initialSearchCategoryTerm = props.location.state.searchCategoryTerm;
    }

    const[products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordCount, setRecordCount] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(initialSearchCategoryTerm);
    const [filteredCategories, setFilteredCategories] = useState(null);
    const [categories, setCategories] = useState([]);
    const [productSearchTerm, setProductSearchTerm] = useState(initialSearchProductTerm);
    const [vendorSearchTerm, setVendorSearchTerm] = useState(initialSearchVendorTerm);
    const pageSize = 10;


    useEffect(() =>{
        retrieveCategories();
        if (productSearchTerm.trim().length !== 0 || vendorSearchTerm.trim().length !== 0 || selectedCategory !== null){
            retrieveSearchProducts();
        }
        else{
            retrieveProductsPagination(currentPage);
        }
    }, [props.token, currentPage]);


    const getCurrentPageInfo = () =>{
        if (productSearchTerm.trim().length !== 0 || vendorSearchTerm.trim().length !== 0 || selectedCategory !== null){
            retrieveSearchProducts();
        }
        else{
            retrieveProductsPagination(currentPage);
        }
    }


    const retrieveProductsPagination = (currentPage) =>{
        productDataService.getDataPagination(currentPage,props.token)
        .then(response => {
            setProducts(response.data.results);
            setRecordCount(response.data.count);
        })
        .catch(e=>{
            console.log(e);
        });
    }
    // const deleteProduct = (product) =>{
    //     productDataService.delete(product, props.token)
    //     .then(response => {
    //         retrieveProductsPagination();
    //     })
    //     .catch(e =>{
    //         console.log(e);
    //     });
    // }
    //
    const handlePagination = (currentPage) => {
        // console.log(recordCount/pageSize);
        // if(Math.ceil(recordCount/pageSize) < currentPage)
        //     setCurrentPage(1);
        setCurrentPage(currentPage);
    }

    const onChangeProductSearchTerm = e => {
        const searchTerm = e.target.value;
        setProductSearchTerm(searchTerm);
    }

    const onChangeVendorSearchTerm = e => {
        const searchTerm = e.target.value;
        setVendorSearchTerm(searchTerm);
    }

    const retrieveCategories = () => {
        categoryDataService.getAll(props.token)
            .then( r=>{
                // console.log(r.data);
                setCategories(r.data);
            })
            .catch(e=> console.log(e));
    }

    // const onClickSearch = () => {
    //     setCurrentPage(1);
    // }

    const searchCategory = (event) => {
        setTimeout(()=>{
            let _filteredCategories;
            const categoriesName = categories.map(category => category.name)
            if(!event.query.trim().length){

                _filteredCategories = [...categoriesName];
            }
            else{
                // console.log(event.query);
                _filteredCategories = categoriesName.filter((category) =>{
                    return category.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            setFilteredCategories(_filteredCategories);
        }, 250)
    }

    const retrieveSearchProducts = () => {
        if (productSearchTerm.trim().length !== 0 || vendorSearchTerm.trim().length !== 0 || selectedCategory !== null){
                productDataService.getSearchProductDataPagination(currentPage, props.token, productSearchTerm, vendorSearchTerm,selectedCategory)
                .then( r=> {
                    const data = r.data;
                    setProducts(data.results);
                    setRecordCount(data.count);
                })
                .catch(e => {
                    // console.log(e);
                    setCurrentPage(1);
                    productDataService.getSearchProductDataPagination(1, props.token, productSearchTerm, vendorSearchTerm,selectedCategory)
                        .then( r=> {
                            const data = r.data;
                            // console.log(data.results);
                            setProducts(data.results);
                            // console.log(data.count);
                            setRecordCount(data.count);
                            // console.log(data.count/pageSize);
                        })
                        .catch(e => console.log(e));
                });
            }
        else{
            retrieveProductsPagination(currentPage);
        }

    }
    //
    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <h2>Danh Sách Sản Phẩm</h2>
                        <Link to={"/products/create"}>
                            <Button variant="success" className="mb-3">
                            Thêm Sản Phẩm
                            </Button>
                        </Link>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Tên Sản Phẩm</InputGroup.Text>
                            <Form.Control
                                placeholder="Tên Sản Phẩm"
                                value={productSearchTerm}
                                onChange={onChangeProductSearchTerm}
                            />
                            <InputGroup.Text>Nhà Cung Cấp</InputGroup.Text>
                            <Form.Control
                                placeholder="Nhà Cung Cấp"
                                value={vendorSearchTerm}
                                onChange={onChangeVendorSearchTerm}
                            />
                            <InputGroup.Text>Phân Loại Sản Phẩm</InputGroup.Text>
                            <AutoComplete value={selectedCategory} suggestions={filteredCategories} completeMethod={searchCategory} onChange={(e) => setSelectedCategory(e.value)} dropdown />
                            <Button onClick={retrieveSearchProducts}><FaSearch/></Button>
                        </InputGroup>
                        {/*<InputGroup className="mb-3">*/}
                        {/*    <InputGroup.Text>Nhà Cung Cấp</InputGroup.Text>*/}
                        {/*    <Form.Control*/}
                        {/*        placeholder="Nhà Cung Cấp"*/}
                        {/*        value={vendorSearchTerm}*/}
                        {/*        onChange={onChangeVendorSearchTerm}*/}
                        {/*    />*/}
                        {/*</InputGroup>*/}
                        {/*<InputGroup className="mb-3">*/}
                        {/*    <InputGroup.Text>Phân Loại Sản Phẩm</InputGroup.Text>*/}
                        {/*    <AutoComplete value={selectedCategory} suggestions={filteredCategories} completeMethod={searchCategory} onChange={(e) => setSelectedCategory(e.value)} dropdown />*/}
                        {/*    <Button onClick={retrieveSearchProducts}><FaSearch/></Button>*/}
                        {/*</InputGroup>*/}
                        <DataPagination
                        pageSize={pageSize}
                        recordCount={recordCount}
                        handlePagination={handlePagination}
                        currentPage={currentPage}
                        />
                        <ProductListTemplate
                            token={props.token}
                            products={products}
                            getCurrentPageInfo={getCurrentPageInfo}
                            searchCategoryTerm={selectedCategory}
                            searchProductTerm={productSearchTerm}
                            searchVendorTerm={vendorSearchTerm}
                        />
                        {/*<Table bordered hover>*/}
                        {/*    <thead>*/}
                        {/*        <tr>*/}
                        {/*            <th>Tên Sản Phẩm</th>*/}
                        {/*            <th>Nhà Cung Cấp</th>*/}
                        {/*            <th>Tổng Số Từ Đơn Hàng</th>*/}
                        {/*            <th>Loại Sản Phẩm</th>*/}
                        {/*        </tr>*/}

                        {/*    </thead>*/}
                        {/*        {*/}
                        {/*            products.map((product) =>{*/}
                        {/*                return(*/}
                        {/*                    <tbody>*/}
                        {/*                        <tr key={product.product_id}>*/}
                        {/*                            <td><Link to ={{*/}
                        {/*                                    pathname:"/product/"+ product.product_id + "/view",*/}
                        {/*                                    state:{*/}
                        {/*                                        currentProduct:product*/}
                        {/*                                    }*/}
                        {/*                                }}>*/}
                        {/*                                {product.name}*/}
                        {/*                                </Link></td>*/}
                        {/*                            <td>{product.vendor_name}</td>*/}
                        {/*                            <td>{product.total_quantity_all_orders}</td>*/}
                        {/*                            <td>{product.product_type}</td>*/}
                        {/*                            <td>*/}
                        {/*                                <Link to ={{*/}
                        {/*                                    pathname:"/products/" + product.product_id,*/}
                        {/*                                    state:{*/}
                        {/*                                        currentProduct:product*/}
                        {/*                                    }*/}
                        {/*                                }}>*/}
                        {/*                                <Button variant="outline-info" className="me-2">Chỉnh Sửa</Button>*/}
                        {/*                                </Link>*/}
                        {/*                                <Button variant="outline-danger" onClick={() => deleteProduct(product.product_id)}>Xóa</Button>*/}
                        {/*                            </td>*/}
                        {/*                        </tr>*/}

                        {/*                    </tbody>*/}

                        {/*                )*/}
                        {/*            }*/}

                        {/*            )*/}
                        {/*        }*/}
                        {/*</Table>*/}
                    </div>
                )
            }

        </Container>
    )
}



export default ProductList;