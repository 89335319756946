import React, {useState, useEffect, useCallback, useRef} from "react";
import {Link} from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import DataPagination from "./pagination";
import {v4 as uuidv4} from 'uuid';
import { Toast } from 'primereact/toast';
import 'react-toastify/dist/ReactToastify.css'
import USShipmentListTemplate from "./us-shipment-template";
import {FaFloppyDisk, FaPlus, FaImages, FaXmark, FaArrowLeft, FaTrash, FaPencil, FaTruckFast, FaPlane} from "react-icons/fa6";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import usShipmentDataService from "../services/usshipments";
import utils from "../services/utils";
import shipmentDataService from "../services/shipments";


const USShipmentList = props => {
    const [newShipments, setNewShipments] = useState([]);
    const [inUSShipments, setInUSShipments] = useState([]);
    const [currentNewShipmentPage, setCurrentNewShipmentPage] = useState(1);
    const [recordNewShipmentCount, setRecordNewShipmentCount] = useState(0);
    const [currentInUSShipmentPage, setCurrentInUSShipmentPage] = useState(1);
    const [recordInUSShipmentCount, setRecordInUSShipmentCount] = useState(0);
    const pageSize = 10;
    const toast = useRef(null);

    useEffect(() =>{
        getCurrentPageInfo()
        // retrieveNewShipmentsPagination(currentNewShipmentPage);
    }, [props.token, currentNewShipmentPage, currentInUSShipmentPage]);

    const getCurrentPageInfo = () => {
        retrieveNewShipmentsPagination(currentNewShipmentPage);
        retrieveInUSShipmentsPagination(currentInUSShipmentPage);
    }


    const retrieveNewShipmentsPagination = (currentPage) =>{
        usShipmentDataService.getAllShipmentByStatusPagination(currentPage,props.token)
        .then(response => {
            setNewShipments(response.data.results);
            setRecordNewShipmentCount(response.data.count);
        })
        .catch(e=>{
            console.log(e);
        });
    }


    const retrieveInUSShipmentsPagination = (currentPage) =>{
        usShipmentDataService.getAllShipmentByStatusPagination(currentPage,props.token, 'us')
        .then(response => {
            setInUSShipments(response.data.results);
            setRecordInUSShipmentCount(response.data.count);
        })
        .catch(e=>{
            console.log(e);
        });
    }


    const createShippingLabel = () => {
        const shipment_us_id = uuidv4();

        let data = {
            shipment_us_id: shipment_us_id,
            label: "Đơn vận chuyển hàng qua Mỹ",
            created_by: localStorage.getItem('username'),
            last_modified_by: localStorage.getItem('username'),
            last_modified_date: moment()
        }
        usShipmentDataService.create(data, props.token)
            .then(response =>{
                shipmentDataService.getAllShipmentByStatus(props.token, 'vn')
                    .then(
                        r =>{
                            const allVNShipments = r.data;
                            // console.log('allVNShipments: ', allVNShipments);
                            // console.log('vnShipments', vnShipments);
                            const filtered_shipments = filterOutSelectedShipmentsFromAvailableShipments(allVNShipments);
                            // console.log('filter_shipments:', filtered_shipments);
                            if(filtered_shipments){
                                filtered_shipments.forEach((filtered_shipment)=>{
                                    const shipmentData = {
                                    shipment_us_id: shipment_us_id,
                                    last_modified_by: localStorage.getItem('username'),
                                    last_modified_date: moment()
                                    }
                                    shipmentDataService.update(filtered_shipment.shipment_id, shipmentData, props.token)
                                        .then(r=>{}
                                        )
                                        .catch(e => console.log(e));
                                })
                            }
                        }
                    )
                    .catch(e => console.log(e));
                toast.current.show({ severity: 'info', detail: "Tạo đơn vận chuyển qua Mỹ thành công!" });
                retrieveNewShipmentsPagination(currentNewShipmentPage);
                retrieveInUSShipmentsPagination(currentInUSShipmentPage);
            })
            .catch(e => {
                console.log(e);
            });
    }

    const filterOutSelectedShipmentsFromAvailableShipments = (shipments) => {
        if (shipments.length > 0)
            return shipments.filter(shipment => shipment.shipment_us_id === null);
    }

    const handleNewShipmentPagination = (currentPage) => {
        setCurrentNewShipmentPage(currentPage);
    }

    const handleInUSShipmentPagination = (currentPage) => {
        setCurrentInUSShipmentPage(currentPage);
    }
    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <Toast ref={toast} />
                        <h2>Danh Sách Đơn Vận Chuyển Qua Mỹ</h2>
                        {
                            <Button variant="success" className="mb-3" onClick={createShippingLabel}>
                            <FaPlane title="Tạo Đơn Vận Chuyển Qua Mỹ"/>&nbsp;Tạo Đơn Vận Chuyển Qua Mỹ
                            </Button>
                        }
                        {
                            props.role==="staff"||props.role==="admin"?(
                                <Tabs defaultActiveKey={0}>
                                    <Tab eventKey={0} title="Mới">
                                        <Tab.Content>
                                            <>
                                                <DataPagination
                                                pageSize={pageSize}
                                                recordCount={recordNewShipmentCount}
                                                handlePagination={handleNewShipmentPagination}
                                                currentPage={currentNewShipmentPage}
                                                />
                                                <USShipmentListTemplate
                                                token={props.token}
                                                shipments={newShipments}
                                                getCurrentPageInfo={getCurrentPageInfo}
                                            />
                                            </>
                                        </Tab.Content>
                                    </Tab>
                                    <Tab eventKey={2} title="Đã Nhận Ở Mỹ">
                                        <Tab.Content>
                                            <>
                                                <DataPagination
                                                pageSize={pageSize}
                                                recordCount={recordInUSShipmentCount}
                                                handlePagination={handleInUSShipmentPagination}
                                                currentPage={currentInUSShipmentPage}
                                                />
                                                <USShipmentListTemplate
                                                token={props.token}
                                                shipments={inUSShipments}
                                                getCurrentPageInfo={getCurrentPageInfo}
                                            />
                                            </>

                                        </Tab.Content>
                                    </Tab>
                                </Tabs>
                            ):""
                        }


                    </div>
                )
            }
        </Container>
    )
}



export default USShipmentList;