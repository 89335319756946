import React, {useState, useEffect, useCallback, useRef} from "react";
import orderDataService from '../services/orders';
import {Link} from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import DataPagination from "./pagination";
import OrderListTemplate from "./order-list-template";
import { ToastContainer, toast } from 'react-toastify';
import {FaPlus} from "react-icons/fa6";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import fairDataService from "../services/fairs";
import FairListTemplate from "./fair-list-template";
import {InputGroup} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {AutoComplete} from "primereact/autocomplete";
import {FaSearch} from "react-icons/fa";
import utils from "../services/utils";
import { Toast } from 'primereact/toast';


const FairList = props => {
    let isSearched = false;
    const toast = useRef(null);
    const [futureFairs, setFutureFairs] = useState([]);
    // const [searchedFairs, setSearchedFairs] = useState([]);
    const [currentFutureFairsPage, setCurrentFutureFairsPage] = useState(1);
    const [futureFairsCount, setFutureFairsCount] = useState(0);
    const [currentSearchedFairsPage, setCurrentSearchedFairsPage] = useState(1);
    // const [searchedFairsCount, setSearchedFairsCount] = useState(0);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchedFairName, setSearchedFairName] = useState("");
    const [isSearchState, setIsSearchState] = useState(isSearched);
    const pageSize = 10;
    // const toastCompleteNotification = () => toast("Chuyển đơn hàng sang \"Đã Hoàn Thành\" thành công!", {autoClose: 2000});
    // const toastIncompleteNotification = () => toast("Chuyển đơn hàng sang \"Chưa Hoàn Thành\" thành công!", {autoClose: 2000});
    // const toastDeleteNotification = () => toast("Xóa đơn hàng thành công!", {autoClose: 2000});

    useEffect(() =>{
        // retrievePastFairPagination(currentSearchedFairsPage);
        if(isSearchState){
            onClickSearch(currentFutureFairsPage);
        }
        else{
            retrieveFutureFairPagination(currentFutureFairsPage);
        }

    }, [props.token, currentFutureFairsPage, currentSearchedFairsPage]);


    const getCurrentPageInfo = () => {
        // retrievePastFairPagination(currentSearchedFairsPage);
        retrieveFutureFairPagination(currentFutureFairsPage);
    }


    // const retrievePastFairPagination = (currentPage) =>{
    //     fairDataService.getFairs(false, props.token)
    //     .then(r => {
    //         const pastFairs = r.data.results;
    //         console.log('searchedFairs:', pastFairs)
    //     })
    //     .catch(e=>{
    //         console.log(e);
    //     });
    // }

    const retrieveFutureFairPagination = (currentPage) =>{
        fairDataService.getFairs(currentPage,true, props.token)
        .then(r => {
            const futureFairs = r.data.results;
            // console.log('future Fairs:', futureFairs);
            setFutureFairs(futureFairs);
            setFutureFairsCount(r.data.count);
        })
        .catch(e=>{
            console.log(e);
        });
    }

    const onChangeFromDate = (e) => {
        const fromDate = e.target.value;
        setFromDate(fromDate);
    }

    const onChangeToDate = (e) => {
        const toDate = e.target.value;
        setToDate(toDate);
    }

    const onClickSearch = (currentPage) => {
        let start;
        let end;
        if(searchedFairName.trim().length>0){
            fairDataService.getFairsByName(searchedFairName, currentPage, props.token)
                .then(r=>{
                    const results = r.data.results;
                    setFutureFairs(results);
                    setFutureFairsCount(r.data.count);
                })
                .catch(e => console.log(e));
        }
        else{
            if(fromDate && toDate){
                start = utils.formatDatePickerForSearch(fromDate);
                end = utils.formatDatePickerForSearch(toDate);
            }
            else if(fromDate){
                start = utils.formatDatePickerForSearch(fromDate);
                end = "";
            }
            else if(toDate){
                start = "";
                end = utils.formatDatePickerForSearch(toDate);
            }
            else{
                toast.current.show({ severity: 'info', detail: "Bạn chưa nhập thông tin để tìm kiếm!" });
                return;
            }

            fairDataService.getFairsByDateRange(start, end, currentPage,props.token)
                .then(r => {
                    const results = r.data.results;
                    // console.log('results:', results);
                    setFutureFairs(results);
                    setFutureFairsCount(r.data.count);
                })
                .catch(e => console.log(e));
        }
        setIsSearchState(true);

    }

    // const retrieveCompleteOrdersPagination = (currentPage) =>{
    //     orderDataService.getAllIncompleteOrCompleteOrderPagination(currentPage,props.token, true)
    //     .then(response => {
    //         setPastFairs(response.data.results);
    //         setPastFairsCount(response.data.count);
    //     })
    //     .catch(e=>{
    //         console.log(e);
    //     });
    //
    // }
    // const deleteOrder = (orderId) =>{
    //     orderDataService.delete(orderId, props.token)
    //     .then(response => {
    //         toastDeleteNotification();
    //         retrievePastFairPagination(currentFutureFairsPage);
    //     })
    //     .catch(e =>{
    //         console.log(e);
    //     });
    // }

    // const filterIncompleteOrders = (orders) => {
    //     return orders.map(order => order.is_complete === false);
    // }
    //
    // const filterCompleteOrders = (orders) => {
    //     return orders.map(order => order.is_complete === true);
    // }

    // const completeOrder = (orderId, is_complete) => {
    //     orderDataService.complete(orderId, props.token)
    //     .then(response => {
    //         if(is_complete)
    //             toastIncompleteNotification();
    //         else
    //             toastCompleteNotification()
    //         retrievePastFairPagination(currentFutureFairsPage);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }

    const handleFutureFairPagination = (currentPage) => {
        setCurrentFutureFairsPage(currentPage);
    }

    const handleSearchedFairPagination = (currentPage) => {
        setCurrentSearchedFairsPage(currentPage);
    }

    const onChangeSearchedFairName = (e) => {
        const fairName = e.target.value;
        setSearchedFairName(fairName);
    }

    const clearSearch = () => {
        setFromDate("");
        setToDate("");
        setSearchedFairName("");
        setIsSearchState(false);
        getCurrentPageInfo();
    }
    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <h2>Danh Sách Hội Chợ</h2>
                        {
                            props.role === 'admin'?(
                                <Link to={"/fairs/create"}>
                                    <Button variant="success" className="mb-3">
                                    <FaPlus size="1.2em"/>&nbsp;Thêm Hội Chợ
                                    </Button>
                                </Link>
                            ):""
                        }
                        {/*{*/}
                        {/*    props.role === 'admin'?(*/}
                        <Container>
                            <Toast ref={toast} />
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Từ</InputGroup.Text>
                                <Form.Control
                                    type='date'
                                    placeholder="Từ"
                                    value={fromDate}
                                    onChange={onChangeFromDate}
                                />
                                <InputGroup.Text>Tới</InputGroup.Text>
                                <Form.Control
                                    type='date'
                                    placeholder="Tới"
                                    value={toDate}
                                    onChange={onChangeToDate}
                                />
                                <InputGroup.Text>Tên Hội Chợ</InputGroup.Text>
                                <Form.Control
                                    placeholder="Tên Hội Chợ"
                                    value={searchedFairName}
                                    onChange={onChangeSearchedFairName}
                                />
                                <Button onClick={e => onClickSearch(currentFutureFairsPage)}><FaSearch/></Button>
                            </InputGroup>
                            {
                                isSearchState?<Button onClick={clearSearch}>Xóa Tìm Kiếm</Button>:""
                            }
                            <DataPagination
                                pageSize={pageSize}
                                recordCount={futureFairsCount}
                                handlePagination={handleFutureFairPagination}
                                currentPage={currentFutureFairsPage}
                                />
                                <FairListTemplate
                                    fairs={futureFairs}
                                    token={props.token}
                                    getCurrentPageInfo={getCurrentPageInfo}
                                    role='admin'
                                />
                        </Container>
                        {/*<Tabs>*/}
                        {/*    <Tab eventKey="0" title="Hội Chợ Trong Tương Lai">*/}
                        {/*        <Tab.Content>*/}
                        {/*            <DataPagination*/}
                        {/*            pageSize={pageSize}*/}
                        {/*            recordCount={futureFairsCount}*/}
                        {/*            handlePagination={handleFutureFairPagination}*/}
                        {/*            currentPage={currentFutureFairsPage}*/}
                        {/*            />*/}
                        {/*            <FairListTemplate*/}
                        {/*                fairs={futureFairs}*/}
                        {/*                token={props.token}*/}
                        {/*                getCurrentPageInfo={getCurrentPageInfo}*/}
                        {/*                role='admin'*/}
                        {/*            />*/}
                        {/*        </Tab.Content>*/}

                        {/*    </Tab>*/}
                        {/*    <Tab eventKey="1" title="Tìm Kiếm">*/}
                        {/*        <Tab.Content>*/}
                        {/*            <InputGroup className="mb-3">*/}
                        {/*                <InputGroup.Text>Từ</InputGroup.Text>*/}
                        {/*                <Form.Control*/}
                        {/*                    type='date'*/}
                        {/*                    placeholder="Từ"*/}
                        {/*                    value={fromDate}*/}
                        {/*                    onChange={onChangeFromDate}*/}
                        {/*                />*/}
                        {/*                <InputGroup.Text>Tới</InputGroup.Text>*/}
                        {/*                <Form.Control*/}
                        {/*                    type='date'*/}
                        {/*                    placeholder="Tới"*/}
                        {/*                    value={toDate}*/}
                        {/*                    onChange={onChangeToDate}*/}
                        {/*                />*/}
                        {/*                <InputGroup.Text>Phân Loại Sản Phẩm</InputGroup.Text>*/}
                        {/*                <Button onClick={onClickSearch}><FaSearch/></Button>*/}
                        {/*            </InputGroup>*/}
                        {/*            <DataPagination*/}
                        {/*                pageSize={pageSize}*/}
                        {/*                recordCount={searchedFairsCount}*/}
                        {/*                handlePagination={handleSearchedFairPagination}*/}
                        {/*                currentPage={currentSearchedFairsPage}*/}
                        {/*            />*/}
                        {/*            <FairListTemplate*/}
                        {/*                fairs={searchedFairs}*/}
                        {/*                // completeOrder={completeOrder}*/}
                        {/*                // deleteOrder={deleteOrder}*/}
                        {/*                // token={props.token}*/}
                        {/*                getCurrentPageInfo={getCurrentPageInfo}*/}
                        {/*                role='admin'*/}
                        {/*                completeTab={true}*/}
                        {/*            />*/}

                        {/*        </Tab.Content>*/}

                        {/*    </Tab >*/}
                        {/*</Tabs>*/}
                        {/*    ):*/}
                        {/*    (*/}
                        {/*        <>*/}
                        {/*            <DataPagination*/}
                        {/*                pageSize={pageSize}*/}
                        {/*                recordCount={futureFairsCount}*/}
                        {/*                handlePagination={handleFutureFairPagination}*/}
                        {/*                currentPage={currentFutureFairsPage}*/}
                        {/*            />*/}
                        {/*            <OrderListTemplate*/}
                        {/*                orders={futureFairs}*/}
                        {/*                // completeOrder={completeOrder}*/}
                        {/*                // deleteOrder={deleteOrder}*/}
                        {/*                token={props.token}*/}
                        {/*                getCurrentPageInfo={getCurrentPageInfo}*/}
                        {/*                role='not admin'*/}
                        {/*                completeTab={false}*/}
                        {/*            />*/}
                        {/*        </>*/}


                        {/*    )*/}
                        {/*}*/}

                    </div>
                )
            }
        </Container>
    )
}



export default FairList;