import logo from './logo.svg';
import './App.css';
import React, {useEffect, useRef, useState} from 'react';
import {Switch, Route, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import AddOrder from './components/add-order';
import OrderList from './components/order-list';
import ViewOrder from './components/view-order';
import EditOrderedProduct from "./components/edit-ordered-product";
import AddProduct from './components/add-product';
import ProductList from './components/product-list';
import ViewProduct from "./components/view-product";
import VendorList from './components/vendor-list';
import AddVendor from './components/add-vendor';
import ViewVendor from './components/view-vendor';
import Login from './components/login';
import ShipmentList from "./components/shipment-list";
import ViewShipment from "./components/view-shipment";
import ViewShippedProduct from "./components/view-shipped-product";
import AddShipment from "./components/add-shipment-admin";
import CategoryList from "./components/category-list";
import EditCategory from "./components/view-category";
import AddPayment from "./components/add-payment";
import HomePage from "./components/home-page";
import USShipmentList from "./components/us-shipment-list";
import ViewUShipment from "./components/view-us-shipment";
import FairList from "./components/fair-list";
import AddFair from "./components/add-fair";
import ViewFair from "./components/view-fair";
import AddFairProducts from "./components/add-fair-products";
import UserList from "./components/user-list";
import AddUser from "./components/add-user";
import moment from 'moment';
import { Toast } from 'primereact/toast';

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Navbar'
import userDataService from './services/users';
import NavDropdown from 'react-bootstrap/NavDropdown';
import utils from '../src/services/utils';

import { Menubar } from 'primereact/menubar';


function App() {
  const [user, setUser] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [tokenExpiredDate, setTokenExpiredDate] = React.useState(null);
  const [role, setRole] = React.useState(null);
  const toast = useRef(null);

  async function login(user=null){
    userDataService.login(user).then(response => {
      setToken(response.data.token);
      setUser(user.username);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('username', user.username);
      // localStorage.setItem('tokenexpired', moment.unix(response.data.expiry_date).format('LLL'));
      localStorage.setItem('tokenexpired', response.data.expiry_date);
      // localStorage.setItem('role', response.data.role);
      // console.log(response.data.role);
      setRole(response.data.role);

    })
    .catch(e =>{
      // console.log('login', e);
      toast.current.show({ severity: 'error', detail: "Đăng Nhập không thành công!Hãy kiểm tra lại tên tài khoản và mật khẩu!" });
    });
  }

  useEffect(()=>{
    const loggedInUser = localStorage.getItem('username');
    const token = localStorage.getItem('token');
    const tokenExpiredDate = localStorage.getItem('tokenexpired');
    if(token && tokenExpiredDate && !utils.checkTokenExpired(token, tokenExpiredDate)){
      getUserRole(token);
    }
    // const role = localStorage.getItem('role');
    setToken(token);
    setUser(loggedInUser);
    // setRole(role);
    setTokenExpiredDate(tokenExpiredDate);

  },[])
  async function logout(){
    setToken('');
    setUser('');
    setRole('');
    setTokenExpiredDate('');
    localStorage.setItem('token', '');
    localStorage.setItem('username', '');
    // localStorage.setItem('role', '')
    localStorage.setItem('tokenexpired', '');

    // localStorage.setItem('lastlogindate', '');
  }

  const getUserRole = (token) => {
    userDataService.getUserRole(token)
        .then(r=>{
          const role = r.data;
          if(role){
            const groupId = role[0].groups;
            // console.log('group', groupId[0]);
            userDataService.getGroups(token)
            .then(r=>{
                // console.log('groups:',r.data);
                const groups = r.data;
                const role = groups.filter(group => group.id === groupId[0]);
                // console.log('role:',role[0].name);
                setRole(role[0].name);
            })
            .catch(e=> console.log(e));

          }
          // console.log(role);
          // setRole(role);
        })
        .catch(e=>console.log(e));
  }

  // async function signup(user=null){
  //   OrderDataService.signup(user)
  //   .then(response => {
  //     setToken(response.data.token);
  //     setUser(user.username);
  //     localStorage.setItem('token', response.data.token);   
  //     localStorage.setItem('username', user.username);
  //   })
  //   .catch(e =>{
  //     console.log(e);
  //     setError(e.toString());bg="primary" variant="dark"
  //   })
  // }
  return (
    <div className="App">
      <Toast ref={toast} />
      <Navbar  bg="success" data-bs-theme="dark">
        <div className='container-fluid'>
          <Navbar.Brand>Linh Phan</Navbar.Brand>
          <Nav className='me-auto' variant="pills">

              {
                role==='admin'?(
                        <Container>
                          <NavDropdown title="Quản Lý" className="font-semibold" >
                            <NavDropdown.Item>
                               <Link class="nav-link" to={"/fairs"}><b>Hội Chợ</b></Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item bg="success">
                              <Link class="nav-link" to={"/vendors"} ><b>Nhà Cung Cấp</b></Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                               <Link class="nav-link" to={"/products"}><b>Sản Phẩm</b></Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                               <Link class="nav-link" to={"/orders"}><b>Đơn Hàng</b></Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                               <Link class="nav-link" to={"/categories"}><b>Phân Loại Sản Phẩm</b></Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                               <Link class="nav-link" to={"/users"}><b>Người Dùng</b></Link>
                            </NavDropdown.Item>

                          </NavDropdown>
                          <NavDropdown title="Vận Chuyển">
                            <NavDropdown.Item>
                              <Link class="nav-link" to={"/shipments"} ><b>Giao Hàng Tại Việt Nam</b></Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                              <Link class="nav-link" to={"/usshipments"} ><b>Vận Chuyển Qua Mỹ</b></Link>
                            </NavDropdown.Item>
                          </NavDropdown>
                          {/*<Link class="nav-link" to={"/vendors"} ><b>Nhà Cung Cấp</b></Link>*/}
                          {/*<Link class="nav-link" to={"/products"}><b>Sản Phẩm</b></Link>*/}
                          {/*<Link class="nav-link" to={"/orders"}><b>Đơn Hàng</b></Link>*/}
                          {/*<Link class="nav-link" to={"/shipments"}><b>Giao Hàng</b></Link>*/}
                          {/*<Link class="nav-link" to={"/usshipments"}><b>Vận Chuyển Qua Mỹ</b></Link>*/}
                          {/*<Link class="nav-link" to={"/categories"}><b>Phân Loại Sản Phẩm</b></Link>*/}
                          {/*<NavLink class="nav-link" to={"/vendors"}>Nhà Cung Cấp</NavLink >*/}
                          {/*<NavLink class="nav-link" to={"/products"}>Sản Phẩm</NavLink >*/}
                          {/*<NavLink class="nav-link" to={"/orders"}>Đơn Hàng</NavLink >*/}
                          {/*<NavLink class="nav-link" to={"/shipments"}>Giao Hàng</NavLink >*/}
                          {/*<NavLink class="nav-link" to={"/categories"}>Phân Loại Sản Phẩm</NavLink >*/}
                          </Container>
                ):( role==='vendor'?
                    (<Container>
                      <Link class="nav-link" to={"/orders"}><b>Đơn Hàng</b></Link>
                      {/*<Link class="nav-link" to={"/shipments"}><b>Giao Hàng</b></Link>*/}
                    </Container>):
                        (
                            <Link class="nav-link" to={"/shipments"}><b>Giao Hàng</b></Link>
                        )
                )
              }

          </Nav >
          <Nav>
          {user?(
                  <Link class="nav-link" className=" navbar-right" to="" onClick={logout}>{user} - Đăng Xuất</Link>
                ):(
                  <>
                  <Link class="nav-link" className="navbar-right" to={"/login"}>Đăng Nhập</Link>
                  </>
                )
              }

          </Nav>

        </div>
      </Navbar>
      <div className='container mt-4'>
        <Switch>
          <Route exact path={["/"]} render={
            (props)=><HomePage{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route exact path={["/orders"]} render={
            (props)=><OrderList{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path="/orders/create" render={
            (props)=><AddOrder{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path="/orders/:id" render={
            (props)=><AddOrder{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path="/order/:id/view" render={
            (props)=><ViewOrder{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path="/orderedproducts/:id" render={
            (props)=><EditOrderedProduct{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route exact path={["/vendors"]} render={
            (props)=><VendorList{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path="/vendors/create" render={
            (props)=><AddVendor{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path="/vendors/:id" render={
            (props)=><AddVendor{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path="/vendor/:id/view" render={
            (props)=><ViewVendor{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          
          <Route exact path={["/products"]} render={
            (props)=><ProductList{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path="/products/create" render={
            (props)=><AddProduct{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path="/products/:id" render={
            (props)=><AddProduct{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path="/product/:id/view" render={
            (props)=><ViewProduct{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>

          <Route exact path={["/shipments"]} render={
            (props)=><ShipmentList{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path={["/shipment/:id/view"]} render={
            (props)=><ViewShipment{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path={["/shipments/create"]} render={
            (props)=><AddShipment{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route exact path={["/categories"]} render={
            (props)=><CategoryList{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path="/category/:id/view" render={
            (props)=><EditCategory{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>

          <Route exact path={["/usshipments"]} render={
            (props)=><USShipmentList{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>

          <Route path={["/usshipment/:id/view"]} render={
            (props)=><ViewUShipment{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>


          <Route path={["/shippedproduct/:id/view"]} render={
            (props)=><ViewShippedProduct{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>


          <Route path="/payments/create" render={
            (props)=><AddPayment{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path="/payments/:id" render={
            (props)=><AddPayment{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>

          <Route exact path={["/fairs"]} render={
            (props)=><FairList{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path="/fairs/create" render={
            (props)=><AddFair{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
           <Route path="/fairs/:id" render={
            (props)=><AddFair{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
           <Route path="/fair/:id/view" render={
            (props)=><ViewFair{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path="/fairproducts/:id" render={
            (props)=><AddFairProducts{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>

          <Route exact path={["/users"]} render={
            (props)=><UserList{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>

          <Route path="/users/:id" render={
            (props)=><AddUser{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>
          <Route path="/users/create" render={
            (props)=><AddUser{...props} token={token} role={role} tokenExpiredDate={tokenExpiredDate}/>
          }></Route>

          <Route path="/login" render={
            (props)=><Login{...props} login={login}/>
          }></Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
