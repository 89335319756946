import React, {useRef} from 'react'
import {Link} from "react-router-dom";
import moment from "moment/moment";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup'; // To use confirmPopup method
import "primereact/resources/themes/lara-light-blue/theme.css";
import {FaFloppyDisk, FaPlus, FaPencil, FaTrash, FaCheck, FaSpinner, FaTrashCan} from "react-icons/fa6";
import shipmentDataService from "../services/shipments";
import shippedProductDataService from "../services/shippedproducts";
import { Toast } from 'primereact/toast';
import Container from "react-bootstrap/Container";
import shippedProductPhotoDataService from "../services/shippedproductphotos";
import paymentDataService from "../services/payments";
import paymentPhotoDataService from "../services/paymentphotos";


const ShipmentListTemplate = ({token, role, shipments, getCurrentPageInfo, fromOrder, orderId, tabEventKey}) => {
    const toast = useRef(null);

    const handleDeleteShipment = (event, shipmentId) =>{
        confirmPopup({
            target: event.currentTarget,
            message: 'Bạn có chắc là bạn muốn xóa đơn giao hàng này chứ?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteShipment(shipmentId),
            acceptLabel: "Đồng Ý",
            rejectLabel: "Không"

        })
    }
    const deleteShipment = (shipmentId) =>{
        shipmentDataService.delete(shipmentId, token)
        .then(response => {
            shippedProductDataService.getAll(token, shipmentId)
                .then( r=>{
                   const shippedProducts = r.data;
                   shippedProducts.forEach((shippedProduct) =>{
                       shippedProductDataService.delete(shippedProduct.sp_id, token)
                           .then(r=>{

                           })
                           .catch(e => console.log(e));
                   })
                }
                ).catch(e=> console.log(e));
            shippedProductPhotoDataService.getAllShipmentPhotos(token, shipmentId)
                .then( r=>{
                    const shippedProductPhotos = r.data;
                    shippedProductPhotos.forEach( (shippedProductPhoto) => {
                        shippedProductPhotoDataService.delete(shippedProductPhoto.sp_photo_id, token)
                            .then(r=>{

                            })
                            .catch(e => console.log(e));
                    })
                })
            getCurrentPageInfo();
            paymentDataService.getAllPaymentsForShipment(token, shipmentId)
                .then( r=>{
                    const payments = r.data;
                    payments.forEach((payment) => {
                        paymentDataService.delete(payment.payment_id, token)
                            .then( r=>{
                                 paymentPhotoDataService.getAllPaymentProductPhotos(token, payment.payment_id)
                                    .then( r=>{
                                        const paymentPhotos = r.data;
                                        paymentPhotos.forEach((paymentPhoto) => {
                                            paymentPhotoDataService.delete(paymentPhoto.payment_photo_id, token)
                                                .then(e=>{

                                                })
                                                .catch(e=> console.log(e));
                                        })
                                        }
                                    )
                                    .catch(e => console.log(e));

                            })
                            .catch( e=> console.log(e));
                    })
                }).catch( e=> console.log(e));
            toast.current.show({ severity: 'info', detail: "Xóa đơn giao hàng thành công!" });
        })
        .catch(e =>{
            console.log(e);
        });
    }

    return(
        <Container>
            <Toast ref={toast} />
            <ConfirmPopup />
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Label</th>
                        <th>Nhà Cung Cấp</th>
                        <th>Trạng Thái</th>
                        <th>Ngày Tạo</th>
                        <th></th>
                    </tr>

                </thead>
                    {
                        shipments.map((shipment) =>{
                            return(
                                <tbody>
                                    <tr key={shipment.shipment_id}>
                                        <td><Link to ={{
                                                pathname:"/shipment/"+ shipment.shipment_id + "/view",
                                                state:{
                                                    currentShipment:shipment,
                                                    fromOrder: fromOrder,
                                                    shipmentPageEventKey: tabEventKey,
                                                    orderId: orderId
                                                }
                                            }}>
                                            {shipment.label}
                                            </Link></td>
                                        <td>{shipment.vendor_name}</td>
                                        <td >{
                                                        shipment.is_delivered_us?(role==='admin' || role ==='staff'?<Button disabled variant="success">Đã Nhận Ở Mỹ</Button>:<Button disabled variant="success">Đã Nhận</Button>):(
                                                            shipment.is_delivered_vn?(role==='admin' || role ==='staff'?<Button disabled variant="success">Đã Nhận Ở Việt Nam</Button>: <Button disabled variant="success">Đã Nhận</Button>):
                                                                <Button disabled variant="success">Mới</Button>
                                                        )
                                            }</td>
                                        <td >{moment(shipment.created_date).format('LLL')}</td>
                                        <td>
                                            {
                                                role!== 'staff'?(shipment.is_delivered_vn?"":<Button variant="outline-danger" onClick={(e) => handleDeleteShipment(e, shipment.shipment_id)}><FaTrashCan/></Button>):""
                                            }


                                        </td>
                                    </tr>
                                </tbody>
                            )
                        }

                        )
                    }
            </Table>
        </Container>

    )

}

export default ShipmentListTemplate