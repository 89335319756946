import React, {useEffect, useState} from "react";
import orderedProductDataService from "../services/orderedproducts";
import orderDataService from '../services/orders'
import vendorDataService from '../services/vendors';
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import utils from "../services/utils";

const EditOrderedProduct = props => {
    let initialOrderedProduct = "";
    let initialOrder = "";
    let initialNote ="";
    let initialOPPrice = "";
    let initialOPCost = 0;
    let initialQuantity = 0;
    let initialOPProductId = "";
    let initialOPProductName = "";
    if(props.location.state && props.location.state.currentOrderedProduct){
        initialOrder = props.location.state.currentOrder;
        initialNote = props.location.state.currentOrderedProduct.notes;
        initialOPPrice = props.location.state.currentOrderedProduct.ordered_product_price;
        initialOPCost = props.location.state.currentOrderedProduct.price;
        console.log(initialOPCost);
        initialQuantity = props.location.state.currentOrderedProduct.quantity;
        initialOPProductId = props.location.state.currentOrderedProduct.product_id;
        initialOPProductName = props.location.state.currentOrderedProduct.product_name;
    }

    const [note, setNote] = useState(initialNote);
    const [orderedProductCost, setOrderedProductCost] = useState(initialOPCost);
    const [orderedProductQuantity, setOrderedProductQuantity] = useState(initialQuantity);
    const [validated, setValidated] = useState(false);

    const onChangeQuantity = e =>{
        const quantity = e.target.value;
        setOrderedProductQuantity(quantity);
        setOrderedProductCost(quantity*initialOPPrice);
    }

    const onChangeCost = e =>{
        const cost = e.target.value;
        setOrderedProductCost(cost);
    }

    const onChangeNote = e =>{
        const note = e.target.value;
        setNote(note);
    }

    const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    saveOrderedProduct();
  };


    const saveOrderedProduct = () => {
        var data = {
            price: orderedProductCost===parseInt(orderedProductCost, 10)?orderedProductCost:parseInt(orderedProductCost.replace('.', '')),
            notes: note,
            product_id: initialOPProductId,
            order_id: initialOrderedProduct.order_id,
            quantity: orderedProductQuantity,
            last_modified_by: localStorage.getItem('username'),
            last_modified_date: moment(),
        }
        orderedProductDataService.update(props.location.state.currentOrderedProduct.op_id, data, props.token).then(response =>{
        }).catch(e =>{
            console.log(e);
        });
    }

    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <Form noValidate validated={validated}>
                        <Form.Group as={Col} controlId="productValidation">
                            <Form.Label>Sản Phẩm</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                disabled
                                value={initialOPProductName}
                            />
                            <Form.Control.Feedback type="invalid">
                                Nhập sản phẩm
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="priceValidation">
                            <Form.Label>Đơn Giá</Form.Label>
                            <Form.Control
                                type="numeric"
                                required
                                disabled
                                value={Intl.NumberFormat('vi-VN', {style : 'currency', currency : 'VND'}).format(initialOPPrice)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Nhập giá cho sản phẩm
                              </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="quantityValidation">
                            <Form.Label>Số Lượng</Form.Label>
                             <Form.Control
                                type="number"
                                required
                                isInvalid={!parseInt(orderedProductQuantity) > 0}
                                value={orderedProductQuantity}
                                onChange={onChangeQuantity}
                            />
                            <Form.Control.Feedback type="invalid">
                                Nhập số lượng sản phẩm. Nếu số lượng là 0. Hãy double check.
                              </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="productCostValidation">
                            <Form.Label>Thành Tiền</Form.Label>
                             <Form.Control
                                type="number"
                                required
                                isInvalid={orderedProductCost===''}
                                value={orderedProductCost}
                                onChange={onChangeCost}
                            />
                            <Form.Control.Feedback type="invalid">
                                Nhập giá tiền cuối cùng
                              </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Ghi Chú</Form.Label>
                             <Form.Control
                                as='textarea'
                                rows={3}
                                placeholder="Nhập ghi chú"
                                value={note}
                                onChange={onChangeNote}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Link to ={{
                                pathname:"/order/"+ initialOrder.order_id + "/view",
                                state:{
                                    currentOrder:initialOrder
                                }
                                }}>
                            <Button variant="primary" className="me-2"><b>&#8592;</b></Button>
                            </Link>
                            <Link to ={{
                                pathname:"/order/"+ initialOrder.order_id + "/view",
                                state:{
                                    currentOrder:initialOrder
                                }
                            }}>
                            <Button variant="outline-warning" onClick={handleSubmit}>Cập Nhật</Button>
                            </Link>
                        </Form.Group>

                </Form>

                )
            }
        </Container>
    )

}

export default EditOrderedProduct;