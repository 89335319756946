import CommonDataService from './commondataservice';
import axios from "axios";

class ShipmentDataService extends CommonDataService{
    async getShipmentsById(token,shipmentIds){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view?shipment_ids=${shipmentIds}`)
    }

    async deliver(id, location, token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        if(location === 'vn')
            return await axios.put(this.baseURL+`${this.objectName}/${id}/delivered/vn`);
        if(location === 'us')
            return await axios.put(this.baseURL+`${this.objectName}/${id}/delivered/us`);
    }

    async notify(id, token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.put(this.baseURL+`${this.objectName}/${id}/notification`);
    }

    async getAllShipmentByStatusPagination(pageNumber,token, status='new'){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view/all?p=${pageNumber}&status=${status}`, {timeout:5000});
    }

    async getAllShipmentByStatus(token, status='new'){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view?status=${status}`, {timeout:5000});
    }

    async getAllShipmentByUSShipmentId(shipmentUsId, token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view?us_shipment_id=${shipmentUsId}`)
    }

}

const shipmentDataService = new ShipmentDataService('shipments', 'shipment');
export default shipmentDataService;