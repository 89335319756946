import React, {useState, useEffect, useCallback} from "react";
import orderDataService from '../services/orders';
import {Link} from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import DataPagination from "./pagination";
import OrderListTemplate from "./order-list-template";
import { ToastContainer, toast } from 'react-toastify';
import {FaPlus} from "react-icons/fa6";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import utils from "../services/utils";


const OrderList = props => {
    const[incompleteOrders, setIncompleteOrders] = useState([]);
    const [completeOrders, setCompleteOrders] = useState([]);
    const [currentIncompletePage, setCurrentIncompletePage] = useState(1);
    const [incompleteOrdersCount, setIncompleteOrdersCount] = useState(0);
    const [currentCompletePage, setCurrentCompletePage] = useState(1);
    const [completeOrdersCount, setCompleteOrdersCount] = useState(0);
    const pageSize = 10;
    // const toastCompleteNotification = () => toast("Chuyển đơn hàng sang \"Đã Hoàn Thành\" thành công!", {autoClose: 2000});
    // const toastIncompleteNotification = () => toast("Chuyển đơn hàng sang \"Chưa Hoàn Thành\" thành công!", {autoClose: 2000});
    // const toastDeleteNotification = () => toast("Xóa đơn hàng thành công!", {autoClose: 2000});

    useEffect(() =>{
        retrieveIncompleteOrdersPagination(currentIncompletePage);
        if (props.role === 'admin')
            retrieveCompleteOrdersPagination(currentCompletePage);
    }, [props.token, currentIncompletePage, currentCompletePage]);


    const getCurrentPageInfo = () => {
        retrieveIncompleteOrdersPagination(currentIncompletePage);
        if (props.role === 'admin')
            retrieveCompleteOrdersPagination(currentCompletePage);
    }


    const retrieveIncompleteOrdersPagination = (currentPage) =>{
        orderDataService.getAllIncompleteOrCompleteOrderPagination(currentPage,props.token)
        .then(response => {
            setIncompleteOrders(response.data.results);
            setIncompleteOrdersCount(response.data.count);
        })
        .catch(e=>{
            console.log(e);
        });
    }

    const retrieveCompleteOrdersPagination = (currentPage) =>{
        orderDataService.getAllIncompleteOrCompleteOrderPagination(currentPage,props.token, true)
        .then(response => {
            setCompleteOrders(response.data.results);
            setCompleteOrdersCount(response.data.count);
        })
        .catch(e=>{
            console.log(e);
        });

    }
    // const deleteOrder = (orderId) =>{
    //     orderDataService.delete(orderId, props.token)
    //     .then(response => {
    //         toastDeleteNotification();
    //         retrieveIncompleteOrdersPagination(currentIncompletePage);
    //     })
    //     .catch(e =>{
    //         console.log(e);
    //     });
    // }

    // const filterIncompleteOrders = (orders) => {
    //     return orders.map(order => order.is_complete === false);
    // }
    //
    // const filterCompleteOrders = (orders) => {
    //     return orders.map(order => order.is_complete === true);
    // }

    // const completeOrder = (orderId, is_complete) => {
    //     orderDataService.complete(orderId, props.token)
    //     .then(response => {
    //         if(is_complete)
    //             toastIncompleteNotification();
    //         else
    //             toastCompleteNotification()
    //         retrieveIncompleteOrdersPagination(currentIncompletePage);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }

    const handleIncompletePagination = (currentPage) => {
        setCurrentIncompletePage(currentPage);
    }

    const handleCompletePagination = (currentPage) => {
        setCurrentCompletePage(currentPage);
    }
    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <h2>Danh Sách Đơn Hàng</h2>
                        {
                            props.role === 'admin'?(
                                <Link to={"/orders/create"}>
                                    <Button variant="success" className="mb-3">
                                    <FaPlus size="1.2em"/>&nbsp;Thêm Đơn Hàng
                                    </Button>
                                </Link>
                            ):""
                        }
                        {
                            props.role === 'admin'?(
                                <Tabs>
                                    <Tab eventKey="incompleteOrders" title="Chưa Hoàn Thành">
                                        <Tab.Content>
                                            <DataPagination
                                            pageSize={pageSize}
                                            recordCount={incompleteOrdersCount}
                                            handlePagination={handleIncompletePagination}
                                            currentPage={currentIncompletePage}
                                            />
                                            <OrderListTemplate
                                                orders={incompleteOrders}
                                                token={props.token}
                                                getCurrentPageInfo={getCurrentPageInfo}
                                                // completeOrder={completeOrder}
                                                // deleteOrder={deleteOrder}
                                                role='admin'
                                                completeTab={false}
                                            />

                                        </Tab.Content>

                                    </Tab>
                                    <Tab eventKey="completeOrders" title="Đã Hoàn Thành">
                                        <Tab.Content>
                                            <DataPagination
                                                pageSize={pageSize}
                                                recordCount={completeOrdersCount}
                                                handlePagination={handleCompletePagination}
                                                currentPage={currentCompletePage}
                                            />
                                            <OrderListTemplate
                                                orders={completeOrders}
                                                // completeOrder={completeOrder}
                                                // deleteOrder={deleteOrder}
                                                token={props.token}
                                                getCurrentPageInfo={getCurrentPageInfo}
                                                role='admin'
                                                completeTab={true}
                                            />

                                        </Tab.Content>

                                    </Tab >
                                </Tabs>
                            ):
                            (
                                <>
                                    <DataPagination
                                        pageSize={pageSize}
                                        recordCount={incompleteOrdersCount}
                                        handlePagination={handleIncompletePagination}
                                        currentPage={currentIncompletePage}
                                    />
                                    <OrderListTemplate
                                        orders={incompleteOrders}
                                        // completeOrder={completeOrder}
                                        // deleteOrder={deleteOrder}
                                        token={props.token}
                                        getCurrentPageInfo={getCurrentPageInfo}
                                        role='not admin'
                                        completeTab={false}
                                    />
                                </>


                            )
                        }


                        {/*<Table bordered hover>*/}
                        {/*    <thead>*/}
                        {/*        <tr>*/}
                        {/*            <th>Tên Đơn Hàng</th>*/}
                        {/*            <th>Nhà Cung Cấp</th>*/}
                        {/*            <th>Ngày Xác Nhận</th>*/}
                        {/*            <th>Ngày Dự Kiến Hoàn Thành</th>*/}
                        {/*        </tr>*/}
                        {/*        */}
                        {/*    </thead>*/}
                        {/*        {*/}
                        {/*            orders.map((order) =>{*/}
                        {/*                return(*/}
                        {/*                    <tbody>*/}
                        {/*                        <tr key={order.order_id} className={`${order.is_complete?"text-decoration-line-through":""}`}>*/}
                        {/*                            <td><Link to ={{*/}
                        {/*                                    pathname:"/order/"+ order.order_id + "/view",*/}
                        {/*                                    state:{*/}
                        {/*                                        currentOrder:order*/}
                        {/*                                    }*/}
                        {/*                                }}>*/}
                        {/*                                {order.name}*/}
                        {/*                                </Link></td>*/}
                        {/*                            <td>{order.vendor_name}</td>*/}
                        {/*                            <td>{order.confirmed_date?moment(order.confirmed_date).format("LLL"):"Chưa Xác Nhận"}</td>*/}
                        {/*                            <td >{order.estimated_date?moment(order.estimated_date).format("LL"):"Chưa Xác Định"}</td>*/}
                        {/*                            <td>*/}
                        {/*                                <Link to ={{*/}
                        {/*                                    pathname:"/orders/" + order.order_id,*/}
                        {/*                                    state:{*/}
                        {/*                                        currentOrder:order*/}
                        {/*                                    }*/}
                        {/*                                }}>*/}
                        {/*                                <Button variant="outline-info" className="me-2">Chỉnh Sửa</Button>*/}
                        {/*                                </Link>*/}
                        {/*                                <Button variant="outline-success" onClick={() => completeOrder(order.order_id)}>{order.is_complete?"Mark As Incomplete":"Mark As Completed"}</Button>*/}
                        {/*                                <Button variant="outline-danger" onClick={() => deleteOrder(order.order_id)}>Xóa</Button>*/}
                        {/*                            </td>*/}
                        {/*                        </tr>*/}
                        {/*                    </tbody>*/}
                        {/*                )*/}
                        {/*            }*/}

                        {/*            )*/}
                        {/*        }*/}
                        {/*</Table>*/}

                    </div>
                )
            }
        </Container>
    )
}



export default OrderList;