import axios from "axios";
import CommonDataService from "./commondataservice";

class UserDataService extends CommonDataService{
    login(data){
        return axios.post(this.baseURL+'login/', data);
    }

    signup(data){
        return axios.post(this.baseURL+'signup/', data);
    }

    async searchUserDataPagination(pageNumber,token, name){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view/all?p=${pageNumber}&name=${name}`, {timeout:5000});
    }

    async getGroups(token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`groups/view`, {timeout:5000});
    }
    async getUserRole(token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectName}/role`, {timeout:5000});
    }

}

const userDataService = new UserDataService('users', 'user');

// export default new UserDataService('users', 'user');
export default userDataService;