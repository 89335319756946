import CommonDataService from './commondataservice';
import axios from "axios";

class FairDataService extends CommonDataService{
    async getFairsByName(name, currentPage, token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view/all?p=${currentPage}&name=${name}`, {timeout:10000});
    }

    async getFairsByDateRange(start, end, currentPage, token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view/all?p=${currentPage}&start=${start}&end=${end}`, {timeout:10000});
    }

    async getFairs(currentPage, isFuture=true, token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view/all?p=${currentPage}&isFuture=${isFuture}`, {timeout:10000});
    }
}

const fairDataService = new FairDataService('fairs', 'fair');
export default fairDataService;