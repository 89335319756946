import React, {useEffect, useState} from "react";
import productDataService from "../services/products";
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import CommonImageUploadHandler from "./common-image-upload-handler";
import CommonImageDisplayHandler from "./common-image-display-handler";
import utils from "../services/utils";
import { Tree } from 'primereact/tree';
import { Tag } from 'primereact/tag';
import { DataTable } from 'primereact/datatable';

import {Tab, Tabs} from "react-bootstrap";
import orderedProductDataService from "../services/orderedproducts";
import { Column } from 'primereact/column';
import salesTransactionDataService from "../services/salestransaction";
import fairProductDataService from "../services/fairproducts";



const ViewProduct = props => {
    let initialProductId = "";
    let isUploadPhoto = false;
    let initialSearchProductTerm = '';
    let initialSearchVendorTerm = '';
    let initialSearchCategoryTerm = '';

    if(props.location.state && props.location.state.currentProduct){
        initialProductId = props.location.state.currentProduct.product_id;
    }
    else{
        initialProductId = props.location.pathname.replace('/product/', '').replace('/view', '');
    }

    if(props.location.state && props.location.state.searchProductTerm){
        initialSearchProductTerm = props.location.state.searchProductTerm;

    }
    if(props.location.state && props.location.state.searchVendorTerm){
        initialSearchVendorTerm = props.location.state.searchVendorTerm;

    }
    if(props.location.state && props.location.state.searchCategoryTerm){
        initialSearchCategoryTerm = props.location.state.searchCategoryTerm;
    }

    const [product, setProduct] = useState('');
    const [isUploadPhotos, setIsUploadPhotos] = useState(isUploadPhoto);
    const [quantityData, setQuantityData] = useState([]);
    const [averagePrice, setAveragePrice] = useState(0);
    const [priceSeverity, setPriceSeverity] = useState("success");
    const [priceHistory, setPriceHistory] = useState([]);
    const [fairHistory, setFairHistory] = useState([]);
    const [totalShippingCost, setTotalShippingCost] =useState(0);
    const [averageShippingCost, setAvarageShippingCost] = useState(0);
    const [orderedProductHistory, setOrderedProductHistory] = useState([]);
    const [salesTransactions, setSalesTransactions] = useState([]);

    useEffect(() =>{
        retrieveProduct(initialProductId);
        retrievePriceHistory(initialProductId);
        retrieveOrderedProductHistory(initialProductId);
        retrieveSalesTransactions(initialProductId);
        retrieveFairHistory(initialProductId);
    }, [props.token]);


    const retrieveProduct = (product_id) =>{
        productDataService.getOne(product_id,props.token)
        .then(response => {
            const product = response.data;
            setProduct(product);
            const testData = productDataService.transformToNote(product);
            setQuantityData(testData);
            const averagePrice = product.total_quantity_all_orders>0?product.total_order_cost/product.total_quantity_all_orders:0
            setAveragePrice(averagePrice);
            const totalShippingCost = product.total_shipping_cost;
            setTotalShippingCost(totalShippingCost);
            const averagePShippingCost= product.total_quantity_all_orders>0?product.total_shipping_cost/product.total_quantity_all_orders:0;
            setAvarageShippingCost(averagePShippingCost);
            if(averagePrice >= product.price){
                setPriceSeverity("success");
            }
            else
                setPriceSeverity("warning");

        })
        .catch(e=>{
            console.log(e);
        });
    }

    const retrievePriceHistory = (productId) => {
        orderedProductDataService.getProductPriceRecords(productId, props.token)
            .then(r => {
                const data = r.data;
                // console.log("price history", data);
                setPriceHistory(data);
            })
            .catch(e => console.log(e));
    }

    const retrieveFairHistory = (productId) => {
        fairProductDataService.getFairProductsByProduct(productId, props.token)
            .then(r => {
                const data = r.data;
                // console.log("price history", data);
                setFairHistory(data);
            })
            .catch(e => console.log(e));
    }

    const retrieveOrderedProductHistory = (productId) => {
        orderedProductDataService.getOrderedProductsByProductId(props.token, productId)
            .then(r => {
                const opHistory = r.data;
                setOrderedProductHistory(opHistory);
            })
            .catch(e => console.log(e));
    }

    const retrieveSalesTransactions = (productId) => {
        salesTransactionDataService.getAll(props.token, productId)
            .then(r => {
                const salesTransactions = r.data;
                setSalesTransactions(salesTransactions);
            })
            .catch(e => console.log(e));
    }

    const onClickUploadPicture = () => {
        const isUpload = !isUploadPhotos;
        setIsUploadPhotos(isUpload);
    }

    //cost history for price history page
    const totalCostPerHistoryPrice = (productHistory) =>{
        return utils.formatCurrency(productHistory.ordered_product_price * productHistory.quantity);
    }

    //cost history for op history page
    const totalCostOPPerHistoryPrice = (productHistory) =>{
        return utils.formatCurrency(productHistory.ordered_product_price * productHistory.quantity_in_us);
    }

    const totalCostOPPerHistoryCostIncludingShipping = (productHistory) =>{
        return utils.formatCurrency(productHistory.ordered_product_price * productHistory.quantity_in_us + productHistory.total_shipping_cost);
    }

    const priceFormat = (productHistory) => {
        return utils.formatCurrency(productHistory.ordered_product_price);
    }

    const shippingCostFormat = (productHistory) => {
        return utils.formatCurrency(productHistory.total_shipping_cost);
    }

    const createdDateFormat = (productHistory) => {
        return utils.formatDate(productHistory.created_date);
    }

    const usTransactionPriceFormat = (transaction) => {
        return utils.formatCurrency(transaction.selling_price, 'us');
    }

    const usTransactionShippingCostFormat = (transaction) => {
        return utils.formatCurrency(transaction.shipping_cost, 'us');
    }

    const usTransactionTotalPaymentReceivedFormat = (transaction) => {
        return utils.formatCurrency(transaction.total_payment_received, 'us');
    }

    const usTransactionSalesTaxFormat = (transaction) => {
        return utils.formatCurrency(transaction.sales_tax, 'us');
    }

    const usTransactionDateFormat = (transaction) => {
        return utils.formatDate(transaction.transaction_date);
    }

    const fairStartDateFormat = (fair) => {
        return utils.formatDateUndefined(fair.fair_start_date);
    }

    const fairEndDateFormat = (fair) => {
        return utils.formatDateUndefined(fair.fair_end_date);
    }

    const fairPriceFormat = (fair) => {
        return utils.formatCurrency(fair.price, 'us');
    }

    const refreshPage = () =>{
        retrieveProduct(initialProductId);
        retrievePriceHistory(initialProductId);
        retrieveOrderedProductHistory(initialProductId);
        retrieveSalesTransactions(initialProductId);
    }
    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <h2>Chi Tiết Sản Phẩm</h2>
                        <Card border="success" className="mb-3">
                            <Card.Header>{product.vendor_name}</Card.Header>
                            <Card.Body>
                                <Card.Title className="text-2xl">{product.name}</Card.Title>
                                <Card.Text>{product.product_type}</Card.Text>
                                <Card.Text className="text-xl">Giá Bán Hiện Tại: {utils.formatCurrency(product.selling_price, 'us')}</Card.Text>
                                <Card.Text>Đơn Giá Nhập Hiện Tại: <Tag severity={priceSeverity} className="text-base" value={utils.formatCurrency(product.price)}></Tag></Card.Text>
                                <Card.Text>Tổng Chi Phí: <Tag severity="info" className="text-lg" value={utils.formatCurrency(totalShippingCost + product.total_order_cost)}></Tag></Card.Text>
                                <Row>
                                    <Col>
                                        <Card.Text>Tổng Chi Phí Từ Đơn Hàng: <Tag className="text-lg" severity="info" value={utils.formatCurrency(product.total_order_cost)}></Tag></Card.Text>
                                        <Card.Text>Đơn Giá Nhập Trung Bình: {utils.formatCurrency(averagePrice)}</Card.Text>
                                    </Col>
                                    <Col>
                                        <Card.Text>Tổng Chi Phí Vận Chuyển: <Tag className="text-lg" severity="info" value={utils.formatCurrency(totalShippingCost)}></Tag></Card.Text>
                                         <Card.Text>Chi Phí Vận Chuyển Trung Bình: {utils.formatCurrency(averageShippingCost)}</Card.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card.Text>Số Lượng Đã Bán: <Tag severity="success" className="text-base" value={product.sold_quantity}></Tag></Card.Text>
                                    </Col>
                                    <Col>
                                        <Card.Text>Số Lượng Còn Lại: <Tag severity="info" className="text-base" value={product.in_stock_quantity}></Tag></Card.Text>
                                    </Col>
                                </Row>

                                <Card.Text>Ghi Chú: {product.notes}</Card.Text>
                            </Card.Body>
                        </Card>
                        <div className="mb-3">
                            <Link to ={{
                                    pathname:"/products/",
                                    state:{
                                        searchProductTerm: initialSearchProductTerm,
                                        searchVendorTerm: initialSearchVendorTerm,
                                        searchCategoryTerm: initialSearchCategoryTerm
                                    }
                                }}>
                            <Button variant="primary" className="me-2"><b>&#8592;</b></Button>
                            </Link>
                            <Link to ={{
                                pathname:"/products/" + product.product_id,
                                state:{
                                    currentProduct:product,
                                    searchProductTerm: initialSearchProductTerm,
                                    searchVendorTerm: initialSearchVendorTerm,
                                    searchCategoryTerm: initialSearchCategoryTerm
                                }
                            }}>
                            <Button variant="outline-info" className="me-2">Chỉnh Sửa</Button>
                            </Link>
                        </div>
                        <div className="card">
                            <Tabs>
                                <Tab eventKey="0" title="Số Lượng">
                                    <Tab.Content>
                                    <div className="card flex justify-content-center">
                                        <Tree value={quantityData} className="w-full md:w-50rem" />
                                    </div>
                                    </Tab.Content>
                                </Tab>
                                <Tab eventKey="1" title="Lịch Sử Giá Cả Nhập">
                                    <div className="card">
                                        <DataTable value={priceHistory} paginator rows={10} rowsPerPageOptions={[10, 20, 50]} sortMode="multiple" showGridlines stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ordered_product_price" body={priceFormat} header="Giá Nhập" style={{ width: '25%' }} sortable></Column>
                                            <Column field="quantity" header="Số Lượng" style={{ width: '25%' }} sortable></Column>
                                            <Column header="Tổng" body={totalCostPerHistoryPrice} style={{ width: '25%' }}></Column>
                                            {/*<Column field="representative.name" header="Representative" style={{ width: '25%' }}></Column>*/}
                                        </DataTable>
                                    </div>

                                </Tab>
                                <Tab eventKey="2" title="Lịch Sử Nhập">
                                    <div className="card">
                                        <DataTable value={orderedProductHistory} paginator rows={10} rowsPerPageOptions={[10, 20, 50]} sortMode="multiple" showGridlines stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ordered_product_price" body={priceFormat} header="Giá Nhập" sortable ></Column>
                                            <Column field="quantity" header="Số Lượng Đặt" sortable ></Column>
                                            <Column field="quantity_in_us" header="Số Lượng Nhận Ở US" sortable ></Column>
                                            <Column header="Chí Phí Đặt Hàng" body={totalCostOPPerHistoryPrice}></Column>
                                            <Column field="total_shipping_cost" header="Chí Phí Vận Chuyển" body={shippingCostFormat} sortable ></Column>
                                            <Column header="Tổng Chi Phí" body={totalCostOPPerHistoryCostIncludingShipping}></Column>
                                            <Column field="created_date" header="Ngày Tạo" body={createdDateFormat} sortable ></Column>
                                            {/*<Column field="representative.name" header="Representative" style={{ width: '25%' }}></Column>*/}
                                        </DataTable>
                                    </div>
                                </Tab>
                                <Tab eventKey="3" title="Lịch Sử Giao Dịch">
                                    <div className="card">
                                        <DataTable value={salesTransactions} paginator rows={10} rowsPerPageOptions={[10, 20, 50]} sortMode="multiple" showGridlines stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="platform" header="Nền Tảng" sortable ></Column>
                                            <Column field="quantity" header="Số Lượng Bán" sortable ></Column>
                                            <Column field="selling_price" header="Giá Bán" body={usTransactionPriceFormat} sortable ></Column>
                                            <Column field="total_payment_received" header="Tổng" body={usTransactionTotalPaymentReceivedFormat} sortable ></Column>
                                            <Column field="shipping_cost" header="Vận Chuyển" body={usTransactionShippingCostFormat} sortable ></Column>
                                            <Column field="sales_tax" header="Sales Tax" body={usTransactionSalesTaxFormat} sortable ></Column>
                                            <Column field="transaction_date" header="Ngày Giao Dịch" body={usTransactionDateFormat} sortable ></Column>
                                            {/*<Column field="representative.name" header="Representative" style={{ width: '25%' }}></Column>*/}
                                        </DataTable>
                                    </div>
                                </Tab>
                                <Tab eventKey="4" title="Lịch Sử Hội Chợ">
                                    <div className="card">
                                        <DataTable value={fairHistory} paginator rows={10} rowsPerPageOptions={[10, 20, 50]} sortMode="multiple" showGridlines stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="fair_name" header="Tên Hội Chợ" sortable ></Column>
                                            <Column field="sold" header="Số Lượng Bán" sortable ></Column>
                                            <Column field="price" header="Giá Bán" body={fairPriceFormat} sortable ></Column>
                                            <Column field="fair_start_date" header="Ngày Bắt Đầu" body={fairStartDateFormat} sortable ></Column>
                                            <Column field="fair_end_date" header="Ngày Kết Thúc" body={fairEndDateFormat} sortable ></Column>
                                            {/*<Column field="representative.name" header="Representative" style={{ width: '25%' }}></Column>*/}
                                        </DataTable>
                                    </div>
                                </Tab>

                            </Tabs>
                        </div>

                        <Container>
                            <Button variant="primary" className="me-2" onClick={onClickUploadPicture}>{"Thêm Hình"}</Button>
                                <Container>
                                    <div className="mb-3">
                                        {isUploadPhotos?<CommonImageUploadHandler
                                            token={props.token}
                                            keyId={"product_id"}
                                            valueId={initialProductId}
                                        />:""}
                                    </div>

                                </Container>

                            <CommonImageDisplayHandler
                                token={props.token}
                                keyId={"product_id"}
                                value={initialProductId}
                            />
                                </Container>
                    </div>
                    
                )

                
            }
        </Container>
    )

}

export default ViewProduct;