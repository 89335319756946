import CommonDataService from './commondataservice';
import axios from 'axios';

class OrderDataService extends CommonDataService{
    async complete(id,token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.put(this.baseURL+`${this.objectNames}/${id}/complete`);
    }

    async getAllIncompleteOrCompleteOrder(token, status=false){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view?is_completed=${status}`, {timeout:5000})
    }

    async getAllIncompleteOrCompleteOrderPagination(pageNumber,token, status=false){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view/all?p=${pageNumber}&is_completed=${status}`, {timeout:5000});
    }

    async getAllIncompleteOrCompleteOrderVendorPagination(pageNumber,token, vendorId ,status=false){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/vendor/all?p=${pageNumber}&is_completed=${status}&vendor_id=${vendorId}`, {timeout:5000});
    }

    async getOrdersById(token,orderIds){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view?order_ids=${orderIds}`)
    }
}

const orderDataService = new OrderDataService('orders', 'order');

export default orderDataService;