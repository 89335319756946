import React, {useRef} from 'react'
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup'; // To use confirmPopup method
import "primereact/resources/themes/lara-light-blue/theme.css";
import {FaFloppyDisk, FaPlus, FaPencil, FaTrash, FaCheck, FaSpinner, FaTrashCan} from "react-icons/fa6";
import shipmentDataService from "../services/shipments";
import shippedProductDataService from "../services/shippedproducts";
import { Toast } from 'primereact/toast';
import Container from "react-bootstrap/Container";
import paymentDataService from "../services/payments";
import usShipmentDataService from "../services/usshipments";
import { Tag } from 'primereact/tag';
import utils from "../services/utils";
import paymentPhotoDataService from "../services/paymentphotos";
import moment from "moment/moment";


const USShipmentListTemplate = ({token, shipments, getCurrentPageInfo}) => {
    const toast = useRef(null);

    const handleDeleteShipment = (event, shipmentId) =>{
        confirmPopup({
            target: event.currentTarget,
            message: 'Bạn có chắc là bạn muốn xóa đơn vận chuyển qua Mỹ này chứ?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteShipment(shipmentId),
            acceptLabel: "Đồng Ý",
            rejectLabel: "Không"

        })
    }
    const deleteShipment = (shipmentId) =>{
        usShipmentDataService.delete(shipmentId, token)
            .then(
                r=>{
                    shipmentDataService.getAllShipmentByUSShipmentId(shipmentId, token)
                        .then( r => {
                            const shipments = r.data;
                            shipments.forEach((shipment) =>{
                                let updatedData = {
                                    shipment_us_id: null,
                                    last_modified_by: localStorage.getItem('username'),
                                    last_modified_date: moment(),
                                }
                                shipmentDataService.update(shipment.shipment_id, updatedData, token)
                                    .then(r=>{
                                    })
                                    .catch(e => console.log(e));
                            });
                            var currentShipments = shipments.map((shipment)=> shipment.shipment_id);
                            var currentShipmentSet = new Set(currentShipments);
                            let filter_shipments = "";
                            // filter out current orders in the shipment
                            currentShipmentSet.forEach(shipmentId =>{
                                filter_shipments = filter_shipments + shipmentId + "|"
                            })
                            if(filter_shipments.length > 0){
                                shippedProductDataService.getAllShippedProdForShipments(token,filter_shipments)
                                    .then(r=>{
                                        const shippedProducts = r.data;
                                        shippedProducts.forEach((shippedProduct) =>{
                                            let shippedProductData ={
                                                quantity_received_us: null,
                                                shipment_id: shippedProduct.shipment_id,
                                                op_id: shippedProduct.op_id,
                                                last_modified_by: localStorage.getItem('username'),
                                                last_modified_date: moment(),
                                            }
                                            shippedProductDataService.update(shippedProduct.sp_id, shippedProductData, token)
                                                .then(r=>{

                                                }).catch(e=>console.log(e));
                                        })
                                    })
                            }
                            paymentDataService.getAllPaymentsForUSShipment(token, shipmentId)
                            .then( r=>{
                                const payments = r.data;
                                payments.forEach((payment) => {
                                    paymentDataService.delete(payment.payment_id, token)
                                        .then( r=>{
                                            paymentPhotoDataService.getAllPaymentProductPhotos(token, payment.payment_id)
                                                .then( r=>{
                                                    const paymentPhotos = r.data;
                                                    paymentPhotos.forEach((paymentPhoto) => {
                                                        paymentPhotoDataService.delete(paymentPhoto.payment_photo_id, token)
                                                            .then(e=>{

                                                            })
                                                            .catch(e=> console.log(e));
                                                    })
                                                    }
                                                )
                                                .catch(e => console.log(e));

                                        })
                                        .catch( e=> console.log(e));
                                })
                            }).catch( e=> console.log(e));
                            getCurrentPageInfo();
                            toast.current.show({ severity: 'info', detail: "Xóa đơn vận chuyển qua Mỹ thành công!" });
                            })
                }
            )
            .catch(e => console.log(e));
    }

    return(
        <Container>
            <Toast ref={toast} />
            <ConfirmPopup />
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Label</th>
                        <th>Trạng Thái</th>
                        <th>Ngày Tạo</th>
                        <th></th>
                    </tr>

                </thead>
                    {
                        shipments.map((shipment) =>{
                            return(
                                <tbody>
                                    <tr key={shipment.shipment_us_id}>
                                        <td><Link to ={{
                                                pathname:"/usshipment/"+ shipment.shipment_us_id + "/view",
                                                state:{
                                                    currentUSShipment:shipment
                                                }
                                            }}>
                                            {shipment.label}
                                            </Link></td>
                                            <td >{
                                                shipment.is_delivered?<Tag severity="success" value="Đã Nhận"></Tag>:<Tag severity="info" value="Mới"></Tag>
                                            }</td>
                                        <td >{utils.formatDateTime(shipment.created_date)}</td>
                                        <td>
                                            {
                                                shipment.is_delivered?"":<Button variant="outline-danger" onClick={(e) => handleDeleteShipment(e, shipment.shipment_us_id)}><FaTrashCan/></Button>
                                            }


                                        </td>
                                    </tr>
                                </tbody>
                            )
                        }

                        )
                    }
            </Table>
        </Container>

    )

}

export default USShipmentListTemplate