import CommonDataService from './commondataservice';
import axios from "axios";

class ShippedProductDataService extends CommonDataService{
    async getAllShippedProdForOrder(token, filter=''){
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            if(filter)
                return await axios.get(this.baseURL+`${this.objectNames}/view?order_id=${filter}`);
            return await axios.get(this.baseURL+ this.objectNames+ '/view');
    }

    async getAllShippedProdForShipments(token, shipmentIds){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view?shipment_ids=${shipmentIds}`);
    }
}

const shippedProductDataService = new ShippedProductDataService('shippedproducts', 'shippedproduct');
export default shippedProductDataService;