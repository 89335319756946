import React, {useEffect, useState} from "react";
import shippedProductDataService from "../services/shippedproducts";
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ShippedPhotoImageHandler from "./shipped-photo-image-handler";
import shippedProductPhotoDataService from "../services/shippedproductphotos";
import "primereact/resources/themes/lara-light-blue/theme.css";
import 'primereact/resources/primereact.css'
import "primeicons/primeicons.css";
import { Galleria } from 'primereact/galleria';
import {
  S3Client,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import {
  getSignedUrl,
} from "@aws-sdk/s3-request-presigner";
import {FaArrowLeft, FaImages} from "react-icons/fa6";
import utils from "../services/utils";



const ViewShippedProduct = props => {
    let initialShippedProductId = "";
    let initialShipmentId = "";
    let isUploadPhoto = false;
    let isDeliveredVN = false;

    if(props.location.state && props.location.state.currentShippedProductId){
        initialShippedProductId = props.location.state.currentShippedProductId;
        initialShipmentId = props.location.state.currentShipmentId;
        isDeliveredVN = props.location.state.isDeliveredVN;
    }
    else{
        initialShippedProductId = props.location.pathname.replace('/product/', '').replace('/view', '');
    }
    const [shippedProduct, setShippedProduct] = useState('');
    const [isUploadPhotos, setIsUploadPhotos] = useState(isUploadPhoto);
    const [photos, setPhotos] = useState(null);
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const accessKey = process.env.REACT_APP_ACCESS_KEY;
    const vultrBucketName = process.env.REACT_APP_BUCKET_NAME;
    const vultrEndpoint = process.env.REACT_APP_HOST_NAME;
    const [activeIndex, setActiveIndex] = useState(0);
    const responsiveOptions = [
        {
            breakpoint: '991px',
            numVisible: 4
        },
        {
            breakpoint: '767px',
            numVisible: 3
        },
        {
            breakpoint: '575px',
            numVisible: 1
        }
    ];

    const s3Client = new S3Client({
        region: 'us-east-1',
        endpoint: vultrEndpoint,
        forcePathStyle: false,
        credentials: {
            accessKeyId: accessKey,
            secretAccessKey: secretKey,
          },
    });

    const createPresignedUrl = (key) => {
        const command = new GetObjectCommand({Bucket:vultrBucketName, Key:key});
        return getSignedUrl(s3Client, command, {expiresIn:3600});
    }


    useEffect(() =>{
        retrieveShippedProduct(initialShippedProductId);
        retrieveShippedProductPhotos(initialShippedProductId);
    }, [props.token]);

    const refreshPage = () => {
        retrieveShippedProduct(initialShippedProductId);
        retrieveShippedProductPhotos(initialShippedProductId);
    }
    const next = () => {
        setActiveIndex(prevState => (prevState === photos.length - 1) ? 0 : prevState + 1)
    }

    const prev = () => {
        setActiveIndex((prevState) => (prevState === 0 ? 0 : prevState - 1));
    };

    const indicatorTemplate = (index) => {
        return <span style={{ color: '#ffffff', cursor: 'pointer'}}>{index + 1}</span>;
    };

    const itemTemplate = (item) => {
        return <img src={item}  style={{ width: '100%', display: 'block' }} />;
    }

    const thumbnailTemplate = (item) => {
        return <img src={item} style={{ width: '25%', display: 'block' }} />;
    }


    const retrieveShippedProduct = (shippedProductId) =>{
        shippedProductDataService.getOne(shippedProductId,props.token)
        .then(response => {
            setShippedProduct(response.data);
        })
        .catch(e=>{
            console.log(e);
        });
    }

    async function getURL(key){
        return await createPresignedUrl(key);
    }

    const retrieveShippedProductPhotos = (shippedProductId) => {
        shippedProductPhotoDataService.getAllShippedProductPhotos(props.token, shippedProductId)
            .then(
                r => {
                    let photoList = [];
                    const photoIds = r.data;
                    photoIds.forEach((photoId) =>{
                        const url = getURL(photoId.photo_vn);
                        photoList.push(url);
                    })
                    Promise.all(photoList).then(values =>{
                        // console.log(values);
                        setPhotos(values);
                    }).catch(e=> console.log(e));
                }
            )
            .catch(e => console.log(e));
    }


    const onClickUploadPicture = () => {
        const isUpload = !isUploadPhotos;
        setIsUploadPhotos(isUpload);
    }


    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <Card border="success" className="mb-3">
                            <Card.Header>{shippedProduct.order_name} <br/> <h4>{shippedProduct.product_name}</h4></Card.Header>
                            <Card.Body>
                                <Card.Title>{shippedProduct.name}</Card.Title>
                                <Card.Text>{shippedProduct.notes}</Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item>Tổng Số Sẽ Gửi: {shippedProduct.quantity_sent}</ListGroup.Item>
                            </ListGroup>

                        </Card>
                        <div>
                            <Link to ={{
                                    pathname:"/shipment/" + initialShipmentId + '/view',
                                state:{
                                    currentShipmentId:initialShipmentId
                                }
                                }}>
                            <Button variant="outline-primary" className="mb-3"><FaArrowLeft size="1.2em"/></Button>
                            {/*    <Button icon="pi pi-arrow-left" className="me-2" severity="primary" outlined />*/}
                            </Link>
                        </div>
                        <Button variant="outline-primary" className="mb-3" onClick={onClickUploadPicture}><FaImages size="1.2em"/> &nbsp;Thêm Hình</Button>
                            <Container>
                                <div>
                                    {isUploadPhotos?<ShippedPhotoImageHandler
                                        shippedProductId={initialShippedProductId}
                                        shipmentId={initialShipmentId}
                                        token={props.token}
                                        refreshPage={refreshPage}
                                        // refreshPage={refreshPage}
                                    />:""}
                                </div>

                            </Container>
                        <div>
                            <div className="card">
                                {/*<div className="mb-3">*/}
                                {/*    <Button icon="pi pi-minus" onClick={prev} />*/}
                                {/*    <Button icon="pi pi-plus" onClick={next}  />*/}
                                {/*</div>*/}

                            <Galleria
                                value={photos}
                                // activeIndex={activeIndex}
                                // onItemChange={(e) => setActiveIndex(e.index)}
                                // responsiveOptions={responsiveOptions}
                                // numVisible={10}
                                // item={itemTemplate}
                                // thumbnail={thumbnailTemplate}
                                style={{ maxWidth: '75vw'}}
                                className="custom-indicator-galleria"
                                circular
                                showItemNavigators
                                showThumbnails={false}
                                showIndicators
                                // changeItemOnIndicatorHover
                                showIndicatorsOnItem
                                indicatorsPosition="top"
                                item={itemTemplate}
                                indicator={indicatorTemplate}
                            />
                        </div>
                            {/*<img src="https://testimages.ewr1.vultrobjects.com/171089251…98522dfb3&X-Amz-SignedHeaders=host&x-id=GetObject"/>*/}


                        </div>
                    </div>


                )


            }
        </Container>
    )

}

export default ViewShippedProduct;