import React, {useState, useEffect, useCallback} from "react";
import shipmentDataService from "../services/shipments";
import vendorDataService from "../services/vendors";
import {Link} from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Alert from 'react-bootstrap/Alert';
import moment from 'moment';
import DataPagination from "./pagination";
import {v4 as uuidv4} from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ShipmentListTemplate from "./shipment-list-template";
import {FaFloppyDisk, FaPlus, FaImages, FaXmark, FaArrowLeft, FaTrash, FaPencil, FaTruckFast, FaPlane} from "react-icons/fa6";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import utils from "../services/utils";


const ShipmentList = props => {
    let initialActiveTab = 0
    const[shipments, setShipments] = useState([]);
    const [newShipments, setNewShipments] = useState([]);
    const [inVNShipments, setInVNShipments] = useState([]);
    const [inUSShipments, setInUSShipments] = useState([]);
    const [currentNewShipmentPage, setCurrentNewShipmentPage] = useState(1);
    const [recordNewShipmentCount, setRecordNewShipmentCount] = useState(0);
    const [currentInVNShipmentPage, setCurrentInVNShipmentPage] = useState(1);
    const [recordInVNShipmentCount, setRecordInVNShipmentCount] = useState(0);
    const [currentInUSShipmentPage, setCurrentInUSShipmentPage] = useState(1);
    const [recordInUSShipmentCount, setRecordInUSShipmentCount] = useState(0);
    const pageSize = 10;
    const toastNotification = () => toast("Tạo đơn giao hàng thành công!", {autoClose: 3000});

    if(props.location.state && props.location.state.eventKey){
        initialActiveTab = props.location.state.eventKey;
    }

    useEffect(() =>{
        getCurrentPageInfo()
        // retrieveNewShipmentsPagination(currentNewShipmentPage);
    }, [props.token, currentNewShipmentPage, currentInVNShipmentPage, currentInUSShipmentPage]);

    const getCurrentPageInfo = () => {
        retrieveNewShipmentsPagination(currentNewShipmentPage);
        retrieveInVNShipmentsPagination(currentInVNShipmentPage);
        retrieveInUSShipmentsPagination(currentInUSShipmentPage);
    }


    const retrieveNewShipmentsPagination = (currentPage) =>{
        shipmentDataService.getAllShipmentByStatusPagination(currentPage,props.token)
        .then(response => {
            setNewShipments(response.data.results);
            setRecordNewShipmentCount(response.data.count);
        })
        .catch(e=>{
            console.log(e);
        });
    }

    const retrieveInVNShipmentsPagination = (currentPage) =>{
        shipmentDataService.getAllShipmentByStatusPagination(currentPage,props.token, 'vn')
        .then(response => {
            setInVNShipments(response.data.results);
            setRecordInVNShipmentCount(response.data.count);
        })
        .catch(e=>{
            console.log(e);
        });
    }

    const retrieveInUSShipmentsPagination = (currentPage) =>{
        shipmentDataService.getAllShipmentByStatusPagination(currentPage,props.token, 'us')
        .then(response => {
            setInUSShipments(response.data.results);
            setRecordInUSShipmentCount(response.data.count);
        })
        .catch(e=>{
            console.log(e);
        });
    }


    const createShippingLabel = () => {
        const shipment_id = uuidv4();
        let vendor_id = "";
        if(props.role!=='admin'){
            vendorDataService.getAll(props.token)
            .then(r => {
                const currentVendor = r.data;
                console.log(currentVendor);
                let vendor_name = localStorage.getItem('username');
                if(currentVendor.length===1){
                    vendor_id = currentVendor[0].vendor_id;
                    vendor_name = currentVendor[0].name;
                }
                var data = {
                    shipment_id: shipment_id,
                    label: "Đơn giao hàng từ " + vendor_name,
                    vendor_id: vendor_id,
                    created_by: localStorage.getItem('username'),
                    last_modified_by: localStorage.getItem('username'),
                    last_modified_date: moment()
                }
                shipmentDataService.create(data, props.token)
                    .then(response =>{
                        retrieveNewShipmentsPagination(currentNewShipmentPage);
                    })
                    .catch(e => {
                        console.log(e);
                    });
                })
                .catch(e => console.log(e));
        }

        toastNotification();
    }

    const handleNewShipmentPagination = (currentPage) => {
        setCurrentNewShipmentPage(currentPage);
    }
    const handleInVNShipmentPagination = (currentPage) => {
        setCurrentInVNShipmentPage(currentPage);
    }
    const handleInUSShipmentPagination = (currentPage) => {
        setCurrentInUSShipmentPage(currentPage);
    }
    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <ToastContainer />
                        <h2>Danh Sách Đơn Giao Hàng</h2>
                        {
                            props.role==='admin'?(
                                <Link to ={{
                                            pathname:"/shipments/create",
                                        }}>
                                            <Button variant="success" className="me-2"><FaTruckFast title="Tạo Đơn Giao Hàng"/>&nbsp;Tạo Đơn Giao Hàng</Button>
                                        </Link>
                            ):(props.role==='vendor'?<Button variant="success" className="mb-3" onClick={createShippingLabel}>
                            <FaTruckFast title="Tạo Đơn Giao Hàng"/>&nbsp;Tạo Đơn Giao Hàng
                            </Button>:"")
                        }

                        {
                            props.role==='vendor'?(
                                <>
                                    <DataPagination
                                    pageSize={pageSize}
                                    recordCount={recordNewShipmentCount}
                                    handlePagination={handleNewShipmentPagination}
                                    currentPage={currentNewShipmentPage}
                                    />
                                    <ShipmentListTemplate
                                    token={props.token}
                                    role={props.role}
                                    shipments={newShipments}
                                    getCurrentPageInfo={getCurrentPageInfo}
                                />
                                </>

                            ):""

                        }
                        {
                            props.role==="staff"||props.role==="admin"?(
                                <Tabs defaultActiveKey={initialActiveTab}>
                                    <Tab eventKey={0} title="Mới">
                                        <Tab.Content>
                                            <>
                                                <DataPagination
                                                pageSize={pageSize}
                                                recordCount={recordNewShipmentCount}
                                                handlePagination={handleNewShipmentPagination}
                                                currentPage={currentNewShipmentPage}
                                                />
                                                <ShipmentListTemplate
                                                token={props.token}
                                                role={props.role}
                                                shipments={newShipments}
                                                fromOrder={false}
                                                tabEventKey={0}
                                                getCurrentPageInfo={getCurrentPageInfo}
                                            />
                                            </>
                                        </Tab.Content>
                                    </Tab>
                                    <Tab eventKey={1} title="Đã Nhận Ở Việt Nam">
                                        <Tab.Content>
                                            <>
                                                <DataPagination
                                                pageSize={pageSize}
                                                recordCount={recordInVNShipmentCount}
                                                handlePagination={handleInVNShipmentPagination}
                                                currentPage={currentInVNShipmentPage}
                                                />
                                                <ShipmentListTemplate
                                                token={props.token}
                                                role={props.role}
                                                shipments={inVNShipments}
                                                fromOrder={false}
                                                tabEventKey={1}
                                                getCurrentPageInfo={getCurrentPageInfo}
                                            />
                                            </>
                                        </Tab.Content>
                                    </Tab>
                                    <Tab eventKey={2} title="Đã Nhận Ở Mỹ">
                                        <Tab.Content>
                                            <>
                                                <DataPagination
                                                pageSize={pageSize}
                                                recordCount={recordInUSShipmentCount}
                                                handlePagination={handleInUSShipmentPagination}
                                                currentPage={currentInUSShipmentPage}
                                                />
                                                <ShipmentListTemplate
                                                token={props.token}
                                                role={props.role}
                                                shipments={inUSShipments}
                                                fromOrder={false}
                                                tabEventKey={2}
                                                getCurrentPageInfo={getCurrentPageInfo}
                                            />
                                            </>

                                        </Tab.Content>
                                    </Tab>
                                </Tabs>
                            ):""
                        }


                    </div>
                )
            }
        </Container>
    )
}



export default ShipmentList;