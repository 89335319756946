import "primereact/resources/themes/lara-light-blue/theme.css";
import 'primereact/resources/primereact.css'
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';
import { Image } from 'primereact/image';
import {
  S3Client,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import {
  getSignedUrl,
} from "@aws-sdk/s3-request-presigner";
import React, {useEffect, useState} from "react";
import shippedProductPhotoDataService from "../services/shippedproductphotos";
import Container from "react-bootstrap/Container";
import paymentPhotoDataService from "../services/paymentphotos";
import productPhotoDataService from "../services/productphotos";

export default function CommonImagePreviewHandler({keyId, value, token}) {
     useEffect( () => {
       retrievePhotos(keyId, value);
     }, [token]);

    const [photo, setPhoto] = useState(null);
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const accessKey = process.env.REACT_APP_ACCESS_KEY;
    const vultrBucketName = process.env.REACT_APP_BUCKET_NAME;
    const vultrEndpoint = process.env.REACT_APP_HOST_NAME;

    const s3Client = new S3Client({
        region: 'us-east-1',
        endpoint: vultrEndpoint,
        forcePathStyle: false,
        credentials: {
            accessKeyId: accessKey,
            secretAccessKey: secretKey,
          },
    });

    const createPresignedUrl = (key) => {
        const command = new GetObjectCommand({Bucket:vultrBucketName, Key:key});
        return getSignedUrl(s3Client, command, {expiresIn:3600});
    }


    async function getURL(key){
        return await createPresignedUrl(key);
    }

    const retrievePhotos = (keyId, value) => {
      if(keyId === "payment_id"){
        paymentPhotoDataService.getAllPaymentProductPhotos(token, value)
            .then(
                r=>{
                      const photoId = r.data[0];
                      getURL(photoId.photo_vn).then(
                          r => {
                              setPhoto(r);
                          }
                      ).catch(e => console.log(e));
                  }
            )
            .catch(e => console.log(e));
      }
      else if(keyId === "product_id"){
        productPhotoDataService.getAllProductPhotos(token, value)
            .then(
                r=>{
                      const photoId = r.data[0];
                      getURL(photoId.photo_vn).then(
                          r => {
                              setPhoto(r);
                          }
                      ).catch(e => console.log(e));
                  }
            )
            .catch(e => console.log(e));
      }
      else if(keyId === "sp_id"){
          shippedProductPhotoDataService.getAllShippedProductPhotos(token, value)
              .then(
                  r=>{
                      if(r.data.length > 0){
                         const photoId = r.data[0];

                          getURL(photoId.photo_vn).then(
                          r => {
                              setPhoto(r);
                          }
                          ).catch(e => console.log(e));
                      }
                  }
              )
              .catch(e => console.log(e));
      }


    }


    return(
        <Container>
            <div className="card flex justify-content-center">
                <Image src={photo} zoomSrc={photo} alt="Có Lỗi Hoặc Chưa Có Hình" width="90" height="100" className=" shadow-2 border-round" preview />
            </div>
        </Container>
    )

}