import CommonDataService from './commondataservice';
import axios from "axios";

class USShipmentDataService extends CommonDataService{
    async getShipmentsById(token,shipmentIds){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view?shipment_ids=${shipmentIds}`)
    }

    async deliver(id, token){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.put(this.baseURL+`${this.objectName}/${id}/delivered`);
    }

    async getAllShipmentByStatusPagination(pageNumber,token, status='new'){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view/all?p=${pageNumber}&status=${status}`, {timeout:5000});
    }

}

const usShipmentDataService = new USShipmentDataService('usshipments', 'usshipment');
export default usShipmentDataService;