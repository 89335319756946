import React, {useEffect, useRef, useState} from "react";
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import orderDataService from "../services/orders";
import orderedProductDataService from "../services/orderedproducts";
import productDataService from "../services/products";
import DataPagination from "./pagination";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Accordion from 'react-bootstrap/Accordion';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import utils from "../services/utils";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import {
    FaFloppyDisk,
    FaPlus,
    FaPencil,
    FaArrowLeft,
    FaTruckFast,
    FaTrashCan, FaDollarSign
} from "react-icons/fa6";
import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup'; // To use confirmPopup method
import {InputGroup} from "react-bootstrap";
import { Toast } from 'primereact/toast';
import {v4 as uuidv4} from "uuid";
import shipmentDataService from "../services/shipments";
import shippedProductDataService from "../services/shippedproducts";
import ShipmentListTemplate from "./shipment-list-template";
import PaymentListTemplate from "./payment-list-template";
import paymentDataService from "../services/payments";
import CommonImagePreviewHandler from "./common-image-preview-handler";
import { AutoComplete } from "primereact/autocomplete";
import USCurrencyInput from "./us-currency-input-mask";
import telegramServices from "../services/telegram";
import fairProductDataService from "../services/fairproducts";
import fairDataService from "../services/fairs";
import {FaSave} from "react-icons/fa";
import CommonGalleryDisplayHandler from "./common-gallery-preview-handler";


const ViewFair = props => {
    let initialFairId = "";
    let initialFairName = "";
    let initialStartDate = "";
    let initialEndDate = "";
    let initialLocation = "";
    let initialBoothLocation = "";
    // let initialApplicationFee = 0;
    let initialActiveTab = 0;
    let initialFairStatus = "New";
    let initialStatusTag = "info";


    if(props.location.state && props.location.state.currentFair){
        initialFairId = props.location.state.currentFair.fair_id;
        initialFairName = props.location.state.currentFair.name;
        initialStartDate = utils.formatDateUndefined(props.location.state.currentFair.start_date);
        initialEndDate = utils.formatDateUndefined(props.location.state.currentFair.end_date);
        initialLocation = props.location.state.currentFair.location;
        initialBoothLocation = props.location.state.currentFair.booth_name;
        // initialApplicationFee = utils.formatCurrency(props.location.state.currentFair.application_fee, 'us');
        initialFairStatus = props.location.state.currentFair.status;
    }
    else{
        initialFairId = props.location.pathname.replace('/fair/', '').replace('/view', '');
    }

    if(props.location.state && props.location.state.eventKey){
        initialActiveTab = props.location.state.eventKey;
    }

    if(props.location.state && props.location.state.newFairId){
        initialFairId = props.location.state.newFairId;
    }
    else{
        initialFairId = props.location.pathname.replace('/fair/', '').replace('/view', '');
    }

    const [fair, setFair] = useState('');
    // const [products, setProducts] = useState([]);
    const [fairName, setFairName] = useState(initialFairName);
    const [fairStartDate, setFairStartDate] = useState(initialStartDate);
    const [fairEndDate, setFairEndDate] = useState(initialEndDate);
    const [fairLocation, setFairLocation] = useState(initialLocation);
    const [fairBoothLocation, setFairBoothLocation] = useState(initialBoothLocation);
    // const [fairApplicationFee, setFairApplicationFee] = useState(initialApplicationFee);
    const [fairProducts, setFairProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordCount, setRecordCount] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [fairStatus, setFairStatus] = useState(initialFairStatus);
    const [statusTag, setStatusTag] = useState(initialStatusTag);
    // const [shippedQuantity, setShippedQuantity] = useState(initialShipped);
    // const [orderCost, setOrderCost] = useState(0);
    const pageSize = 10;
    // const [validated, setValidated] = useState(false);
    // const [allShipments, setAllShipment] = useState([]);
    const [allPayments, setAllPayments] = useState([]);
    // const [paymentStatus, setPaymentStatus] = useState("");
    const toast = useRef(null);
    const toastUpdateNotification = () => toast.current.show({ severity: 'success', detail: "Cập nhật thông tin hợp lệ thành công!" });
    const toastDeleteNotification = () => toast.current.show({ severity: 'success', detail: "Xóa thành công!" });


    useEffect(() =>{
        retrieveFair(initialFairId);
        retrieveFairProductPagination(currentPage, initialFairId);
        // retrieveShipments();
        retrievePaymentsForFair(initialFairId);

    }, [props.token, currentPage]);


    const retrieveFairProductPagination = (currentPage, fairId) =>{
        fairProductDataService.getDataPagination(currentPage,props.token, fairId)
        .then(response => {
            setFairProducts(response.data.results);
            setRecordCount(response.data.count);
        })
        .catch(e=>{
            console.log(e);
        });
    }

    const onChangeQuantity = (e, fairProductId) => {
        const {name, value} = e.target;
        const editData = fairProducts.map((item) =>
            item.fair_product_id === fairProductId && name?{...item, [name]: value}:item
        )
        // console.log('editData: ',editData);
        setFairProducts(editData);
    }


    const retrieveFair = (fairId) =>{
        fairDataService.getOne(fairId,props.token)
        .then(response => {
            const fair = response.data;
            // console.log('fair:', fair);
            setFair(fair);
            setFairName(fair.name);
            setFairLocation(fair.location);
            setFairStartDate(utils.formatDateUndefined(fair.start_date));
            setFairEndDate(utils.formatDateUndefined(fair.end_date));
            setFairBoothLocation(fair.booth_name);
            // setFairStatus(fair.status)
            // setFairApplicationFee(utils.formatCurrency(fair.application_fee, 'us'));
            if(fair.status.toLowerCase() === 'new'){
                setStatusTag("info");
                setFairStatus("Mới");
            }

            else if(fair.status.toLowerCase() === 'accepted'){
                setStatusTag("success");
                setFairStatus("Được Chấp Nhận");
            }

            else if(fair.status.toLowerCase() === 'waitlisted'){
                setStatusTag("warning");
                setFairStatus("Trong Danh Sách Chờ");
            }
            else{
                setStatusTag("danger");
                setFairStatus("Bị Từ Chối");
            }
        })
        .catch(e=>{
            console.log(e);
        });
    }

    const retrievePaymentsForFair = (fairId) => {
        paymentDataService.getAllPaymentsForFair(props.token, fairId)
            .then(r =>{
                setAllPayments(r.data);
            })
            .catch(e => console.log(e));
    }

    const handleDeleteFairProduct = (event, fairProductId) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Bạn có chắc là bạn muốn xóa sản phẩm này khỏi hội chợ này chứ?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteFairProduct(fairProductId),
            acceptLabel: "Đồng Ý",
            rejectLabel: "Không"

        })
    }

    const deleteFairProduct = (fairProductId) =>{
        fairProductDataService.delete(fairProductId, props.token)
        .then(response => {
            toastDeleteNotification();
            retrieveFair(initialFairId);
            retrieveFairProductPagination(currentPage, initialFairId);
        })
        .catch(e =>{
            console.log(e);
        });

    }

    const handlePagination = (currentPage) => {
        setCurrentPage(currentPage);
    }



    const getCurrentPageInfo = () =>{
        retrieveFair(initialFairId);
        retrieveFairProductPagination(currentPage, initialFairId);
        retrievePaymentsForFair(initialFairId);
    }

    const saveQuantityFields = (e, fairProductId) =>{
        const editData = fairProducts.filter((item) => item.fair_product_id === fairProductId);
        const stock = editData[0].stock;
        const sold = editData[0].sold;
        const price = editData[0].price;
        const total_instock = editData[0].product_in_stock;
        // console.log(editData);
        let fairProductUpdateData = {
            product_id: editData[0].product_id,
            fair_id: editData[0].fair_id,
            stock: utils.getInt(stock),
            sold: utils.getInt(sold),
            price: utils.parseUSCurrencyInput(price),
            last_modified_by: localStorage.getItem('username'),
            last_modified_date: moment(),
        }
        // console.log(fairProductUpdateData);
        fairProductDataService.update(fairProductId, fairProductUpdateData, props.token)
            .then(r => {

            })
            .catch(e => {
                console.log(e);
            });
        if (stock !== 0 && stock <= total_instock && sold <= stock) {
            toastUpdateNotification();
            retrieveFair(initialFairId);
            retrieveFairProductPagination(currentPage, initialFairId);
        }

    }

    const saveAllProduct = () => {
        fairProducts.forEach(fairProduct => {
            const data = {
                product_id: fairProduct.product_id,
                fair_id: fairProduct.fair_id,
                stock: utils.getInt(fairProduct.stock),
                sold: utils.getInt(fairProduct.sold),
                price: utils.parseUSCurrencyInput(fairProduct.price),
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
            }
            fairProductDataService.update(fairProduct.fair_product_id, data, props.token)
            .then(r => {

            })
            .catch(e => {
                console.log(e);
            });

        })
        toastUpdateNotification();
        retrieveFair(initialFairId);
        retrieveFairProductPagination(currentPage, initialFairId);
    }

    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <Toast ref={toast} />
                        <ConfirmPopup/>
                        <h2>Chi Tiết Hội Chợ</h2>
                        <Card>
                            <Card.Body className="mb-3">
                                <Container fluid>
                                    <Row>
                                        <Col><Card.Title>{fairName} <br/> <Tag severity={statusTag} value={fairStatus}></Tag></Card.Title></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card.Text><b>Địa Chỉ: </b>{fairLocation}</Card.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card.Text><b>Gian Hàng: </b>{fairBoothLocation}</Card.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card.Text><b>Tổng Chi Phí: </b>{fair.total_fee?utils.formatCurrency(fair.total_fee, 'us'):utils.formatCurrency(0, 'us')}</Card.Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <Card.Text><b>Ngày Bắt Đầu: </b>{fairStartDate}</Card.Text>
                                        </Col>
                                        <Col>
                                        <Card.Text><b>Ngày Kết Thúc: </b>{fairEndDate}</Card.Text>
                                        </Col>

                                    </Row>
                                    <Row>
                                        {/*<Card.Text><b>Phí Tham Gia: </b>{fairApplicationFee} &nbsp;*/}
                                        {/*    /!*{*!/*/}
                                        {/*    /!*    paymentStatus === "Thanh Toán Lố"?<Tag severity="danger" value={paymentStatus} icon="pi pi-times"></Tag>:*!/*/}
                                        {/*    /!*        paymentStatus === "Chưa Thanh Toán Đủ"?<Tag severity="warning" value={paymentStatus} icon="pi pi-exclamation-triangle"></Tag>:*!/*/}
                                        {/*    /!*            <Tag severity="success" value={paymentStatus}></Tag>*!/*/}
                                        {/*    /!*}*!/*/}
                                        {/*</Card.Text>*/}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card.Text><b>Ghi Chú: </b><br/>{fair.notes}</Card.Text>
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                         <div>
                                    <Link to ={{
                                            pathname:"/fairs/",
                                        }}>
                                    <Button variant="primary" className="me-2"><FaArrowLeft/></Button>
                                    </Link>
                                    {
                                        props.role==='admin'?<Link to ={{
                                        pathname:"/fairs/" + fair.fair_id,
                                        state:{
                                            currentFair: fair,
                                            fromFair: true
                                        }
                                    }}>
                                    <Button variant="outline-info" className="me-2"><FaPencil /> &nbsp; Chỉnh Sửa</Button>
                                    </Link>:""
                                    }

                                </div>
                        <br/>
                        <Tabs defaultActiveKey={initialActiveTab}>
                            <Tab eventKey="0" title="Sản Phẩm">
                                <Tab.Content className='d-flex flex-column flex-grow-1'>
                                    <div className="flex flex-wrap align-items-center justify-content-center mb-3">
                                        <Link to ={{
                                            pathname:"/fairproducts/" + initialFairId,
                                            currentFairId: initialFairId
                                        }}>
                                           <Button variant="success" className="me-2">
                                                <FaPlus />&nbsp;Thêm Sản Phẩm
                                            </Button>
                                        </Link>
                                        <Button variant="warning" className="me-2" onClick={saveAllProduct}>
                                                <FaSave />&nbsp;Lưu Tất Cả Sản Phẩm
                                            </Button>

                                    </div>

                                    <DataPagination
                                    pageSize={pageSize}
                                    recordCount={recordCount}
                                    handlePagination={handlePagination}
                                    currentPage={currentPage}
                                    />
                                    <Table bordered hover>
                                        <thead>
                                        <tr>
                                            <th>Sản Phẩm</th>
                                            <th>Hình Mẫu</th>
                                            <th>Đơn Giá</th>
                                            <th>Số Lượng Trong Kho</th>
                                            <th>Số Lượng Mang Theo</th>
                                            <th>Số Lượng Bán</th>

                                        </tr>
                                        </thead>
                                        {
                                            fairProducts.length?
                                            fairProducts.map(({fair_product_id, product_id, product_name, instock_atm, stock, sold, price}) =>{
                                                return(
                                                    <tbody>
                                                        <tr key={fair_product_id}>
                                                            <td>{product_name}</td>
                                                            <td>
                                                                {/*<CommonImagePreviewHandler*/}
                                                                {/*    token={props.token}*/}
                                                                {/*    keyId={"product_id"}*/}
                                                                {/*    value={product_id}*/}
                                                                {/*/>*/}
                                                                <CommonGalleryDisplayHandler
                                                                    token={props.token}
                                                                    keyId={"product_id"}
                                                                    value={product_id}
                                                                />
                                                            </td>
                                                            <td>
                                                                {/*<Form.Control*/}
                                                                {/*    type="number"*/}
                                                                {/*    name="price"*/}
                                                                {/*    placeholder={price}*/}
                                                                {/*    required*/}
                                                                {/*    value={price}*/}
                                                                {/*    onChange={(e) => onChangeQuantity(e, fair_product_id)}*/}
                                                                {/*/>*/}
                                                                <USCurrencyInput value={price} name="price" onChange={(e) => onChangeQuantity(e, fair_product_id)}  type="text" className="border-solid border-1 p-2"/>
                                                            </td>
                                                            <td>{instock_atm}</td>
                                                            <td>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="stock"
                                                                    placeholder={stock}
                                                                    required
                                                                    isInvalid={!parseInt(stock) > 0 || utils.getInt(stock)  > instock_atm}
                                                                    value={stock}
                                                                    onChange={(e) => onChangeQuantity(e, fair_product_id)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    type="number"
                                                                    name="sold"
                                                                    placeholder={sold}
                                                                    required
                                                                    isInvalid={utils.getInt(sold)  > stock}
                                                                    value={sold}
                                                                    onChange={(e) => onChangeQuantity(e, fair_product_id)}
                                                                />
                                                            </td>

                                                            <td>
                                                                {/*<Link to ={{*/}
                                                                {/*    pathname:"/fairProducts/" + fairProduct.op_id,*/}
                                                                {/*    state:{*/}
                                                                {/*        currentOrderedProduct:fairProduct,*/}
                                                                {/*        currentOrder: order,*/}
                                                                {/*        currentVendorId: initialVendorId*/}
                                                                {/*    }*/}
                                                                {/*}}>*/}
                                                                {/*<Button variant="outline-info" className="me-2"><FaPencil/></Button>*/}
                                                                {/*</Link>*/}
                                                                <Button variant="outline-warning" className="me-2" onClick={(e) => saveQuantityFields(e, fair_product_id)}><FaFloppyDisk title="Cập Nhật"/></Button>
                                                                <Button variant="outline-danger" onClick={(e) => handleDeleteFairProduct(e, fair_product_id)}><FaTrashCan/></Button>
                                                            </td>


                                                        </tr>
                                                    </tbody>
                                                    )

                                            }):(
                                                <div className='d-flex justify-content-center'>
                                                    <br/>
                                                    <Alert variant="info">Chưa có sản phẩm</Alert>
                                                </div>)
                                        }

                                    </Table>
                                </Tab.Content>
                            </Tab>

                            <Tab eventKey="1" title="Thanh Toán">
                                <Tab.Content>
                                    {
                                        props.role === 'admin'?(
                                            <Link to={{
                                                pathname: "/payments/create",
                                                state:{
                                                    currentFair: fair,
                                                    isNew: true
                                                }}}>
                                                <Button variant="success" className="mb-3">
                                                    <FaDollarSign />&nbsp;Thanh Toán
                                                </Button>
                                            </Link>
                                        ):""
                                    }
                                    <PaymentListTemplate
                                        payments={allPayments}
                                        token={props.token}
                                        role={props.role}
                                        fromOrder={false}
                                        paid={0}
                                        remaining={0}
                                        fromShipment={false}
                                        currentOrder={null}
                                        currentShipment={null}
                                        fromFair={true}
                                        currentFair={fair}
                                        getCurrentPageInfo={getCurrentPageInfo}
                                    />


                                </Tab.Content>
                            </Tab>

                        </Tabs>


                        {/*<Formik validationSchema={schema} onSubmit={}>*/}

                        {/*</Formik>*/}

                    </div>

                )


            }
        </Container>
    )

}

export default ViewFair;