import React, {useEffect, useRef, useState} from 'react'
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import 'react-toastify/dist/ReactToastify.css'
import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup'; // To use confirmPopup method
import "primereact/resources/themes/lara-light-blue/theme.css";
import {FaFloppyDisk, FaPlus, FaPencil, FaTrash, FaCheck, FaSpinner, FaTrashCan} from "react-icons/fa6";
import productDataService from "../services/products";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import { Toast } from 'primereact/toast';
import productPhotoDataService from "../services/productphotos";

const ProductListTemplate = ({token, products, getCurrentPageInfo, searchProductTerm="", searchVendorTerm="",
                             searchCategoryTerm=""}) =>{
    const toast = useRef(null);

    const handleDeleteProduct = (event, productId) =>{
        confirmPopup({
            target: event.currentTarget,
            message: 'Bạn có chắc là bạn muốn xóa sản phẩm này chứ?',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteProduct(productId),
            acceptLabel: "Đồng Ý",
            rejectLabel: "Không"

        })
    }

    const deleteProduct = (product) =>{
        productDataService.delete(product, token)
        .then(response => {
            productPhotoDataService.getAllProductPhotos(token, product)
                .then(r=>{
                    const productPhotos = r.data;
                    productPhotos.forEach(photo => {
                        productPhotoDataService.delete(photo.product_photo_id, token)
                            .then(r=>{}).catch(e=>console.log(e));
                    })
                }).catch(e=>console.log(e));
            toast.current.show({ severity: 'info', detail: "Xóa sản phẩm thành công!" });
            getCurrentPageInfo();
        })
        .catch(e =>{
            console.log(e);
        });
    }

    return(
        <Container>
            {
                token==null||token===""?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <Toast ref={toast} />
                        <ConfirmPopup />
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>Tên Sản Phẩm</th>
                                    <th>Nhà Cung Cấp</th>
                                    <th>Tổng Số Trong Kho</th>
                                    <th>Loại Sản Phẩm</th>
                                </tr>

                            </thead>
                                {
                                    products.map((product) =>{
                                        return(
                                            <tbody>
                                                <tr key={product.product_id}>
                                                    <td><Link to ={{
                                                            pathname:"/product/"+ product.product_id + "/view",
                                                            state:{
                                                                currentProduct:product,
                                                                searchProductTerm: searchProductTerm,
                                                                searchVendorTerm: searchVendorTerm,
                                                                searchCategoryTerm: searchCategoryTerm
                                                            }
                                                        }}>
                                                        {product.name}
                                                        </Link></td>
                                                    <td>{product.vendor_name}</td>
                                                    <td>{product.in_stock_quantity}</td>
                                                    <td>{product.product_type}</td>
                                                    <td>
                                                        <Link to ={{
                                                            pathname:"/products/" + product.product_id,
                                                            state:{
                                                                currentProduct:product,
                                                                searchProductTerm: searchProductTerm,
                                                                searchVendorTerm: searchVendorTerm,
                                                                searchCategoryTerm: searchCategoryTerm
                                                            }
                                                        }}>
                                                        <Button variant="outline-info" className="me-2"><FaPencil title="Chỉnh XS"/></Button>
                                                        </Link>
                                                        <Button variant="outline-danger" onClick={(e) => handleDeleteProduct(e, product.product_id)}><FaTrashCan title="Xóa"/></Button>
                                                    </td>
                                                </tr>

                                            </tbody>

                                        )
                                    }

                                    )
                                }
                        </Table>
                    </div>
                )
            }

        </Container>
    )

}

export default ProductListTemplate