import React, {useEffect, useRef, useState} from "react";
import orderDataService from '../services/orders'
import vendorDataService from '../services/vendors';
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import orderedProductDataService from "../services/orderedproducts";
import paymentDataService from "../services/payments";
import {v4 as uuidv4} from 'uuid';
import {FaArrowLeft} from "react-icons/fa6";
import utils from "../services/utils";
import { AutoComplete } from "primereact/autocomplete";
import {InputGroup} from "react-bootstrap";
import VNCurrencyInput from "./vn-currency-input-mask";
import { Toast } from 'primereact/toast';

const AddOrder = props => {
    let editing = false;
    let initialDateEstimated = "";
    let initialNote= "";
    let initialDeposit=0;
    let initialDiscount=0;
    let initialOrderName = "";
    let initialVendor = "";
    let initialVendorName = "";
    let initialOrder = "";
    let fromVendor = false;
    let vendorFromVendor = '';
    let initialOrderId = "";
    let initialActiveKey = 0;
    let initialVendorSearchName = "";
    let initialFacebookHandler = "";
    let fromOrder = false;

    if(props.location.state && props.location.state.currentOrder){
        editing = true;
        initialDateEstimated = moment(props.location.state.currentOrder.estimated_date).format('yyyy-MM-DD');
        initialNote = props.location.state.currentOrder.notes;
        initialDeposit = utils.formatCurrency(props.location.state.currentOrder.deposit);
        initialDiscount = utils.formatCurrency(props.location.state.currentOrder.discount);
        initialOrderName = props.location.state.currentOrder.name;
        initialVendor = props.location.state.currentOrder.vendor_id;
        initialVendorName = props.location.state.currentOrder.vendor_name;
        initialFacebookHandler= props.location.state.currentOrder.vendor_facebook_handler;
        initialVendorSearchName = initialVendorName + "|" + initialFacebookHandler;
        initialOrder =props.location.state.currentOrder;
        initialOrderId = props.location.state.currentOrder.order_id;
        // fromVendor = props.location.state.fromVendor;
        fromOrder = props.location.state.fromOrder;
    }

    if(props.location.state && props.location.state.currentVendor){
        fromVendor = props.location.state.fromVendor;
        initialActiveKey = props.location.state.eventKey;
        initialVendor = props.location.state.currentVendor.vendor_id;
        initialVendorName = props.location.state.currentVendor.name;
        initialFacebookHandler = props.location.state.currentVendor.vendor_facebook_handler;
        initialVendorSearchName = initialVendorName + "|" + initialFacebookHandler;

    }

    const[vendors, setVendors] = useState([]);
    const[selectedVendor, setSelectedVendor] = useState(initialVendor);
    const[dateEstimated, setDateEstimated] = useState(initialDateEstimated);
    const[submitted, setSubmitted] = useState(false);
    const[deposit, setDeposit] = useState(initialDeposit);
    const[discount, setDiscount] = useState(initialDiscount);
    const[note, setNote] = useState(initialNote);
    const[orderName, setOrderName] = useState(initialOrderName);
    const [selectedVendorName, setSelectedVendorName] = useState(initialVendorSearchName);
    const [filteredVendors, setFilteredVendors] = useState(null);
    const [newOrderId, setNewOrderId] = useState("");
    // const [categories, setCategories] = useState([]);
    const toast = useRef(null);

    const toastErrorNotification = () => {
        toast.current.show({severity:'error', summary: 'Có Lỗi', detail:'Kiểm tra lại Tên Đơn Hàng và Nhà Cung Cấp', life: 2000});
    }


    useEffect(() =>{
        retrieveVendors();
    },[props.token]);

    const retrieveVendors = () =>{
        vendorDataService.getAll(props.token).then(response=>{
            setVendors(response.data);
        }).catch(e =>{
            console.log(e);
        });
    }

    const onChangeVendor = e => {
        setSelectedVendorName(e.target.value);
        // console.log('vendor:', e.target.value);
        const selectedVendorSearch = (e.target.value).split("|") ;
        const selectVendorName = selectedVendorSearch[0];
        const facebookHandler = selectedVendorSearch[1];
        const vendor = vendors.filter(vendor => vendor.name === selectVendorName && vendor.facebook_handler===facebookHandler);
        // console.log('vendor:', vendor);
        // console.log('vendor_id:', vendor[0].vendor_id);
        if(vendor.length>0)
            setSelectedVendor(vendor[0].vendor_id);
    }

    const onChangeDateEstimated = e => {
        const dateEstimated = e.target.value;
        setDateEstimated(dateEstimated);
    }

    const onChangeOrderName = e => {
        const orderName = e.target.value;
        setOrderName(orderName);
    }

    const onChangeNote = e =>{
        const note = e.target.value;
        setNote(note);
    }

    const onChangeDeposit = e => {
        const deposit = e.target.value;
        setDeposit(deposit);
    }

    const onChangeDiscount = e => {
        const discount = e.target.value;
        setDiscount(discount);
    }

    const search = (event) => {
        setTimeout(()=>{
            let _filteredVendorNames;
            const vendorNames = vendors.map(vendor => vendor.name + "|" + vendor.facebook_handler);
            if(!event.query.trim().length){

                _filteredVendorNames = [...vendorNames];
            }
            else{
                // console.log(event.query);
                _filteredVendorNames = vendorNames.filter((vendorName) =>{
                    return vendorName.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            setFilteredVendors(_filteredVendorNames);
        }, 250)
    }

    const saveOrder = (e) => {
        e.preventDefault();
        let data;
        if(editing){
            data = {
                vendor_id: selectedVendor,
                estimated_date: moment(dateEstimated).add(12, 'hours'),
                // is_complete: false,
                deposit: utils.parseVNCurrencyInput(deposit),
                notes: note,
                name: orderName,
                discount: utils.parseVNCurrencyInput(discount),
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
            }
            orderDataService.update(props.location.state.currentOrder.order_id, data, props.token).then(response =>{
                setSubmitted(true);
                const tmpDeposit = utils.parseVNCurrencyInput(deposit);
                // console.log('tmpDeposit: ', tmpDeposit);
                if (tmpDeposit !== utils.parseVNCurrencyInput(initialDeposit)){
                    paymentDataService.getAllPaymentsForOrder(props.token, props.location.state.currentOrder.order_id)
                        .then( r=> {
                            const payments = r.data;
                            const depositPayment = payments.filter(payment => payment.is_deposit === true);
                            // console.log('depositPayment: ', depositPayment);
                            if (tmpDeposit > 0){
                                if (depositPayment.length > 0){
                                    let paymentUpdateData = {
                                        payment_amount: tmpDeposit,
                                        last_modified_by: localStorage.getItem('username'),
                                        last_modified_date: moment(),
                                    }
                                    paymentDataService.update(depositPayment[0].payment_id, paymentUpdateData, props.token)
                                    .then(r => {

                                    })
                                    .catch(e => console.log(e));
                                }
                                else{
                                    let paymentUpdateData = {
                                        order_id: props.location.state.currentOrder.order_id,
                                        payment_amount: tmpDeposit,
                                        is_deposit: true,
                                        is_paid: false,
                                        last_modified_by: localStorage.getItem('username'),
                                        created_by: localStorage.getItem('username'),
                                     }
                                    paymentDataService.create(paymentUpdateData, props.token)
                                        .then(r => {

                                        })
                                        .catch(e => console.log(e));
                                }

                            }
                            else{
                                if (depositPayment.length > 0){
                                    paymentDataService.delete(depositPayment[0].payment_id, props.token)
                                    .then(r => {

                                    })
                                    .catch(e => console.log(e));
                                }

                            }


                        })
                }
            }).catch(e =>{
                console.log(e);
                toastErrorNotification();
            })

            orderedProductDataService.getAll(props.token, initialOrderId)
                .then( r=> {
                    const allOrderedProduct = r.data;
                    const vendorNotMatchOP = allOrderedProduct.filter(op => op.vendor_id !== selectedVendor);
                    vendorNotMatchOP.forEach(item => {
                        orderedProductDataService.delete(item.op_id, props.token).then(r=>{

                        })
                            .catch(e => console.log(e));
                    })
                })
                .catch(e => {
                    console.log(e);
                });
            
        }
        else{
            const order_id = uuidv4();
            setNewOrderId(order_id);
            data = {
                order_id: order_id,
                vendor_id: selectedVendor,
                estimated_date: moment(dateEstimated).add(12, 'hours'),
                is_complete: false,
                deposit: utils.parseVNCurrencyInput(deposit),
                discount: utils.parseVNCurrencyInput(discount),
                notes: note,
                name: orderName,
                created_by: localStorage.getItem('username'),
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
            }
            // console.log('data: ', data);
            orderDataService.create(data, props.token).then(response =>{
                setSubmitted(true);
                // const tmpDeposit = deposit === parseInt(deposit, 10)?deposit:(deposit.trim().length >0?parseInt(deposit.replace('.', '')): 0);
                const tmpDeposit = utils.parseVNCurrencyInput(deposit);
                if (tmpDeposit > 0){
                    let paymentUpdateData = {
                        order_id: order_id,
                        payment_amount: tmpDeposit,
                        is_deposit: true,
                        is_paid: false,
                        last_modified_by: localStorage.getItem('username'),
                        created_by: localStorage.getItem('username'),
                    }
                    paymentDataService.create(paymentUpdateData, props.token)
                        .then(r => {

                        })
                        .catch(e => console.log(e));

                }
            }).catch(e =>{
                console.log(e);
                toastErrorNotification();
            });
        }


    }

    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(submitted?(
                    editing?(
                        <div>
                        <h4>Đơn hàng cập nhật thành công!</h4>
                        <Link to ={{
                            pathname:"/order/"+ initialOrder.order_id + "/view",
                            state:{
                                currentOrder: initialOrder,
                                currentVendorId: selectedVendor
                            }
                        }}>
                        Quay lại đơn hàng {orderName}
                        </Link>
                        <br/>
                        <Link to={"/orders/"}>Hoặc đi tới danh sách đơn hàng</Link>
                        {
                            fromVendor?<Link to={"/vendor/"+ initialVendor+'/view'}>Hoặc quay lại trang chi tiết nhà cung cấp trước đó!</Link>:''
                        }
                    </div>
                    ):(
                        <div>
                        <h4>Đơn hàng tạo thành công</h4>
                            <Link to ={{
                                pathname:"/order/"+ newOrderId+ "/view",
                                state:{
                                    newOrderId: newOrderId,
                                    currentVendorId: selectedVendor
                                }
                            }}>
                            Quay lại đơn hàng {orderName}
                            </Link>
                            <br/>
                            <Link to={"/orders/"}>Quay lại danh sách đơn hàng</Link>
                    </div>
                    )
                ):(
                    <Form onSubmit={saveOrder}>
                        <Toast ref={toast} />
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Tên Đơn Hàng</InputGroup.Text>
                            <Form.Control
                                type='text'
                                placeholder="Nhập tên đơn hàng"
                                value={orderName}
                                onChange={onChangeOrderName}
                                isInvalid={orderName.length <=0}
                            />
                            <Form.Control.Feedback type="invalid">
                              Điền vào tên đơn hàng
                            </Form.Control.Feedback>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Nhà Cung Cấp*</InputGroup.Text>
                            <AutoComplete invalid={selectedVendorName.length<1} value={selectedVendorName} suggestions={filteredVendors} completeMethod={search} onChange={(e) => onChangeVendor(e)} dropdown />
                            <Form.Control.Feedback type="invalid">
                              Chọn nhà cung cấp!
                            </Form.Control.Feedback>
                        </InputGroup>
                        <InputGroup className="mb-3 xl:w-4">
                            <InputGroup.Text>Ngày Dự Kiến Hoàn Thành</InputGroup.Text>
                            <Form.Control
                                type='date'
                                placeholder="Nhập ngày dự kiến hoàn thành"
                                value={dateEstimated}
                                onChange={onChangeDateEstimated}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Tiền Đặt Cọc</InputGroup.Text>
                            <VNCurrencyInput value={deposit} onChange={onChangeDeposit} type="text" className="border-solid border-1 p-2"/>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Giảm Giá</InputGroup.Text>
                            <VNCurrencyInput value={discount} onChange={onChangeDiscount} type="text" className="border-solid border-1 p-2"/>
                        </InputGroup>
                        <Form.Group className="mb-3">
                            <Form.Label>Ghi Chú</Form.Label>
                            <Form.Control
                                as='textarea'
                                rows={3}
                                placeholder="Nhập ghi chú"
                                value={note}
                                onChange={onChangeNote}
                            />
                        </Form.Group>
                        {
                            fromVendor?(
                                <Link to ={{
                                    pathname:"/vendor/" + initialVendor + "/view",
                                    state:{
                                        currentVendor: props.location.state.currentVendor,
                                        eventKey: initialActiveKey
                                    }
                                    }}>
                                    <Button variant="outline-primary" className="me-2"><FaArrowLeft title="Quay Lại"/></Button>
                                </Link>
                            ):
                            (
                                fromOrder?(
                                    <Link to ={{
                                        pathname:"/order/" + initialOrderId + "/view",
                                        state:{
                                            currentOrder: initialOrder
                                        }
                                        }}>
                                    <Button variant="outline-primary" className="me-2"><FaArrowLeft title="Quay Lại"/></Button>
                                    </Link>
                                    ):
                                <Link to ={{
                                    pathname:"/orders/",
                                    }}>
                                <Button variant="outline-primary" className="me-2"><FaArrowLeft title="Quay Lại"/></Button>
                                </Link>
                            )
                        }
                        <Button variant="outline-warning" type="submit">{editing?"Cập Nhật":"Tạo Mới"}</Button>

                    </Form>
                ))
            }
        </Container>
    )

}

export default AddOrder;