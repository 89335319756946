import CommonDataService from './commondataservice';
import axios from "axios";

class PaymentDataService extends CommonDataService{
    async getAllPaymentsForOrder(token, orderId){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+ this.objectNames+ `/view?order_id=${orderId}`);
    }

    async getAllPaymentsForShipment(token, shipmentId){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+ this.objectNames+ `/view?shipment_id=${shipmentId}`);
    }

    async getAllPaymentsForUSShipment(token, shipmentId){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+ this.objectNames+ `/view?shipment_us_id=${shipmentId}`);
    }

    async getAllPaymentsForFair(token, fairId){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+ this.objectNames+ `/view?fairId=${fairId}`);
    }
}

const paymentDataService = new PaymentDataService('payments', 'payment');
export default paymentDataService;