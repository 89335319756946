import React, {useState, useEffect, useRef} from "react";
import productDataService from '../services/products';
import {Link} from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Alert from 'react-bootstrap/Alert';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import DataPagination from "./pagination";
import ProductListTemplate from "./product-list-template";
import categoryDataService from "../services/categories";
import {InputGroup} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import {AutoComplete} from "primereact/autocomplete";
import {FaSearch} from "react-icons/fa";
import {FaArrowLeft, FaPencil, FaPlus} from "react-icons/fa6";
import CommonImagePreviewHandler from "./common-image-preview-handler";
import fairProductDataService from "../services/fairproducts";
import { Toast } from 'primereact/toast';
import utils from "../services/utils";
import moment from "moment/moment";
import shippedProductDataService from "../services/shippedproducts";
import CommonGalleryDisplayHandler from "./common-gallery-preview-handler";

const AddFairProducts = (props) => {
    let isSearched = false;
    let initialFairId = ""
    if(props.location.state && props.location.state.currentFairId){
        initialFairId = props.location.state.currentFairId;
    }
    else{
        initialFairId = props.location.pathname.replace('/fairproducts/', '');
    }

    const[products, setProducts] = useState([]);
    const[productsDT, setProductsDT] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordCount, setRecordCount] = useState(0);
    // const [sellingPrice, setSellingPrice] = useState(0);
    // const [instockAtm, setInstockAtm] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [filteredCategories, setFilteredCategories] = useState(null);
    const [categories, setCategories] = useState([]);
    const [productSearchTerm, setProductSearchTerm] = useState("");
    const [isSearchState, setIsSearchState] = useState(isSearched);
    const [fairProducts, setFairProducts] = useState([]);
    const pageSize = 10;
    const toast = useRef(null);


    useEffect(() =>{
        retrieveCategories();
        // retrieveProducts();
        // retrieveFairProduct(initialFairId);
        if (productSearchTerm.trim().length !== 0 || selectedCategory !== null){
            // retrieveSearchProducts();
            // retrieveProducts();
            retrieveSearchProductsDT(initialFairId);
        }
        else{
            // retrieveProductsPagination(currentPage);
            // retrieveProducts();
            retrieveProducts(initialFairId);
        }
    }, [props.token, currentPage]);


    // const getCurrentPageInfo = () =>{
    //     retrieveCategories();
    //     retrieveFairProduct(initialFairId);
    //     if (productSearchTerm.trim().length !== 0 || selectedCategory !== null){
    //         retrieveSearchProducts();
    //     }
    //     else{
    //         retrieveProductsPagination(currentPage);
    //     }
    // }

    const getCurrentPageInfoDT = () =>{
        retrieveCategories();
        if (productSearchTerm.trim().length !== 0 || selectedCategory !== null){
            retrieveSearchProductsDT(initialFairId);
        }
        else{
            retrieveProducts(initialFairId);
        }
    }


    // const retrieveProductsPagination = (currentPage) =>{
    //     productDataService.getDataPagination(currentPage,props.token)
    //     .then(response => {
    //         setProducts(response.data.results);
    //         setRecordCount(response.data.count);
    //     })
    //     .catch(e=>{
    //         console.log(e);
    //     });
    // }



    // const retrieveFairProduct = (fairId) =>{
    //     fairProductDataService.getAll(props.token, fairId)
    //     .then(response => {
    //         // console.log('fair product', response.data);
    //         const fairProducts = response.data;
    //         const productIds = fairProducts.map(fairProduct => fairProduct.product_id);
    //         setFairProducts(productIds);
    //     })
    //     .catch(e=>{
    //         console.log(e);
    //     });
    // }

    const clearSearch = () => {
        setSelectedCategory("");
        setProductSearchTerm("");
        setIsSearchState(false);
        // console.log('productSearchTerm: ', productSearchTerm);
        // console.log('selectedCategory: ', selectedCategory);
        // getCurrentPageInfo();
        // retrieveProductsPagination(currentPage);
        retrieveProducts(initialFairId);
        setSelectedCategory("");
        // console.log('selectedCategory: ', selectedCategory);
    }
    // const deleteProduct = (product) =>{
    //     productDataService.delete(product, props.token)
    //     .then(response => {
    //         retrieveProductsPagination();
    //     })
    //     .catch(e =>{
    //         console.log(e);
    //     });
    // }
    //
    // const handlePagination = (currentPage) => {
    //     // console.log(recordCount/pageSize);
    //     // if(Math.ceil(recordCount/pageSize) < currentPage)
    //     //     setCurrentPage(1);
    //     setCurrentPage(currentPage);
    // }

    const onChangeProductSearchTerm = e => {
        const searchTerm = e.target.value;
        setProductSearchTerm(searchTerm);
    }

    const retrieveCategories = () => {
        categoryDataService.getAll(props.token)
            .then( r=>{
                // console.log(r.data);
                setCategories(r.data);
            })
            .catch(e=> console.log(e));
    }

    const addFairProduct = (productId, instockAtm, sellingPrice, stock=0) => {
        const data = {
            fair_id: initialFairId,
            product_id: productId,
            instock_atm: instockAtm,
            stock: stock,
            price: utils.parseUSCurrencyInput(sellingPrice)
        }
        console.log('data',data);
        fairProductDataService.create(data, props.token)
            .then(r=>{
                toast.current.show({ severity: 'success', detail: "Thêm sản phẩm thành công!" });
                // getCurrentPageInfo();
                getCurrentPageInfoDT();
            })
            .catch(e => console.log(e));
    }

    // const onClickSearch = () => {
    //     setCurrentPage(1);
    // }

    const searchCategory = (event) => {
        setTimeout(()=>{
            let _filteredCategories;
            const categoriesName = categories.map(category => category.name)
            if(!event.query.trim().length){

                _filteredCategories = [...categoriesName];
            }
            else{
                console.log(event.query);
                _filteredCategories = categoriesName.filter((category) =>{
                    return category.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            setFilteredCategories(_filteredCategories);
        }, 250)
    }

    // const retrieveSearchProducts = () => {
    //     if (productSearchTerm.trim().length !== 0 || selectedCategory !== null){
    //             productDataService.getSearchProductDataPagination(currentPage, props.token, productSearchTerm, "",selectedCategory)
    //             .then( r=> {
    //                 const data = r.data;
    //                 setProducts(data.results);
    //                 setRecordCount(data.count);
    //                 setIsSearchState(true);
    //             })
    //             .catch(e => {
    //                 console.log(e);
    //                 setCurrentPage(1);
    //                 productDataService.getSearchProductDataPagination(1, props.token, productSearchTerm, "",selectedCategory)
    //                     .then( r=> {
    //                         const data = r.data;
    //                         // console.log(data.results);
    //                         setProducts(data.results);
    //                         // console.log(data.count);
    //                         setRecordCount(data.count);
    //                         // console.log(data.count/pageSize);
    //                         setIsSearchState(true);
    //                     })
    //                     .catch(e => console.log(e));
    //             });
    //
    //         }
    //     else{
    //         retrieveProductsPagination(currentPage);
    //         setIsSearchState(false);
    //     }
    //
    // }


    const retrieveSearchProductsDT = (fairId) => {
        if (productSearchTerm.trim().length !== 0 || selectedCategory !== null){
                productDataService.getSearchProduct(props.token, productSearchTerm, "",selectedCategory)
                .then( r=> {
                    let productsDT = r.data;
                    // console.log('productsDT', productsDT);
                    fairProductDataService.getAll(props.token, fairId)
                        .then(response => {
                            // console.log('fair product', response.data);
                            const fairProducts = response.data;
                            const productIds = fairProducts.map(fairProduct => fairProduct.product_id);
                            productIds.forEach(productId => {
                                productsDT = productsDT.filter(productDT => productDT.product_id!==productId && productDT.in_stock_quantity > 0)
                            })
                            setFairProducts(productIds);
                            setProductsDT(productsDT);
                            setIsSearchState(true);
                        })
                        .catch(e=>{
                            console.log(e);
                        });
                })
                .catch(e => {
                    console.log(e);
                });

            }
        else{
            retrieveProducts(fairId);
            setIsSearchState(false);
        }

    }
    // test prime react data table
    const retrieveProducts = (fairId) =>{
        productDataService.getAll(props.token)
        .then(response => {
            let productsDT = response.data;
            fairProductDataService.getAll(props.token, fairId)
                .then(response => {
                    // console.log('fair product', response.data);
                    const fairProducts = response.data;
                    const productIds = fairProducts.map(fairProduct => fairProduct.product_id);
                    productIds.forEach(productId => {
                        productsDT = productsDT.filter(productDT => productDT.product_id!==productId && productDT.in_stock_quantity > 0)
                    })
                    setFairProducts(productIds);
                    setProductsDT(productsDT);
                })
                .catch(e=>{
                    console.log(e);
                });

        })
        .catch(e=>{
            console.log(e);
        });
    }

    const onRowEditComplete = (e) => {
        let _products = [...productsDT];
        let { newData, index } = e;
        _products[index] = newData;

        setProductsDT(_products);
        addFairProduct(newData["product_id"], utils.getInt(newData["in_stock_quantity"]),newData["selling_price"], newData["fair_stock"]);
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const priceEditor = (options) => {
        return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} mode="currency" currency="USD" locale="en-US" />;
    };

    const productImage = (options) => {
        return <CommonGalleryDisplayHandler
                    token={props.token}
                    keyId={"product_id"}
                    value={options.product_id}
                />;
    };

    const priceBodyTemplate = (rowData) => {
        return utils.formatCurrency(rowData.selling_price, 'us');
    };

    //
    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <div>
                        <div className="flex flex-wrap align-items-center justify-content-center mb-3">
                            <Link to ={{
                                pathname:"/fair/" + initialFairId + "/view",
                                }}>
                                <Button variant="primary" className="me-2 mr-8"><FaArrowLeft/></Button>
                            </Link>
                            <h2>Thêm Sản Phẩm Vào Hội Chợ</h2>
                        </div>

                        <Toast ref={toast} />
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Tên Sản Phẩm</InputGroup.Text>
                            <Form.Control
                                placeholder="Tên Sản Phẩm"
                                value={productSearchTerm}
                                onChange={onChangeProductSearchTerm}
                            />
                            <InputGroup.Text>Phân Loại Sản Phẩm</InputGroup.Text>
                            <AutoComplete value={selectedCategory} suggestions={filteredCategories} completeMethod={searchCategory} onChange={(e) => setSelectedCategory(e.value)} dropdown />
                            <Button onClick={(e) => retrieveSearchProductsDT(initialFairId)}><FaSearch/></Button>
                        </InputGroup>
                        {
                            isSearchState?<Button onClick={clearSearch}>Xóa Tìm Kiếm</Button>:""
                        }
                        {/*<DataPagination*/}
                        {/*pageSize={pageSize}*/}
                        {/*recordCount={recordCount}*/}
                        {/*handlePagination={handlePagination}*/}
                        {/*currentPage={currentPage}*/}
                        {/*/>*/}
                        {/*<Table bordered hover>*/}
                        {/*    <thead>*/}
                        {/*        <tr>*/}
                        {/*            <th>Tên Sản Phẩm</th>*/}
                        {/*            <th>Hình Mẫu</th>*/}
                        {/*            <th>Nhà Cung Cấp</th>*/}
                        {/*            <th>Số Lượng Trong Kho</th>*/}
                        {/*            <th>Loại Sản Phẩm</th>*/}
                        {/*            <th></th>*/}
                        {/*        </tr>*/}

                        {/*    </thead>*/}
                        {/*        {*/}
                        {/*            products.map((product) =>{*/}
                        {/*                return(*/}
                        {/*                    <tbody>*/}
                        {/*                        <tr key={product.product_id}>*/}
                        {/*                            <td>{product.name}</td>*/}
                        {/*                            <td>*/}
                        {/*                                <CommonImagePreviewHandler*/}
                        {/*                                    token={props.token}*/}
                        {/*                                    keyId={"product_id"}*/}
                        {/*                                    value={product.product_id}*/}
                        {/*                                />*/}
                        {/*                            </td>*/}
                        {/*                            <td>{product.vendor_name}</td>*/}
                        {/*                            <td>{product.in_stock_quantity}</td>*/}
                        {/*                            <td>{product.product_type}</td>*/}
                        {/*                            <td>*/}
                        {/*                                {*/}
                        {/*                                    product.in_stock_quantity>0?fairProducts.includes(product.product_id)?<i className="pi pi-check" style={{ fontSize: '1.5rem' }}></i>:<Button variant="outline-success" className="me-2" onClick={(e)=> addFairProduct(product.product_id,*/}
                        {/*                                    product.in_stock_quantity, product.selling_price)}><FaPlus title="Thêm"/></Button>:<i className="pi pi-times" style={{ fontSize: '1.5rem' }}></i>*/}
                        {/*                                }*/}
                        {/*                            </td>*/}
                        {/*                        </tr>*/}

                        {/*                    </tbody>*/}

                        {/*                )*/}
                        {/*            }*/}

                        {/*            )*/}
                        {/*        }*/}
                        {/*</Table>*/}
                        <div className="card p-fluid">
                        <DataTable value={productsDT} editMode="row" dataKey="product_id" onRowEditComplete={onRowEditComplete} paginator rows={10} rowsPerPageOptions={[10, 25, 50]}
                                   tableStyle={{ minWidth: '50rem' }}>
                            <Column field="name" header="Tên Sản Phẩm" sortable></Column>
                            <Column field="vendor_name" header="Nhà Cung Cấp" sortable></Column>
                            <Column field="product_id" header="Hình Mẫu" body={productImage}></Column>
                            <Column field="in_stock_quantity" header="Số Lượng Trong Kho" sortable></Column>
                            <Column field="fair_stock" header="Số Lượng Mang Theo" editor={(options) => textEditor(options)}></Column>
                            <Column field="selling_price" header="Giá Bán" sortable body={priceBodyTemplate} editor={(options) => priceEditor(options)}></Column>
                            <Column rowEditor={true} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                        </DataTable>
                        </div>
                    </div>
                )
            }

        </Container>
    )
}



export default AddFairProducts;