import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import {v4 as uuidv4} from 'uuid';
import {FaArrowLeft} from "react-icons/fa6";
import utils from "../services/utils";
import { AutoComplete } from "primereact/autocomplete";
import {InputGroup} from "react-bootstrap";
import USCurrencyInput from "./us-currency-input-mask";
import fairDataService from "../services/fairs";

const AddFair = props => {
    let editing = false;
    let initialStartDate = "";
    let initialEndDate = "";
    let initialNote= "";
    let initialApplicationFee=0;
    let initialFairName = "";
    let initialFairLocation = "";
    let initialFair = "";
    let initialFairId = "";
    let initialBooth = ""
    let fromFair = false;
    let initialStatus = "New";

    if(props.location.state && props.location.state.currentFair){
        editing = true;
        initialStartDate = utils.formatDatePicker(props.location.state.currentFair.start_date);
        initialEndDate = utils.formatDatePicker(props.location.state.currentFair.end_date);
        initialNote = props.location.state.currentFair.notes;
        initialApplicationFee = utils.formatCurrency(props.location.state.currentFair.application_fee, 'us');
        initialFairName = props.location.state.currentFair.name;
        initialFairLocation = props.location.state.currentFair.location;
        initialFair =props.location.state.currentFair;
        initialFairId = props.location.state.currentFair.fair_id;
        initialBooth = props.location.state.currentFair.booth_name;
        // fromVendor = props.location.state.fromVendor;
        fromFair = props.location.state.fromFair;
        initialStatus = props.location.state.currentFair.status;
    }

    const[startDate, setStartDate] = useState(initialStartDate);
    const[endDate, setEndDate] = useState(initialEndDate);
    const [boothLocation, setBoothLocation] = useState(initialBooth);
    const [fairLocation, setFairLocation] = useState(initialFairLocation);
    const[submitted, setSubmitted] = useState(false);
    const[applicationFee, setApplicationFee] = useState(initialApplicationFee);
    const[note, setNote] = useState(initialNote);
    const[fairName, setFairName] = useState(initialFairName);
    const[fairStatus, setFairStatus] = useState(initialStatus);
    const [newFairId, setNewFairId] = useState("");
    // const [categories, setCategories] = useState([]);


    useEffect(() =>{
        // retrieveVendors();
    },[props.token]);


    const onChangeStartDate = e => {
        const date = e.target.value;
        setStartDate(date);
    }

    const onChangeEndDate = e => {
        const date = e.target.value;
        setEndDate(date);
    }

    const onChangeFairName = e => {
        const name = e.target.value;
        setFairName(name);
    }

    const onChangeFairLocation = e => {
        const location = e.target.value;
        setFairLocation(location);
    }

    const onChangeBoothLocation = e => {
        const location = e.target.value;
        setBoothLocation(location);
    }

    const onChangeNote = e =>{
        const note = e.target.value;
        setNote(note);
    }

    const onChangeStatus = e =>{
        const status = e.target.value;
        // console.log('status', status)
        setFairStatus(status);
    }

    const onChangeApplicationFee = e => {
        const applicationFee = e.target.value;
        setApplicationFee(applicationFee);
    }


    const saveFair = (e) => {
        e.preventDefault();
        let data;
        if(editing){
            data = {
                start_date: moment(startDate).add(5, 'hours'),
                end_date: moment(endDate).add(5, 'hours'),
                // is_complete: false,
                // application_fee: utils.parseUSCurrencyInput(applicationFee),
                location: fairLocation,
                booth_name: boothLocation,
                notes: note,
                name: fairName,
                status: fairStatus,
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
            }
            // console.log('data: ', data);
            fairDataService.update(props.location.state.currentFair.fair_id, data, props.token).then(response =>{
                setSubmitted(true);
            }).catch(e =>{
                console.log(e);
            })

        }
        else{
            const fair_id = uuidv4();
            setNewFairId(fair_id);
            data = {
                fair_id: fair_id,
                start_date: moment(startDate).add(5, 'hours'),
                end_date: moment(endDate).add(5, 'hours'),
                // is_complete: false,
                // application_fee: utils.parseUSCurrencyInput(applicationFee),
                location: fairLocation,
                booth_name: boothLocation,
                notes: note,
                name: fairName,
                status: fairStatus,
                created_by: localStorage.getItem('username'),
                last_modified_by: localStorage.getItem('username'),
                last_modified_date: moment(),
            }
            // console.log('data: ', data);
            fairDataService.create(data, props.token).then(response =>{
                setSubmitted(true);
                // const tmpDeposit = applicationFee === parseInt(applicationFee, 10)?applicationFee:(applicationFee.trim().length >0?parseInt(applicationFee.replace('.', '')): 0);
                // const tmpDeposit = utils.parseVNCurrencyInput(applicationFee);
                // if (tmpDeposit > 0){
                //     let paymentUpdateData = {
                //         order_id: fair_id,
                //         payment_amount: tmpDeposit,
                //         is_deposit: true,
                //         is_paid: false,
                //         last_modified_by: localStorage.getItem('username'),
                //         created_by: localStorage.getItem('username'),
                //     }
                //     paymentDataService.create(paymentUpdateData, props.token)
                //         .then(r => {
                //
                //         })
                //         .catch(e => console.log(e));
                //
                // }
            }).catch(e =>{
                console.log(e);
            });
        }


    }

    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(submitted?(
                    editing?(
                        <div>
                        <h4>Cập nhật thông tin hội chợ thành công!</h4>
                        <Link to ={{
                            pathname:"/fair/"+ initialFairId + "/view",
                            state:{
                                currentFair: initialFair,
                            }
                        }}>
                        Quay lại hội chợ: {fairName}
                        </Link>
                        <br/>
                        <Link to={"/fairs/"}>Hoặc đi tới danh sách hội chợ</Link>
                    </div>
                    ):(
                        <div>
                        <h4>Hội chợ được tạo thành công</h4>
                            <Link to ={{
                                pathname:"/fair/"+ newFairId+ "/view",
                                state:{
                                    newFairId: newFairId,
                                }
                            }}>
                            Đi tới hội chợ: {fairName}
                            </Link>
                            <br/>
                            <Link to={"/fairs/"}>Quay lại danh sách hội chợ</Link>
                    </div>
                    )
                ):(
                    <Form onSubmit={saveFair}>
                        {/*<Form.Group className="mb-3">*/}
                        {/*    <Form.Label>Tên Đơn Hàng</Form.Label>*/}
                        {/*    <Form.Control*/}
                        {/*        type='text'*/}
                        {/*        placeholder="Nhập tên đơn hàng"*/}
                        {/*        value={fairName}*/}
                        {/*        onChange={onChangeFairName}*/}
                        {/*    />*/}
                        {/*</Form.Group>*/}
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Tên Hội Chợ</InputGroup.Text>
                            <Form.Control
                                type='text'
                                placeholder="Nhập tên hội chợ"
                                value={fairName}
                                isInvalid={fairName.length<=0}
                                onChange={onChangeFairName}
                            />
                            <Form.Control.Feedback type="invalid">
                                Điền vào tên hội chợ.
                              </Form.Control.Feedback>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Trạng Thái</InputGroup.Text>
                            <Form.Select defaultValue={fairStatus} onChange={onChangeStatus}>
                                  <option value="New">Mới</option>
                                  <option value="Accepted">Được Chấp Nhận</option>
                                  <option value="Waitlisted">Trong Danh Sách Chờ</option>
                                  <option value="Rejected">Bị Từ Chối</option>
                            </Form.Select>
                        </InputGroup>
                        <InputGroup className="mb-3 xl:w-4">
                            <InputGroup.Text>Ngày Bắt Đầu</InputGroup.Text>
                            <Form.Control
                                type='date'
                                placeholder=""
                                value={startDate}
                                isInvalid={startDate.length<=0}
                                onChange={onChangeStartDate}
                            />
                            <Form.Control.Feedback type="invalid">
                                Chọn ngày bắt đầu.
                              </Form.Control.Feedback>
                        </InputGroup>
                        <InputGroup className="mb-3 xl:w-4">
                            <InputGroup.Text>Ngày Kết Thúc</InputGroup.Text>
                             <Form.Control
                                type='date'
                                placeholder="Nhập ngày kết thúc"
                                value={endDate}
                                isInvalid={endDate < startDate}
                                onChange={onChangeEndDate}
                            />
                            <Form.Control.Feedback type="invalid">
                              Ngày kết thúc không nên nhỏ hơn ngày bắt đầu
                            </Form.Control.Feedback>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Địa Chỉ</InputGroup.Text>
                            <Form.Control
                                type='text'
                                placeholder="Nhập địa chỉ hội chợ"
                                value={fairLocation}
                                onChange={onChangeFairLocation}
                            />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text>Gian Hàng</InputGroup.Text>
                            <Form.Control
                                type='text'
                                placeholder="Nhập tên gian hàng"
                                value={boothLocation}
                                onChange={onChangeBoothLocation}
                            />
                        </InputGroup>
                        {/*<InputGroup className="mb-3">*/}
                        {/*    <InputGroup.Text>Tiền Phí</InputGroup.Text>*/}
                        {/*    <USCurrencyInput value={applicationFee} onChange={onChangeApplicationFee} type="text" className="border-solid border-1 p-2"/>*/}
                        {/*</InputGroup>*/}
                        <Form.Group className="mb-3">
                            <Form.Label>Ghi Chú</Form.Label>
                            <Form.Control
                                as='textarea'
                                rows={3}
                                placeholder="Nhập ghi chú"
                                value={note}
                                onChange={onChangeNote}
                            />
                        </Form.Group>
                        {
                                fromFair?(
                                    <Link to ={{
                                        pathname:"/fair/" + initialFairId + "/view",
                                        state:{
                                            cuurentFair: initialFair
                                        }
                                        }}>
                                    <Button variant="outline-primary" className="me-2"><FaArrowLeft title="Quay Lại"/></Button>
                                    </Link>
                                    ):
                                <Link to ={{
                                    pathname:"/fairs/",
                                    }}>
                                <Button variant="outline-primary" className="me-2"><FaArrowLeft title="Quay Lại"/></Button>
                                </Link>
                        }
                        <Button variant="outline-warning" type="submit">
                                {/*onClick={saveFair}>*/}
                            {editing?"Cập Nhật":"Tạo Mới"}</Button>

                    </Form>
                ))
            }
        </Container>
    )

}

export default AddFair;