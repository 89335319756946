import CommonDataService from './commondataservice';
import axios from "axios";

class PaymentPhotoDataService extends CommonDataService{
    async getAllPaymentProductPhotos(token, paymentId){
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        return await axios.get(this.baseURL+`${this.objectNames}/view?payment_id=${paymentId}`, {timeout:5000})
    }
}

const paymentPhotoDataService = new PaymentPhotoDataService('paymentphotos', 'paymentphoto');
export default paymentPhotoDataService;