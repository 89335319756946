import React, {useEffect, useState} from "react";
import categoryDataService from "../services/categories";
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import { useHistory } from 'react-router-dom';
import utils from "../services/utils";

const EditCategory = props => {
    let initialName = "";
    let initialCategoryId = "";
    let initialNote = "";

    if(props.location.state && props.location.state.currentCategory){
        initialName = props.location.state.currentCategory.name;
        initialCategoryId = props.location.state.currentCategory.category_id;
        initialNote = props.location.state.currentCategory.notes;
    }
    else{
        initialCategoryId = props.location.pathname.replace('/category/', '').replace('/view', '');
    }

    const[name, setName] = useState(initialName);
    const[note, setNote] = useState(initialNote);
    const history = useHistory();


    useEffect(() =>{
        retrieveCategory();
    },[props.token]);

    const retrieveCategory = () =>{
        categoryDataService.getOne(initialCategoryId, props.token).then(response=>{
            const category = response.data;
            setName(category.name);
            setNote(category.notes);
        }).catch(e =>{
            console.log(e);
        });
    }


    const onChangeName = e => {
        const name = e.target.value;
        setName(name);
    }

    const onChangeNote = e => {
        const note = e.target.value;
        setNote(note);
    }


    const saveCategory = () => {
        let data = {
            name: name,
            notes: note,
            last_modified_by: localStorage.getItem('username'),
            last_modified_date: moment(),
        }
        // console.log(data);
        categoryDataService.update(initialCategoryId, data, props.token).then(response =>{
        }).catch(e =>{
            console.log(e);
        });
        history.push('/categories');
    }

    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Phân Loại</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder="Nhập phân loại"
                                value={name}
                                onChange={onChangeName}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Ghi Chú</Form.Label>
                            <Form.Control
                                as='textarea'
                                rows={3}
                                placeholder="Nhập ghi chú"
                                value={note}
                                onChange={onChangeNote}
                            />
                        </Form.Group>
                        <Button variant="info" onClick={saveCategory}>Cập Nhật</Button>
                    </Form>
                )


            }
        </Container>
    )

}

export default EditCategory;