import React, {useEffect, useState} from "react";
import shipmentDataService from "../services/shipments";
import vendorDataService from '../services/vendors';
import {Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import utils from "../services/utils";

const AddShipment = props => {
    let editing = false;
    let initialNote= "";
    let initialVendor = "";
    let initialVendorName = "";
    let initialLabel = ""


    const[vendors, setVendors] = useState([]);
    const[selectedVendor, setSelectedVendor] = useState(initialVendor);
    const[submitted, setSubmitted] = useState(false);
    const[note, setNote] = useState(initialNote);
    const[shippingLabel, setShippingLabel] = useState(initialLabel);
    const[shippingCost, setShippingCost] = useState(0);


    useEffect(() =>{
        retrieveVendors();
    },[props.token]);

    const retrieveVendors = () =>{
        vendorDataService.getAll(props.token).then(response=>{
            setVendors(response.data);
        }).catch(e =>{
            console.log(e);
        });
    }

    const onChangeVendor = e => {
        const selectedVendor = e.target.value;
        setSelectedVendor(selectedVendor);
    }

    const onChangeLabel = e => {
        const label = e.target.value;
        setShippingLabel(label);
    }


    const onChangeNote = e =>{
        const note = e.target.value;
        setNote(note);
    }

    const onChangeShippingCost = e =>{
        const cost = e.target.value;
        setShippingCost(cost);
    }

    const saveShipment = () => {
        var data;
        data = {
            vendor_id: selectedVendor,
            notes: note,
            label: shippingLabel,
            created_by: localStorage.getItem('username'),
            last_modified_by: localStorage.getItem('username'),
            last_modified_date: moment(),
        }
        shipmentDataService.create(data, props.token).then(response =>{
            setSubmitted(true);
        }).catch(e =>{
            console.log(e);
        });
    }

    return(
        <Container>
            {
                utils.checkTokenExpired(props.token, props.tokenExpiredDate)?(
                    <Alert variant="warning">Bạn chưa đăng nhập. Hãy <Link to={"/login"}>đăng nhập</Link> để tiếp tục!</Alert>
                ):(submitted?(
                    <div>
                        <h4>Sản phẩm {editing?"được cập nhật":"được tạo"} thành công</h4>
                        <Link to={"/shipments/"}>Quay lại danh sách đơn vận chuyển</Link>
                    </div>
                ):(
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Tên Đơn Vận Chuyển</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder="Nhập tên đơn vận chuyển"
                                value={shippingLabel}
                                onChange={onChangeLabel}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Nhà Cung Cấp</Form.Label>
                            <Form.Select
                                required
                                value={selectedVendor}
                                onChange={onChangeVendor}
                                defaultValue={initialVendorName?initialVendorName:"...Chọn Nhà Cung Cấp..."}>
                                    <option>...Chọn Nhà Cung Cấp...</option>
                                    {
                                        vendors.map((vendor) =>{
                                            return(
                                                <option value={vendor.vendor_id} key={vendor.vendor_id} data-key={vendor.vendor_id}>{vendor.name}</option>
                                            )
                                        }
                                        )
                                    }

                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Chi Phí Vận Chuyển</Form.Label>
                            <Form.Control
                                type='number'
                                placeholder="Nhập chi phí vận chuyển"
                                value={shippingCost}
                                onChange={onChangeShippingCost}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Ghi Chú</Form.Label>
                            <Form.Control
                                as='textarea'
                                rows={3}
                                placeholder="Nhập ghi chú"
                                value={note}
                                onChange={onChangeNote}
                            />
                        </Form.Group>
                        <Button variant="success" onClick={saveShipment}>Tạo Mới</Button>
                    </Form>
                ))


            }
        </Container>
    )

}

export default AddShipment;