import React from 'react';
// import ReactDOM from 'react-dom/client';
import { PrimeReactProvider } from 'primereact/api';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter} from 'react-router-dom';
import 'primeflex/primeflex.css';
// import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/themes/saga-green/theme.css";
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import '../src/css/flag.css'
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>

//   </React.StrictMode>
// );
ReactDOM.render(
    <React.StrictMode>
    <BrowserRouter>
        <PrimeReactProvider>
            <App />
        </PrimeReactProvider>
    </BrowserRouter>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
