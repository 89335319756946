import React, {useRef} from 'react'
import {Link} from "react-router-dom";
import moment from "moment/moment";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import 'react-toastify/dist/ReactToastify.css'
import { ConfirmPopup } from 'primereact/confirmpopup'; // To use <ConfirmPopup> tag
import { confirmPopup } from 'primereact/confirmpopup'; // To use confirmPopup method
import "primereact/resources/themes/lara-light-blue/theme.css";
import {FaFloppyDisk, FaPlus, FaPencil, FaTrash, FaCheck, FaSpinner, FaTrashCan} from "react-icons/fa6";
import { Toast } from 'primereact/toast';
import Container from "react-bootstrap/Container";
import utils from "../services/utils";
import fairDataService from "../services/fairs";
import fairProductDataService from "../services/fairproducts";
import paymentDataService from "../services/payments";
import paymentPhotoDataService from "../services/paymentphotos";

const FairListTemplate = ({fairs, role, token, getCurrentPageInfo}) => {
    const toast = useRef(null);

    // const completeOrder = (orderId, is_complete) => {
    //     orderDataService.complete(orderId, token)
    //     .then(response => {
    //         if(is_complete)
    //             // toastIncompleteNotification();
    //             toast.current.show({ severity: 'info', detail: "Chuyển đơn hàng sang \"Chưa Hoàn Thành\" thành công!" });
    //         else
    //             toast.current.show({ severity: 'info', detail: "Chuyển đơn hàng sang \"Đã Hoàn Thành\" thành công!" });
    //         getCurrentPageInfo();
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }

    const deleteFair = (fairId) =>{
        fairDataService.delete(fairId, token)
        .then(response => {
            fairProductDataService.getAll(token, fairId)
                .then (r => {
                    const fps = r.data;
                    fps.forEach(fp => {
                        fairProductDataService.delete(fp.fair_product_id, token)
                            .then(r => {

                            })
                            .catch(e => console.log(e))
                    })
                })
                .catch(e => console.log(e));
            paymentDataService.getAllPaymentsForFair(token, fairId)
                .then(r =>{
                    const allPayments = r.data;
                    allPayments.forEach(payment => {
                        paymentDataService.delete(payment.payment_id, token)
                            .then( r=>{
                                 paymentPhotoDataService.getAllPaymentProductPhotos(token, payment.payment_id)
                                    .then( r=>{
                                        const paymentPhotos = r.data;
                                        // console.log('paymentPhotos:', paymentPhotos);
                                        paymentPhotos.forEach((paymentPhoto) => {
                                            paymentPhotoDataService.delete(paymentPhoto.payment_photo_id, token)
                                                .then(e=>{

                                                })
                                                .catch(e=> console.log(e));
                                        })
                                        }
                                    )
                                    .catch(e => console.log(e));

                            })
                            .catch( e=> console.log(e));
                    })
                })
                .catch(e => console.log(e));

            toast.current.show({ severity: 'info', detail: "Xóa hội chợ thành công!" });
            getCurrentPageInfo();
        })
        .catch(e =>{
            console.log(e);
        });
    }

    const deleteFairConfirmation = (event, fairId) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Bạn thật sự muốn xóa hội chợ này',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteFair(fairId),
        })
    }
    return(
        <Container>
            <Toast ref={toast} />
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Tên Hội Chợ</th>
                        <th>Ngày Bắt Đầu</th>
                        <th>Ngày Kết Thúc</th>
                        <th></th>
                    </tr>

                </thead>
                    {
                        fairs.map((fair) =>{
                            return(
                                <tbody>
                                    <tr key={fair.fair_id} >
                                        <td><Link to ={{
                                                pathname:"/fair/"+ fair.fair_id + "/view",
                                                state:{
                                                    currentFair:fair
                                                }
                                            }}>
                                            {fair.name}
                                            </Link></td>
                                        <td>{utils.formatDateUndefined(fair.start_date)}</td>
                                        <td>{utils.formatDateUndefined(fair.end_date)}</td>
                                        {
                                            role==='admin'?(
                                                <td>
                                                        <Link to ={{
                                                            pathname:"/fairs/" + fair.fair_id,
                                                            state:{
                                                                currentFair:fair
                                                            }
                                                        }}>
                                                            <Button variant="outline-primary" className="me-2"><FaPencil title="Chỉnh Sửa" size="1em"/></Button>
                                                        </Link>
                                                        <ConfirmPopup/>
                                                        <Button variant="outline-danger" onClick={(e) => deleteFairConfirmation(e, fair.fair_id)}><FaTrashCan title="Xóa" size="1em"/></Button>
                                                 </td>
                                                ):""

                                        }

                                    </tr>
                                </tbody>
                            )
                        }

                        )
                    }
            </Table>
        </Container>

    )
}

export default FairListTemplate;