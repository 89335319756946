import React, {useRef, useState} from "react";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from "react-bootstrap/Button";


const Login = props =>{
    const[username, setUsername] = useState("");
    const[password, setPassword] = useState("");


    const onChangeUsername = e=>{
        const username = e.target.value;
        setUsername(username);
    }
    const onChangePassword = e =>{
        const password = e.target.value;
        setPassword(password);
    }

    const login = () =>{
        props.login({username:username, password:password});
        props.history.push('/');
        setTimeout(function (){
            window.location.reload();
        }, 1000);


    }
    return(
        <Container>

            <Form onSubmit={login}>
                <Form.Group className="mb-3">
                    <Form.Label>Tài Khoản</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder="Nhập tên tài khoản"
                        value={username}
                        onChange={onChangeUsername}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Mật Khẩu</Form.Label>
                    <Form.Control
                        type='password'
                        placeholder="Nhập mật khẩu"
                        value={password}
                        onChange={onChangePassword}
                    />
                </Form.Group>
                <Button type="submit" variant="primary">Đăng Nhập</Button>
            </Form>
        </Container>
    )

}

export default Login;